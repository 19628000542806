import { createContext } from 'react'

export const AppContext = createContext({
    userInfo: { userId: '', status: '' },
    skin: {},
    notification: () => {
        return {
            text: '',
            type: '', //['error','warning']
        }
    },
    evoGames: {},
    setEvoGames: (games) => {},
})

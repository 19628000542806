import React, { useContext, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import { BREAKPOINTS } from '../../themes/breakpoints'
import CountdownTimer from '../Countdown'
import BottomBar from '../BottomBar'
import { TimerWrapper } from '../../styled'

import GameLaunchService from '../../services/GameLaunchService'
import { AppContext } from '../../contexts/AppContex'
import { convertTimeWithZone } from '../../utils/time'
import FlagBar from '../FlagBar'
import {isObject} from "../../utils/utilsFunctions";

const StyledThumbWrapper = styled.div`
    width: 50%;
    height: auto;
    margin: 20px 0 0;
    box-sizing: border-box;
    padding: 0 10px;

    @media (max-width: ${BREAKPOINTS.md + 'px'}) {
        margin: 10px 0 0;
        padding: 0 5px;
    }

    @media (max-width: ${BREAKPOINTS.sm + 'px'}) {
        width: 100%;
        margin: 10px 0 0;
        padding: 0 5px;
    }
`

const StyledThumb = styled.div`
    transition: all 0.3s ease 0s;
    position: relative;
    border-radius: 8px;
    overflow: hidden;
    line-height: 0; //removing space between picture and border

    :hover {
        cursor: ${(props) => (props.closed ? 'default' : 'pointer')};
        transform: scale(1.05);
    }

    img {
        width: 100%;
        min-height: 100%;
        object-fit: contain;
        box-sizing: content-box;
        transition: all 0.3s ease 0s;
        border-radius: 8px;

        ${(props) =>
            props.closed &&
            css`
                filter: brightness(50%);
            `};
    }

    @media (max-width: ${BREAKPOINTS.sm + 'px'}) {
        width: 100%; //mob: 345/104
        min-width: 354px;
        height: auto; //deks: 544/174
        min-height: 110px;
    }
`

const TitleBox = styled.div`
    position: absolute;
    top: calc(50% - 55px);
    left: 10%;
    text-align: center;
    color: #fff;

    @media (max-width: ${BREAKPOINTS.lg + 'px'}) {
        top: calc(50% - 40px);
    }

    ${(props) =>
        props.titlePosition === 'right' &&
        css`
            right: 10%;
            left: initial;
        `};

    h4 {
        font-size: 32px;
        line-height: 32px;
        font-weight: normal;
        margin: 0;

        @media (max-width: ${BREAKPOINTS.lg + 'px'}) {
            font-size: 22px;
            line-height: 22px;
        }
    }

    h3 {
        font-size: 48px;
        line-height: 48px;
        margin: 0;

        @media (max-width: ${BREAKPOINTS.lg + 'px'}) {
            font-size: 32px;
            line-height: 32px;
        }
    }
`

const TitleDesc = styled.p`
    font-size: 16px;
    line-height: 16px;
    margin-top: 5px;
    text-align: center;

    @media (max-width: ${BREAKPOINTS.lg + 'px'}) {
        font-size: 12px;
        line-height: 12px;
    }
`

const TimeRange = styled.div`
    width: 100%;
    font-size: 16px;
    color: #fff;
    text-align: center;
    margin: 5px 0 0;
    line-height: 100%;

    @media (max-width: ${BREAKPOINTS.lg + 'px'}) {
        font-size: 12px;
    }
`

const ProvidersThumb = ({ liveCasinoGames, thumb, thumbClickHandler }) => {
    const [finishedCountdown, setFinishedCountdown] = useState(false)
    const [game, setGame] = useState({})
    const appContextData = useContext(AppContext)

    const showTimer = () =>
        thumb.showTimer &&
        !thumb.liveCasino.open &&
        isObject(thumb.liveCasino.workHours.value) &&
        !finishedCountdown &&
        !!Object.keys(thumb.liveCasino).length

    const findGameById = () =>
        liveCasinoGames.find((game) => game.id === thumb.gameId)

    const providerGamesCount = (provider) => {
        return liveCasinoGames.filter((game) => game.subprovider === provider)
            .length
    }

    const isGameClosed = () =>
        thumb.liveCasino.open === false && !finishedCountdown && isObject(thumb.liveCasino.workHours.value)

    const getLobbyUrl = (provider) => {
        /**
         * @TODO: extend functionality for another providers when it will be ready
         */
        if (provider === 'evolution')
            GameLaunchService.LaunchLobby(appContextData.skin, 'top_games')
                .then((apiUrl) => {
                    window.location.href = apiUrl
                })

                .catch((error) => {
                    appContextData.notification({
                        text: 'Launch lobby:' + error.toString(),
                        type: 'error',
                    })
                })
    }

    const LaunchGame = (game) => {
        if (!isGameClosed()) {
            const userInfo = appContextData.userInfo
            if (userInfo.status === 'open' || userInfo.status === 'temp') {
                GameLaunchService.LaunchGame(
                    game,
                    userInfo,
                    appContextData.skin
                )
            } else {
                appContextData.notification({
                    text: 'User is selfExcluded',
                    type: 'selfExcluded',
                })
            }
        }
    }

    const onThumbClick = (game) => {
        const userInfo = appContextData.userInfo

        if (!showTimer()) {
            if (userInfo && userInfo.userId) {
                if (userInfo.status === 'open' || userInfo.status === 'temp') {
                    if (thumb.gameId && game === undefined) {
                        console.error(
                            `game with ID - ${thumb.gameId} wasn't match eny tableid in a system`
                        )
                        return
                    } else {
                        return game
                            ? LaunchGame(game)
                            : getLobbyUrl('evolution')
                    }
                } else {
                    appContextData.notification({
                        text: 'User is selfExcluded',
                        type: 'selfExcluded',
                    })
                }
            } else {
                appContextData.notification({
                    text: 'Du spiller nu for sjov. Opret en konto eller log ind for at spille for rigtige penge',
                    type: 'warning',
                })
                return
            }
        }
    }

    useEffect(() => {
        setGame(findGameById())
    }, [])

    return (
        <StyledThumbWrapper>
            <StyledThumb
                onClick={() => {
                    if (thumbClickHandler !== undefined) {
                        const isGameOpened = !showTimer()
                        return thumbClickHandler(thumb, game, isGameOpened)
                    } else {
                        onThumbClick(game)
                    }
                }}
                closed={isGameClosed()}
            >
                <img src={thumb.bg} alt="" />
                <FlagBar
                    showFlag={thumb.showFlag}
                    providerName={thumb.providerName}
                />
                {thumb.title && (
                    <TitleBox titlePosition={thumb.title.titlePosition}>
                        <h4>{thumb.title.topTitle}</h4>
                        <h3>{thumb.title.middleTitle}</h3>
                        <TitleDesc>
                            {!thumb.gameId
                                ? `- ${providerGamesCount(
                                      thumb.providerKey
                                  )} åbne borde -`
                                : thumb.title.bottomTitle}
                        </TitleDesc>
                    </TitleBox>
                )}
                {showTimer() ? (
                    <TimerWrapper left={'28%'}>
                        <CountdownTimer
                            startTime={convertTimeWithZone(
                                thumb.liveCasino.workHours.value?.startTime
                            )}
                            completeCallback={(status) => {
                                return setFinishedCountdown(status)
                            }}
                            onMountCallback={(status) => {
                                return setFinishedCountdown(status)
                            }}
                        />
                        <TimeRange>
                            {`${convertTimeWithZone(
                                thumb.liveCasino.workHours.value?.startTime
                            )} -

                                                ${convertTimeWithZone(
                                                    thumb.liveCasino.workHours
                                                        .value?.endTime
                                                )}`}
                        </TimeRange>
                    </TimerWrapper>
                ) : null}
                <BottomBar
                    liveCasinoData={thumb.liveCasino}
                    showPlayersCount={thumb.players}
                />
            </StyledThumb>
        </StyledThumbWrapper>
    )
}

ProvidersThumb.propTypes = {
    liveCasinoGames: PropTypes.arrayOf(PropTypes.object).isRequired,
    thumb: PropTypes.object.isRequired,
    thumbClickHandler: PropTypes.func,
}

export default ProvidersThumb

import React from 'react'
import { BetCircle, BottomDropDown } from './styled'

const baccaratColors = {
    T: 'green',
    B: 'red',
    P: 'blue',
}

const Baccarat = ({ history = [], active, privateTable }) => {
    const resolveBaccaratColor = (key) => baccaratColors[key]

    const isAllowedData = () =>
        history && Array.isArray(history) && history.length > 0

    return (
        isAllowedData() && (
            <BottomDropDown active={active} privateTable={privateTable}>
                {history.map((value, index) => {
                    return (
                        <BetCircle
                            key={index}
                            border={index < 1}
                            color={resolveBaccaratColor(value)}
                        >
                            {value}
                        </BetCircle>
                    )
                })}
            </BottomDropDown>
        )
    )
}

export default Baccarat

import React, { useState, Fragment, useEffect } from 'react'
import styled, { css } from 'styled-components'
import Icon from '../Icon'
import bg from '../../images/v75bg.png'
import team from '../../images/icons/team.svg'

const Wrapper = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    width: 420px;
`

const TextWrapper = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 0px 35px 20px 30px;
`

const Card = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
    width: 355px;
    background: url(${bg});
    background-repeat: no-repeat;
    background-size: cover;
    background-size: 100%;
    border: solid 1px lightgrey;
    border-radius: 30px;
    padding: 20px 30px;
`

const TextContainer = styled.div`
    margin: 0;
`

const MainText = styled.p`
    margin: 2px;
    font-size: 16px;
    color: #9198aa;
    font-weight: bold;
`

const Row = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
`

const RowBottom = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-top: 10px;
    margin-top: 17px;
`

const SeparateLine = styled.div`
    position: absolute;
    height: 1px;
    background-color: lightgrey;
    width: 100%;
    bottom: 77px;
    left: 0px;
`

const Flag = styled.div`
    position: absolute;
    top: 20px;
    left: -10px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 3px 7px 3px 7px;
    background-color: #e22a2a;
    border-radius: 15px;
`

const CardText = styled.p`
    margin: 2px;
    font-size: 14px;
    color: #9198aa;
`

const CardBoldText = styled.p`
    margin: 2px;
    font-size: 20px;
    font-weight: bold;
    color: #404757;
`

const BackGroundWrapper = styled.div`
    background: url(${bg});
    background-repeat: no-repeat;
    background-size: cover;
`

const FlagText = styled.p`
    margin: 0;
    font-size: 10px;
    color: white;
    font-weight: bold;
`

const Button = styled.a`
    display: flex;
    justify-content: space-between;
    align-items: center;
    text-align: center;
    width: 150px;
    height: 50px;
    background-color: #ebcd32;
    border-radius: 20px;
`

const ButtonText = styled.p`
    color: #404757;
    font-size: 16px;
    font-weight: bold;
    justify-content: space-between;
    align-items: center;
`

class LastGamesCard extends React.Component {
    render() {
        return (
            <Wrapper>
                {this.props.bottom ? (
                    <Fragment>
                        <TextWrapper>
                            <MainText>Seneste andelsspil:</MainText>
                        </TextWrapper>
                        <Card>
                            <Flag>
                                <FlagText>NY!</FlagText>
                            </Flag>
                            <TextContainer>
                                <CardText>Facebook gruppe</CardText>
                                <CardBoldText>
                                    V75 - Solvalla 21.11.19
                                </CardBoldText>
                            </TextContainer>
                            <Row>
                                <TextContainer>
                                    <CardText>Andelspirs</CardText>
                                    <CardBoldText>100,00 kr.</CardBoldText>
                                </TextContainer>
                                <TextContainer>
                                    <CardText>Deadline</CardText>
                                    <CardBoldText>12:12:12</CardBoldText>
                                </TextContainer>
                                <TextContainer>
                                    <CardText>Kaptajn afgift</CardText>
                                    <CardBoldText>10%</CardBoldText>
                                </TextContainer>
                            </Row>
                            <SeparateLine />
                            <RowBottom>
                                <Icon src={team} size="xxl" clickable />
                                <Button>
                                    <ButtonText>SE MERE</ButtonText>
                                </Button>
                            </RowBottom>
                        </Card>
                    </Fragment>
                ) : (
                    <Fragment>
                        <TextWrapper>
                            <MainText>Seneste andelsspil:</MainText>
                            <MainText>Se alle&#8194;></MainText>
                        </TextWrapper>
                        <Card>
                            <Flag>
                                <FlagText>NY!</FlagText>
                            </Flag>
                            <TextContainer>
                                <CardText>Facebook gruppe</CardText>
                                <CardBoldText>
                                    V75 - Solvalla 21.11.19
                                </CardBoldText>
                            </TextContainer>
                            <Row>
                                <TextContainer>
                                    <CardText>Andelspirs</CardText>
                                    <CardBoldText>100,00 kr.</CardBoldText>
                                </TextContainer>
                                <TextContainer>
                                    <CardText>Deadline</CardText>
                                    <CardBoldText>12:12:12</CardBoldText>
                                </TextContainer>
                                <TextContainer>
                                    <CardText>Kaptajn afgift</CardText>
                                    <CardBoldText>10%</CardBoldText>
                                </TextContainer>
                            </Row>
                        </Card>
                    </Fragment>
                )}
            </Wrapper>
        )
    }
}

export default LastGamesCard

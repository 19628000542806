import React, { useState } from 'react'
import propTypes from 'prop-types'
import styled from 'styled-components'
import NumberFormat from 'react-number-format'

const REQUIRED = 'required'
const NUMERIC = 'numeric'
const MIN = 'min'
const MAX = 'max'

export const numericRegex = /^\d+$/

/**
 *
 * @param state
 * @param focus
 * @param inputColor
 * @param focusColor
 * @return {string}
 */
const getInputBorderColor = (
    state,
    focus = false,
    inputColor = '#eceff6',
    focusColor = '#9198aa'
) => {
    let result = focus ? focusColor : inputColor
    if (state === 'error') {
        result = '#DB1C1B'
    } else if (state === 'success') {
        result = '#31D300'
    }
    return result
}

const BottomLabel = styled.p`
    margin: 10px 0 20px 8px;
    font-size: 12px;
    color: #9198aa;
`
const InputWrapper = styled.div`
    margin: 50px auto 0;
    padding: 0;
    width: 100%;
    position: relative;

    input {
        padding: 17px 45px 17px 16px;
        border-radius: 5px;
        width: 100%;
        box-sizing: border-box;
        background: ${({ inputColor }) => inputColor || '#eceff6'};
        position: relative;
        ${({ borderState, inputColor }) =>
            `border: 1px solid ${getInputBorderColor(
                borderState,
                false,
                inputColor
            )}`};
        color: ${({ color }) => color || '#575c69'};
        -webkit-text-fill-color: ${({ color }) => color || '#575c69'};
        opacity: 1;
        font-size: 16px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        &:focus,
        &:hover {
            ${({ borderState, inputColor, focusColor }) =>
                ` border: 1px solid ${getInputBorderColor(
                    borderState,
                    true,
                    inputColor,
                    focusColor
                )}`};
        }
        &::placeholder {
            font-size: 16px;
            color: ${({ color }) => color || '#575c69'};
        }
    }
`
const Title = styled.span`
    position: absolute;
    top: -25px;
    left: 8px;
    font-size: 14px;
    color: ${({ color }) => color || '#575c69'};
    font-family: ${({ condensed }) =>
            condensed ? 'Roboto Condensed' : 'inherit'},
        sans-serif;
`
const Error = styled.div`
    margin: 10px 0 20px 8px;
    font-size: 12px;
    color: #db1c1b;
`
const CPRInput = ({
    title,
    bottomLabel,
    placeholder = '',
    onChangeHandler = () => {},
    onCheckValue = null,
    validate = '',
    value = '',
    format = null,
    mask = '',
    allowEmptyFormatting = false,
    allowFirstZero = true,
    min = '',
    max = '',
    pattern = '',
    textColor = '#575c69',
    inputColor = '#fff',
    focusColor = '#fff',
    condensed = true,
}) => {
    const [isValid, setIsValid] = useState('')
    const [error, setError] = useState('')

    const validateInput = (value) => {
        let valid = ''
        if (value !== '') {
            valid = true
        }
        if (validate.indexOf(NUMERIC) > -1 && !numericRegex.test(value)) {
            valid = false
            setError('Feltet bør kun indeholde cifre')
        }
        if (validate.indexOf(MIN) > -1 && min && value.length < min) {
            valid = false
            setError(`Skal bestå af ${min} tal`)
        }
        if (validate.indexOf(MAX) > -1 && max && value.length > max) {
            valid = false
            setError(`Maximum ${max} tegn`)
        }
        if (validate.indexOf(REQUIRED) > -1 && value === '') {
            valid = false
            setError('Skal udfyldes')
        }
        if (valid) {
            setError('')
        }
        setIsValid(valid)
        return valid
    }
    const changeHandler = (value) => {
        let val = value
        if (!allowFirstZero) {
            if (/^0/.test(val)) {
                val.replace(/^0/, '')
            }
        }
        if (pattern) {
            val = value.replace(pattern, '')
        }
        const isValid = validateInput(val)
        onCheckValue(isValid)
        onChangeHandler(val)
    }

    return (
        <InputWrapper
            color={textColor}
            inputColor={inputColor}
            focusColor={focusColor}
            borderState={
                isValid === false ? 'error' : isValid === true ? 'success' : ''
            }
        >
            {title && (
                <Title color={textColor} condensed={condensed}>
                    {title}
                </Title>
            )}
            <NumberFormat
                value={value}
                format={format}
                onValueChange={(values) => {
                    changeHandler(values.value)
                }}
                placeholder={placeholder}
                allowEmptyFormatting={allowEmptyFormatting}
                type="text"
                mask={mask}
            />
            {error ? (
                <Error>{error}</Error>
            ) : (
                <BottomLabel>{bottomLabel}</BottomLabel>
            )}
        </InputWrapper>
    )
}

CPRInput.propTypes = {
    placeholder: propTypes.string,
    title: propTypes.string,
    bottomLabel: propTypes.any,
    onChangeHandler: propTypes.func,
    validate: propTypes.string,
    value: propTypes.any,
    format: propTypes.any,
    mask: propTypes.any,
    allowEmptyFormatting: propTypes.bool,
    allowFirstZero: propTypes.bool,
    min: propTypes.any,
    max: propTypes.any,
    pattern: propTypes.any,
    textColor: propTypes.string,
    inputColor: propTypes.string,
    focusColor: propTypes.string,
    condensed: propTypes.bool,
}

export default CPRInput

import React from 'react'
import styled from 'styled-components'
import { HighlightBox } from '../styled'
import { ReactComponent as DKflag } from '../images/icons/DK-flag.svg'
import { PROVIDERS_LOGOS } from '../configs/categories'
import { BREAKPOINTS } from '../themes/breakpoints'

const FlagBlock = styled.div`
    line-height: 20px;
    display: inline-block;
    position: absolute;
    top: 5px;
    left: 5px;
    z-index: 1;

    * {
        display: inline-block;
    }
`

const DKflagStyled = styled(DKflag)`
    width: auto;
    height: 17px;
    image {
        @media (max-width: ${BREAKPOINTS.md + 'px'}) {
            width: auto;
            height: 17px;
        }
    }
`

const ThumbProvider = styled(HighlightBox)`
    vertical-align: top;
    margin: 0 0 0 5px;
    text-transform: capitalize;
`

const ThumbProviderIcon = styled.div`
    margin: 0 5px 0 0;
    line-height: 20px;
    vertical-align: middle;
`

const FlagBar = ({ showFlag = false, providerName }) => {
    return (
        <FlagBlock>
            {showFlag && <DKflagStyled />}
            <ThumbProvider>
                {PROVIDERS_LOGOS[providerName] && (
                    <ThumbProviderIcon>
                        {PROVIDERS_LOGOS[providerName]}
                    </ThumbProviderIcon>
                )}
                {providerName}
            </ThumbProvider>
        </FlagBlock>
    )
}

export default FlagBar

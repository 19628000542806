import { getApiUrl } from '../config/api'
import { makeRequest } from '../utils/REST'
export const LOGIN_TYPES = {
    MIT_ID: 'mitid',
}

export const login = (
    type,
    site = '',
    loading,
    setLoading,
    oldAPI = false,
    operation = '',
    onError = () => {}
) => {
    if (!loading[type]) {
        setLoading({ ...loading, [type]: true })
        makeRequest(
            'get',
            oldAPI
                ? `/rest/punter/mitidStart/${type}`
                : `${getApiUrl()}/punter/mitidStart/${type}`,
            {
                ...(site && { site }),
                ...(operation && { operation }),
            }
        )
            .then((res) => res.json())
            .then((result) => {
                if (result.success) {
                    window.location.href = result.data
                } else {
                    result.errorMessage
                        ? onError(result.errorMessage)
                        : onError('Some error appear')
                }
            })
            .catch((e) => {
                setLoading({ ...loading, [type]: true })
                console.error('MitID failed: ', e)
            })
    }
}

export const restorePassword = (
    site = '',
    loading = false,
    setLoading,
    oldAPI = false,
    newPassword,
    confirmPassword,
    successHandler = () => {},
    onError = () => {}
) => {
    if (!loading) {
        setLoading(false)
        makeRequest(
            'post',
            oldAPI
                ? `/rest/punter/setPasswordSecure`
                : `${getApiUrl()}/punter/setPasswordSecure`,
            {
                edit: newPassword,
                confirm: confirmPassword,
            }
        )
            .then((res) => res.json())
            .then((result) => {
                if (result.success) {
                    successHandler()
                } else {
                    console.log('result', result)
                    onError(result)
                }
            })
            .catch((e) => {
                setLoading(false)
                console.error('Change password is failed: ', e)
            })
    }
}

import styled from 'styled-components'
import { BonusButton } from '../styled'

export const Wrapper = styled.div`
    width: 100%;
    max-width: 375px;
    background: #fff;
    border-radius: 12px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
`

export const StyledModalTopbar = styled.div`
    border-bottom: 1px solid #f4f4f4;
    text-align: center;
    padding: 17px 0;
    color: #000;
    font-size: 24px;
    font-weight: bold;
`

export const ContentWrapper = styled.div`
    padding: 32px;
    color: #000;
    font-size: 16px;
`

export const ButtonWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    gap: 8px;
    padding: 0 16px 16px;
`

export const CancelButton = styled(BonusButton)`
    background: #edf1f8;
    color: #000;
    font-size: 14px;

    &:hover {
        background: #e4e9f2;
    }
`

export const ConfirmButton = styled(BonusButton)`
    background: #fbe4e4;
    color: #db1c1b;
    font-size: 14px;

    &:hover {
        background: #db1c1b;
        color: #ffffff;
    }
`

import styled from 'styled-components'
import { Text } from '@it25syv/25syv-ui'

export const TabsLabel = styled(Text)`
    color: ${(props) => props.theme.colors.basic};
`

export const TabsWrapper = styled.div`
    justify-content: flex-start;
    flex-direction: column;
    display: flex;
`

export const ButtonSwitcher = styled.div`
    background: ${(props) =>
        props.theme.name === 'default'
            ? props.theme.colors.bgDarker
            : props.theme.colors.bg};
    border: 1px solid
        ${(props) =>
            props.theme.name === 'default'
                ? 'transparent'
                : props.theme.colors.bgDarker};
    margin: 0 auto;
    width: 100%;
    height: 50px;
    bottom: 70px;
    border-radius: 7px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 5px;
    box-sizing: border-box;
    position: sticky;
    cursor: pointer;
`

export const Tab = styled.button`
    width: 100%;
    height: 100%;
    font-weight: ${(props) => props.theme.fontWeight};
    font-size: 14px;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    z-index: 2;
    cursor: pointer;
    border: 0;
    background: none;
    color: ${(props) =>
        props.active
            ? props.theme.colors.basic
            : props.theme.colors.primaryAction};
`
export const Line = styled.div`
    height: 20px;
    width: 1px;
    margin-left: 0;
    display: inline-block;
    box-sizing: border-box;
    background: ${(props) =>
        props.theme.name === 'default'
            ? props.theme.colors.primaryAction
            : props.theme.colors.bgDarker};
    position: relative;
    z-index: -1;
`

export const ActiveTabPad = styled.div`
    background: ${(props) =>
        props.theme.name === 'default'
            ? props.theme.colors.bg
            : props.theme.colors.bgDarker};
    border-radius: 5px;
    width: 50%;
    height: 40px;
    position: absolute;
    z-index: 1;
    left: ${(props) => props.left};
    transition: all 300ms ease;
`

import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import DigitalDisplay from './DigitalDisplay'

const Wrapper = styled.div`
    color: ${(props) => (props.color ? props.color : 'inherit')};
    font-size: inherit;
`

const pad = (number) => {
    return number < 10 ? '0' + number : number ? number.toString() : ''
}

const DigitalClock = ({ color = 'inherit', displayFormat = 'HH:mm' }) => {
    let now = new Date()

    let hours = now.getHours(),
        minutes = now.getMinutes(),
        seconds = now.getSeconds()
    let ampm = ''

    if (displayFormat.includes('A')) {
        hours = now.getHours()
        minutes = now.getMinutes()
        ampm = hours >= 12 ? 'pm' : 'am'
        hours = hours % 12
        hours = hours ? hours.toString() : '12'
        minutes = minutes < 10 ? '0' + minutes : minutes.toString()
    }

    return (
        <Wrapper color={color}>
            <DigitalDisplay
                hours={displayFormat.includes('HH') ? pad(hours) : ''}
                minutes={displayFormat.includes('mm') ? pad(minutes) : ''}
                seconds={displayFormat.includes('ss') ? pad(seconds) : ''}
                color={color}
            />
            {' ' + ampm}
        </Wrapper>
    )
}

DigitalClock.propTypes = {
    clockColor: PropTypes.string,
    displayFormat: PropTypes.oneOf([
        'HH:mm',
        'HH:mm:ss',
        'HH:mm A',
        'HH:mm:ss A',
    ]),
}

export default DigitalClock

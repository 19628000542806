import React, { useEffect, useState } from 'react'
import { CenterBlock } from './styled'
import styled from 'styled-components'

import ButtonsSwitcher from './components/ButtonsSwitcher'
import { LIVE_CASINO_CATEGORIES } from './configs/categories'
import Content from './components/Content'
import { BREAKPOINTS } from './themes/breakpoints'
import { UI_CONFIG } from './configs/ui'
import Banner from './components/Banner'
import GameService, {
    getAllGames,
    getLiveCasinoGamesAdapter,
    getLiveTablesEvoAdapter,
} from './services/GameService'
import { getEvoSkin } from './configs/env'
import { AppContext } from './contexts/AppContex'
import { version } from './../../package.json'
import Loader from './utils/Loader'
import PropTypes from 'prop-types'
import dgaAPI from './services/dgaAPI'

const PageWrapper = styled.div`
    display: flex;
    flex-direction: column;
    min-height: inherit;
    overflow-x: hidden;
    overflow-y: auto;
`

const BannerContainer = styled.div`
    display: flex;
    width: 100%;
    position: relative;
    min-height: 200px;

    @media (max-width: ${BREAKPOINTS.sm + 'px'}) {
        min-height: initial;
    }
`

const MenuContainer = styled.div`
    display: flex;
    width: 100%;
    height: 80px;
    background: #fff;
    justify-content: center;
    flex-direction: column;
`

const LiveCasino = ({
    appName = 'rod25',
    liveCasinoGames = null,
    matchUrl,
    urlParams,
    showThumbsByProviders = [], //['evolution','scientific'], by default all are shown
    showGamesByProviders = [], //['evolution','scientific'], by default all are shown
    menuColors = {},
    thumbClickHandler,
    gameClickHandler,
    userInfo,
    notificationHandler,
}) => {
    const basicMenuColors = { ...UI_CONFIG.menuColors, ...menuColors }
    const [gamesCount, setGamesCount] = useState(0)
    const [liveCasino, setLiveCasino] = useState([])
    const [liveCasinoEvo, setLiveCasinoEvo] = useState({})
    const [tempPragmatic, setTempPragmatic] = useState({})
    const [pragmaticIds, setPragmaticIds] = useState([])
    const [loader, setLoader] = useState(true)
    const [activeCategory, setActiveCategory] = useState({})

    const appContextData = {
        skin: getEvoSkin(appName),
        userInfo,
        notification: notificationHandler,
        evoGames: liveCasinoEvo,
        setEvoGames: setLiveCasinoEvo,
    }

    const setUrlByCategory = (category) => {
        matchUrl(category)
    }

    const onChangeTabHandler = (tab) => {
        setUrlByCategory(tab.url)
    }

    const matchCategory = () => {
        return LIVE_CASINO_CATEGORIES.find((c) => c.url === urlParams.category)
    }

    const getLiveTables = () => {
        getLiveTablesEvoAdapter()
            .then((res) => {
                if (res) {
                    appContextData.setEvoGames(res)

                    setTimeout(() => setLoader(false), 1000)
                }
            })
            .catch((e) => {
                setLiveCasino([])
                appContextData.notification({
                    text: 'Der er opstået et problem, prøv venligst senere',
                    type: 'error',
                })
            })
    }

    /**
     * It will be used for future implementation

     const preparePragmaticGames = (data) => {
        if (data && Object.keys(data).length && !data.tableKey) {
            const index = appContextData.pragmaticGames.findIndex(
                (obj) => obj.tableId === data.tableId
            )

            if (index === -1) {
                appContextData.setPragmaticGames([
                    ...appContextData.pragmaticGames,
                    data,
                ])
            } else {
                appContextData.setPragmaticGames(
                    appContextData.pragmaticGames.map((game, i) =>
                        i === index ? { ...game, ...data } : game
                    )
                )
            }
        }
    }     */

    useEffect(() => {
        const evoGames = getAllGames(
            liveCasino,
            appContextData.evoGames
        ).liveCasinoGames

        if (evoGames.length) {
            setLiveCasino(evoGames)
        }
    }, [appContextData.evoGames])

    useEffect(() => {
        let interval

        if (liveCasinoGames !== null) {
            // setLiveCasino(liveCasinoGames)
            // if (liveCasinoGames.length) {
            //     setLoader(false)
            // }
        } else {
            getLiveCasinoGamesAdapter().then((res) => {
                if (res && res.length) {
                    const casinoGames = res

                    setLiveCasino(casinoGames)
                    getLiveTables()

                    GameService.setConnectionToPragmatic(casinoGames)
                    dgaAPI.onMessage = function (data) {
                        data.tableKey
                            ? setPragmaticIds(data.tableKey)
                            : setTempPragmatic(data)
                    }

                    interval = setInterval(
                        getLiveTables,
                        1000 * 15,
                        casinoGames,
                        true
                    )

                    setTimeout(() => {
                        GameService.disconnectFromPragmatic()
                        clearInterval(interval)
                    }, 1000 * 60 * 15)
                }
            })
        }

        return () => {
            GameService.disconnectFromPragmatic()
            clearInterval(interval)
        }
    }, [])

    useEffect(() => {
        setLiveCasino(GameService.getPragmaticGames(liveCasino, tempPragmatic))
    }, [tempPragmatic])

    useEffect(() => {
        //needs for first loading with empty liveCas
        const pragmaticByIds = GameService.prepareListOfPragmaticGames(
            liveCasino,
            pragmaticIds
        )

        setLiveCasino(pragmaticByIds)
    }, [pragmaticIds])

    useEffect(() => {
        setActiveCategory(matchCategory())
    }, [urlParams.category])

    useEffect(() => {
        console.log('LC ver. - ', version) //DO NOT remove
    }, [])

    return (
        <PageWrapper>
            <BannerContainer>
                <Banner
                    key={gamesCount}
                    activeCategory={activeCategory}
                    gamesCount={gamesCount}
                />
            </BannerContainer>
            <MenuContainer>
                <CenterBlock>
                    <ButtonsSwitcher
                        activeTab={urlParams.category}
                        onChange={onChangeTabHandler}
                        tabs={LIVE_CASINO_CATEGORIES}
                        menuColors={basicMenuColors}
                    />
                </CenterBlock>
            </MenuContainer>
            {loader ? (
                <Loader />
            ) : (
                <AppContext.Provider value={appContextData}>
                    <Content
                        activeCategory={activeCategory}
                        liveCasinoGames={liveCasino}
                        showThumbsByProviders={showThumbsByProviders}
                        showGamesByProviders={showGamesByProviders}
                        thumbClickHandler={thumbClickHandler}
                        gameClickHandler={gameClickHandler}
                        gamesCount={(count) => {
                            setGamesCount(count)
                        }}
                    />
                </AppContext.Provider>
            )}
        </PageWrapper>
    )
}

LiveCasino.propTypes = {
    appName: PropTypes.string.isRequired,
    liveCasinoGames: PropTypes.arrayOf(PropTypes.object),
    matchUrl: PropTypes.func.isRequired,
    urlParams: PropTypes.object.isRequired,
    menuColors: PropTypes.object,
    showThumbsByProviders: PropTypes.arrayOf(PropTypes.string),
    showGamesByProviders: PropTypes.arrayOf(PropTypes.string),
    userInfo: PropTypes.object.isRequired,
    thumbClickHandler: PropTypes.func,
    gameClickHandler: PropTypes.func,
    notificationHandler: PropTypes.func,
}

export default LiveCasino

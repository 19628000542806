import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'

const WithTimer = (Component) =>
    function TimerComponent({
        countFrom = new Date().getTime(),
        ...props
    }) {

        let now = new Date().getTime()

        const [passedTime, setPassedTime] = useState(now - countFrom)

        const tick = () => {
            setPassedTime(new Date().getTime() - countFrom)
        }

        useEffect(() => {
            let timerID = setInterval(() => tick(), 1000)
            return () => {
                clearInterval(timerID)
            }
        })

        return (
            <Component
                passedTime={passedTime}
                {...props}
            />
        )
    }

WithTimer.propTypes = {
    countFrom: PropTypes.number.isRequired,
    setPassedTime: PropTypes.func.isRequired,
}

export default WithTimer

import React, { Fragment, useEffect, useState } from 'react'
import { ValidatingInput } from '@it25syv/25syv-ui'
import { NextButton } from './styled'
import pick from 'lodash/pick'
import omit from 'lodash/omit'
import AuthService from '../services/AuthService'

const Step2 = ({
    setStep = () => {},
    onCPRExists,
    updateUserData = () => {},
    scrollToTop = () => {},
    setStepValidity,
    userData,
    setUserData,
    showError,
    onFocus,
    isTerminal,
    oldAPI = false,
    theme
}) => {
    const [errors, setErrors] = useState({
        userName: '',
        fullName: '',
        phoneNumber: '',
        cpr: ''
    })
    const [isUserNameDisabled, setIsUserNameDisabled] = useState(true)
    const [isUserNameValid, setIsUserNameValid] = useState(
        userData.userName.length > 2
    )
    const [userNameErrorValue, setUserNameErrorValue] = useState('')
    const [cprErrorValue, setCprErrorValue] = useState('')
    const [isCprValid, setIsCprValid] = useState(null)

    const checkValue = (error) => {
        if (error) {
            setErrors((errors) => ({ ...errors, [error.name]: error.value }))
        }
    }

    const isDataValid = () => {
        const values = Object.values(
            pick(userData, ['userName', 'fullName', 'phoneNumber', 'cpr'])
        )

        return (
            values.every((data) => data?.length > 0) &&
            Object.values(errors).every((error) => error == false)
        )
    }

    useEffect(() => {
        scrollToTop()
    }, [])

    useEffect(() => {
        setStepValidity(isDataValid)
    }, [isDataValid])

    const submitHandler = () => {
        setIsUserNameValid(true)
        setUserNameErrorValue('')
        setIsCprValid(true)
        setCprErrorValue('')
        // check2Step = AuthService.checkRegisterInfo
        AuthService.checkRegisterInfo(
            userData.userName,
            userData.cpr,
            oldAPI
        ).then((res) => {
            if (res?.data) {
                if (res?.data?.exist === false) {
                    const cUserData = {
                        ...userData,
                        stepsValidated: {
                            ...userData.stepsValidated,
                            step2: true
                        }
                    }
                    updateUserData(cUserData)
                    setStep(3)
                } else if (res?.data?.exist === true) {
                    AuthService.checkUserName(
                        userData.userName,
                        userData.cpr,
                        oldAPI
                    ).then((res) => {
                        if (res?.data) {
                            if (res?.data?.exist === true) {
                                const cUserData = {
                                    ...userData,
                                    stepsValidated: {
                                        ...userData.stepsValidated,
                                        step2: false
                                    }
                                }
                                updateUserData(cUserData)
                                setUserNameErrorValue('Brugernavn er optaget')
                                setIsUserNameValid(false)
                                showError('Brugernavn er optaget')
                            } else {
                                const cUserData = {
                                    ...userData,
                                    stepsValidated: {
                                        ...userData.stepsValidated,
                                        step2: false
                                    }
                                }
                                updateUserData(cUserData)
                                setCprErrorValue('CPR er optaget')
                                setIsCprValid(false)

                                onCPRExists && typeof onCPRExists === 'function'
                                    ? onCPRExists()
                                    : showError('CPR er optaget')
                            }
                        }
                    })
                    // const cUserData = {
                    //     ...userData,
                    //     stepsValidated: {
                    //         ...userData.stepsValidated,
                    //         step2: false
                    //     }
                    // }
                    // updateUserData(cUserData)
                    // setUserNameErrorValue('Brugernavn er optaget')
                    // setIsUserNameValid(false)
                    // showError('Brugernavn er optaget')
                }
            } else if (res?.errorMessage === 'CPR_ERROR_TO_YOUNG') {
                const cUserData = {
                    ...userData,
                    stepsValidated: {
                        ...userData.stepsValidated,
                        step2: false
                    }
                }
                updateUserData(cUserData)
                setIsCprValid(false)
                setCprErrorValue(
                    'Du skal være 18 år for at kunne oprette en bruger'
                )
                showError('Du skal være 18 år for at kunne oprette en bruger')
            } else if (res?.errorMessage === 'CPR_ERROR_VALIDATION') {
                const cUserData = {
                    ...userData,
                    stepsValidated: {
                        ...userData.stepsValidated,
                        step2: false
                    }
                }
                updateUserData(cUserData)
                setIsCprValid(false)
                setCprErrorValue('Ugyldigt CPR-nummer')
                showError('Ugyldigt CPR-nummer')
            } else {
                showError('smth went wrong')
            }
        })
    }

    const userNameClickHandler = () => {
        if (isUserNameDisabled) {
            setIsUserNameDisabled(!isUserNameDisabled)
            setUserData({ ...userData, userName: userData.userName })
        } else {
            setUserData({ ...userData, userName: userData.email })
            setIsUserNameDisabled(!isUserNameDisabled)
            setErrors(omit(errors, ['userName']))
        }
    }

    return (
        <Fragment>
            <ValidatingInput
                type='text'
                title='Brugernavn'
                name='userName'
                autoComplete='new-user-name'
                onCheckValue={checkValue}
                validate='required, min-text, username'
                validateOnChange
                value={userData.userName}
                onChangeHandler={(value) => {
                    setUserData({
                        ...userData,
                        userName: value,
                        stepsValidated: {
                            ...userData.stepsValidated,
                            step2: false
                        }
                    })
                }}
                min={3}
                disabled={isUserNameDisabled}
                altLabel={isUserNameDisabled ? 'Ret' : 'Fortryd'}
                altLabelClickHandler={userNameClickHandler}
                bottomLabel='Du kan bruge både email og brugernavn til at logge ind.'
                errorMessage={userNameErrorValue}
                setValid={isUserNameValid}
                onFocus={(e) => {
                    onFocus(e, (value) => {
                        setUserData({
                            ...userData,
                            userName: value,
                            stepsValidated: {
                                ...userData.stepsValidated,
                                step2: false
                            }
                        })
                    })
                }}
                theme={theme.name}
            />
            <input name='email' type='hidden' />
            <ValidatingInput
                onChangeHandler={(value) => {
                    setUserData({ ...userData, fullName: value })
                }}
                onCheckValue={checkValue}
                type='text'
                min={2}
                title='Fulde navn'
                validate='required, min-text, full-name'
                value={userData.fullName}
                name='fullName'
                autoComplete='new-full-name'
                autoFocus={!isTerminal}
                onFocus={(elem, validVal) => {
                    onFocus(
                        elem,
                        (value) => {
                            setUserData({ ...userData, fullName: value })
                        },
                        validVal
                    )
                }}
                theme={theme.name}
            />
            <ValidatingInput
                onChangeHandler={(value) => {
                    setUserData({ ...userData, phoneNumber: value })
                }}
                onCheckValue={checkValue}
                type='number'
                title='Telefonnummer'
                validate='required, min, max, numeric'
                min={8}
                max={8}
                value={userData.phoneNumber}
                name='phoneNumber'
                placeholder='+45'
                autoComplete='new-phone-number'
                allowEmptyFormatting
                format='+45 ## ## ## ##'
                onFocus={(elem, validVal) => {
                    onFocus(
                        elem,
                        (value) => {
                            setUserData({ ...userData, phoneNumber: value })
                        },
                        validVal
                    )
                }}
                theme={theme.name}
            />
            <ValidatingInput
                onChangeHandler={(value) => {
                    setUserData({
                        ...userData,
                        cpr: value,
                        stepsValidated: {
                            ...userData.stepsValidated,
                            step2: false
                        }
                    })
                }}
                onCheckValue={checkValue}
                type={!isTerminal ? 'number' : ''}
                // type='number'
                title='CPR-nummer'
                validate='required, numeric, min, max'
                value={userData.cpr}
                name='cpr'
                min='10'
                max='10'
                bottomLabel='Dit CPR-nummer sikrer os, at du er over 18 år.'
                format={!isTerminal ? '###### - ####' : null}
                placeholder='DDMMÅÅ - XXXX'
                autoComplete='new-cpr'
                allowEmptyFormatting
                mask={
                    !isTerminal
                        ? ['D', 'D', 'M', 'M', 'Å', 'Å', 'X', 'X', 'X', 'X']
                        : ''
                }
                setValid={isCprValid}
                onFocus={(elem, validVal) => {
                    onFocus(
                        elem,
                        (value) => {
                            setUserData({
                                ...userData,
                                cpr: value,
                                stepsValidated: {
                                    ...userData.stepsValidated,
                                    step2: false
                                }
                            })
                        },
                        validVal
                    )
                }}
                errorMessage={cprErrorValue}
                theme={theme.name}
            />

            <NextButton
                active={!!isDataValid()}
                width='100%'
                padding='16px'
                margin='20px 0'
                disabled={!isDataValid()}
                yellow
                onClick={submitHandler}
            >
                Næste
            </NextButton>
        </Fragment>
    )
}

export default Step2

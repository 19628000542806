import styled, { css } from 'styled-components'
import { ReactComponent as AvatarIcon } from '../../images/icons/seat.svg'
import { BREAKPOINTS } from '../../themes/breakpoints'

const BET_CIRCLE_COLORS = {
    red: '#FD484C',
    black: '#333333',
    green: '#1CAC51',
    yellow: '#C49B53',
    blue: '#3A94FF',
}

const BET_CIRCLE_SIZES = {
    defaultSize: '30px',
    mobileSize: '12px',
}

export const BottomDropDown = styled.div`
    width: 100%;
    height: ${(props) =>
        props.active ? (props.extended ? '80px' : '40px') : '0'};
    background: rgba(0, 0, 0, 0.8);
    opacity: ${(props) => (props.active ? '1' : '0')};
    border-radius: 0 0 8px 8px;
    transition: all 0.3s ease;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0 3px;
    overflow-x: scroll;
    -ms-overflow-style: none;
    scrollbar-width: none;
    * {
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
    }

    ::-webkit-scrollbar {
        display: none;
    }

    @media (max-width: ${BREAKPOINTS.md + 'px'}) {
        height: ${(props) =>
            props.active ? (props.extended ? '40px' : '20px') : '0'};
    }
`

export const BetCircle = styled.div`
    min-width: ${BET_CIRCLE_SIZES.defaultSize};
    height: ${BET_CIRCLE_SIZES.defaultSize};
    border-radius: ${BET_CIRCLE_SIZES.defaultSize};
    line-height: ${BET_CIRCLE_SIZES.defaultSize};
    text-align: center;
    font-size: 14px;
    font-weight: bold;
    color: #fff;
    vertical-align: center;
    margin: 0 3px;
    border: 1px solid ${(props) => (props.border ? ' #fff' : 'transparent')};
    box-sizing: content-box;
    background: ${(props) =>
        Object.keys(BET_CIRCLE_COLORS).includes(props.color)
            ? BET_CIRCLE_COLORS[props.color]
            : 'none'};

    @media (max-width: ${BREAKPOINTS.md + 'px'}) {
        min-width: ${BET_CIRCLE_SIZES.mobileSize};
        height: ${BET_CIRCLE_SIZES.mobileSize};
        border-radius: ${BET_CIRCLE_SIZES.mobileSize};
        line-height: ${BET_CIRCLE_SIZES.mobileSize};
        font-size: 8px;
    }
`

export const DoubleRouletteCircle = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
`

export const DoubleRouletteMerged = styled.div`
    margin: 0 3px;
    border-radius: 30px;
    width: 30px;
    background: ${(props) => (props.equal ? '#c49b53' : 'none')};
    height: 100%;
    align-items: center;
    justify-content: space-evenly;
    flex-direction: column;
    display: flex;

    @media (max-width: ${BREAKPOINTS.md + 'px'}) {
        width: ${BET_CIRCLE_SIZES.mobileSize};
        height: ${BET_CIRCLE_SIZES.mobileSize};
        border-radius: ${BET_CIRCLE_SIZES.mobileSize};
    }
`

export const RouletteCircle = styled(BetCircle)`
    ${(props) =>
        props.double &&
        css`
            margin: 0;
            &:first-child {
                margin-bottom: 2px;
            }
            &:last-child {
                margin-top: 2px;
            }
        `};
`

export const BlackjackCircle = styled(BetCircle)`
    background: ${(props) =>
        props.color ? BET_CIRCLE_COLORS.green : BET_CIRCLE_COLORS.black};
`

export const BlackjackTakenSeat = styled(AvatarIcon)`
    filter: ${(props) =>
        props.taken
            ? 'invert(100%) sepia(100%) saturate(0%) hue-rotate(288deg) brightness(102%) contrast(102%)'
            : 'invert(42%) sepia(0%) saturate(0%) hue-rotate(186deg) brightness(104%) contrast(92%)'};
`
export const BlackjackBetBehind = styled.div`
    color: #fff;
    font-size: 14px;
    text-align: center;
    font-weight: bold;
    width: 100%;
`

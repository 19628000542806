import styled from 'styled-components'
import { OpretBtn } from '../Login/styled'

export const ContentWrapper = styled.div`
    padding: 0 23px;
`
export const Description = styled.p`
    padding: 25px 0;
    margin: 0;
    font-size: 16px;
    font-family: ${({ condensed }) =>
            condensed ? 'Roboto Condensed' : 'inherit'},
        sans-serif;
    color: ${({ color }) => color || '#333'};
`

export const ConfirmButton = styled(OpretBtn)`
    margin-top: 40px;
    height: 55px;
    width: 100%;
    box-shadow: none;
    color: ${({ color }) => color || '#333333'};
    background: ${({ activeColor }) => activeColor || '#fbd600'};
    &:disabled {
        background: ${({ disableColor }) => disableColor || '#fff8cb'};
        opacity: 0.6;
        cursor: auto;
    }
`

import { ENV, getEvoSkin } from '../configs/env'
import { post } from './FetchAdapter'
import { detectIpad, detectMobile } from '../utils/deviceDetect'
import {
    API_URL,
    APP_NAME,
    CONTENT_API_BASE_URL,
    GAME_LAUNCH_API,
} from '../configs/main'
import { useContext } from 'react'
import { AppContext } from '../contexts/AppContex'

const playGame = (options, callback) => {
    getGameLaunchUrl(options).then((apiUrl) => {
        apiUrl ? callback(apiUrl) : null
    })
}

const start = (game, userInfo, skin, callback) => {
    if (Object.keys(userInfo).length) {
        let options = {
            game_id: game.game_id,
            server_mode: ENV(),
            mode: 'money',
            srv: ENV(),
            pngDiv: game.game_id,
            ...skin,
        }

        playGame(options, callback)
    }
}

const getGameLaunchUrl = (options) => {
    return post(CONTENT_API_BASE_URL + 'core/getSes.php', '', {
        credentials: 'include',
    })
        .then((data) => {
            return getLaunchLink({
                ...options,
                session_id: 'JSESSIONID=' + data.sessId,
            })
        })
        .catch((err) => {
            console.error(err.toString())
            return err.toString()
        })
}

const getLaunchLink = (options) => {
    let requestParams = {
        ...options,
        lobby:
            detectMobile() && !detectIpad()
                ? window.location.origin
                : window.location.origin + '/lobby',
    }

    if (!detectMobile() || detectIpad()) {
        requestParams.platform = 'web'
    }

    return post(GAME_LAUNCH_API + 'launch/rod25', requestParams)
        .then((res) => {
            if (res.error) {
                console.error(res.error.message)
            }

            if (res.data) {
                return res.data.url
            }
        })
        .catch((error) => {
            console.error(error.toString())
            return error.toString()
        })
}

const LaunchGame = (game, userInfo, skin) => {
    if (game.subprovider === 'evolution') {
        start(game, userInfo, skin, (link) => {
            window.location.href = link
        })
        return
    }
}

const LaunchLobby = (skin, category = 'top_games') => {
    return post(CONTENT_API_BASE_URL + 'core/getSes.php', '', {
        credentials: 'include',
    })
        .then((data) => {
            return getEvoCategoryLink({
                app: APP_NAME,
                server_mode: ENV(),
                srv: ENV(),
                category: category,
                session_id: 'JSESSIONID=' + data.sessId,
                ...skin,
            })
        })
        .catch((err) => {
            console.error(err.toString())
            return err.toString()
        })
}

const getEvoCategoryLink = (options) => {
    return post(API_URL + 'categories/evolution', options)
        .then((res) => {
            if (res.error) {
                console.error(res.error.message)
            }

            if (res.data) {
                let link = res.data.url

                return link
            }
        })
        .catch((error) => {
            console.error(error.toString())
            return error.toString()
        })
}

export default {
    LaunchLobby,
    LaunchGame,
}

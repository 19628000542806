import React, { Fragment, useEffect, useRef, useState } from 'react'
import moment from 'moment'
import LottiePlayer from 'react-lottie-player/dist/LottiePlayerLight'

import Text from './../../Text'
import styled, { keyframes } from 'styled-components'
import PropTypes from 'prop-types'
import {
    dateFormatter,
    numberWithCommasAndDotsIntl,
} from '../../../utils/formatters'
import Icon from '../../Icon'
import notFoundAnimation from '../../../images/lootie-animations/not-found-animation.json'

import arrowRight from '../../../images/icons/arrowRight.svg'
import Spinner from '../../Spinner'
import { StyledButton } from './styled'
import Flex from '../../Flex'
import log from 'eslint-plugin-react/lib/util/log'

export const dissolve = keyframes`
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
`

const Wrapper = styled.div`
    overflow-y: auto;
    flex-grow: 1;
    padding-bottom: 10px;
    padding-top: ${(props) => props.scrolled && props.scrolled + 'px'};
    text-align: center;

    /* Remove Scrolling */
    scrollbar-width: none;

    &::-webkit-scrollbar {
        width: 0;
        height: 0;
    }
`
const TransactionsHeading = styled.h5`
    color: ${(props) => props.theme.colors.black_font_900};
    font-size: 20px;
    padding: 0;
    margin: 0 0 15px;
    text-align: left;
`

const TransactionsList = styled.div`
    display: block;
    border-radius: 8px;
    overflow: hidden;
`

const GroupItem = styled.div`
    display: flex;
    background: ${(props) => props.theme.colors.bg};
    color: ${(props) => props.theme.colors.black_font_900};
    justify-content: space-between;
    align-items: center;
    padding: 16px;
    ${({ border, theme }) =>
        border && `border-bottom: 1px solid ${theme.colors.stroke_400}`};
    cursor: pointer;
    animation: ${dissolve} 300ms ease-out;
    transition: transform 300ms ease-out;
    animation-delay: ${(props) => props.delay}s;

    &:hover {
        background: ${(props) => props.theme.colors.table_tint_300};
    }
`

const GroupRow = styled.div`
    height: auto;
    text-align: ${({ align }) => (align ? align : 'left')};
    ${({ flex }) => `flex: ${flex}`};
`

const StyledText = styled(Text)`
    font-size: 13px;
    line-height: normal;
    color: ${(props) =>
        props.color ? props.color : props.theme.colors.black_font_900};
    margin: 0;
`

const TextLabel = styled.span`
    color: ${(props) =>
        props.color ? props.color : props.theme.colors.placeholder};
`

const Link = styled.span`
    text-align: center;
    cursor: pointer;
    font-weight: bold;
    text-decoration: underline;
    color: ${(props) => props.theme.colors.link_blue};
`

const TransactionList = ({
    data = [],
    theme,
    groupBy = 'MMMM',
    loading,
    showMore,
    periodDate = [],
    transactionDataFormat = 'DDMMYYYY',
    showDetails = () => {},
    loadMoreHandler = () => {},
    loadMoreColor = '',
}) => {
    const [sortedData, setSortedData] = useState(null)
    const [periodTitle, setPeriodTitle] = useState('')
    const [scrolled, setScrolled] = useState(80)
    const contentRef = useRef(null)

    const handleScroll = () => {
        if (contentRef.current) {
            const scrollTop = contentRef.current.scrollTop
            const newMarginTop = Math.max(80 - scrollTop, 0) // Decrease margin but not below 0
            setScrolled(newMarginTop)
        }
    }

    useEffect(() => {
        const transactionListRef = contentRef.current
        if (transactionListRef) {
            transactionListRef.addEventListener('scroll', handleScroll)
            return () =>
                transactionListRef.removeEventListener('scroll', handleScroll)
        }
    }, [])

    useEffect(() => {
        if (periodDate.length) {
            const fromDate = moment(
                periodDate[0],
                transactionDataFormat
            ).format('DD. MMM YYYY')
            const toDate = moment(periodDate[1], transactionDataFormat).format(
                'DD. MMM YYYY'
            )
            setPeriodTitle(`${fromDate} - ${toDate}`)
            setSortedData(data)
        } else {
            setPeriodTitle('')
            setSortedData(data)
        }
    }, [periodDate, groupBy, data])

    const groupItem = (transaction) => {
        return (
            <Fragment>
                <GroupRow flex={6}>
                    <StyledText style={{ margin: '0 0 4px' }}>
                        {transaction.text}{' '}
                        <TextLabel>{transaction.product}</TextLabel>
                    </StyledText>
                    <StyledText>
                        {dateFormatter(
                            new Date(
                                transaction.transactionDate
                            ).toLocaleString('da-DK', {
                                dateStyle: 'short',
                                timeStyle: 'short',
                            })
                        )}
                    </StyledText>
                </GroupRow>
                <GroupRow flex={3}>
                    <StyledText
                        align={'right'}
                        color={
                            transaction.amount >= 0 ? theme.colors.active : null
                        }
                        style={{ margin: '0 0 4px' }}
                    >
                        {numberWithCommasAndDotsIntl(transaction.amount)} kr.
                    </StyledText>
                    <StyledText align={'right'}>
                        {numberWithCommasAndDotsIntl(transaction.balance)} kr.
                    </StyledText>
                </GroupRow>
                <GroupRow flex={1} align={'right'}>
                    <Icon
                        src={arrowRight}
                        width={'16px'}
                        height={'16px'}
                        color={theme.colors.semi_grey_500}
                    />
                </GroupRow>
            </Fragment>
        )
    }

    return (
        <Wrapper ref={contentRef} scrolled={scrolled}>
            <TransactionsHeading>
                {periodTitle ? periodTitle : 'Seneste transaktioner'}
            </TransactionsHeading>
            {data.length ? (
                <Fragment>
                    <TransactionsList>
                        {sortedData &&
                            sortedData
                                .sort(
                                    (a, b) =>
                                        new Date(b.transactionDate) -
                                        new Date(a.transactionDate)
                                )
                                .map((transaction, index) => (
                                    <GroupItem
                                        key={index}
                                        border={index < sortedData.length - 1}
                                        onClick={() => showDetails(transaction)}
                                        //delay={index * 0.1}
                                    >
                                        {groupItem(transaction)}
                                    </GroupItem>
                                ))}
                    </TransactionsList>
                    {showMore && (
                        <Flex style={{ margin: '16px 0' }}>
                            <StyledButton
                                maxWidth={'352px'}
                                bgColor={loadMoreColor || theme.colors.red}
                                onClick={loadMoreHandler}
                                disabled={loading}
                            >
                                <Text color={'white'} align={'center'}>
                                    Vis mere
                                </Text>
                                {loading && (
                                    <Spinner color={'#fff'} size={'md'} />
                                )}
                            </StyledButton>
                        </Flex>
                    )}
                </Fragment>
            ) : loading ? (
                <Spinner size={'md'} style={{ textAlign: 'center' }} />
            ) : (
                <Flex direction={'column'} style={{ margin: '0 0 32px' }}>
                    <LottiePlayer
                        loop
                        animationData={notFoundAnimation}
                        play
                        style={{
                            width: '100px',
                            height: '100px',
                            margin: '0 auto',
                        }}
                    />
                    <Text color={theme.colors.placeholder}>
                        Ingen transaktioner fundet
                    </Text>
                </Flex>
            )}
            <Flex
                background={theme.colors.table_tint_300}
                borderRadius={'8px'}
                align={'baseline'}
                justify={'flex-start'}
                direction={'column'}
                style={{ padding: '24px', margin: '16px 0' }}
            >
                <Text
                    size={18}
                    weight={700}
                    color={theme.colors.black_font_900}
                    margin={'0 0 16px 0'}
                >
                    Leder du efter noget specifikt?
                </Text>
                <Text size={13} lineHeight={'20px'} margin={'0'}>
                    For en fuld transaktionsoversigt, eller en liste af
                    transaktioner for et givet tidsrum bedes du rette
                </Text>
                <Text size={13} lineHeight={'20px'} margin={'0'}>
                    henvendelse til vores{' '}
                    <Link onClick={() => window.Intercom('show')} bold>
                        kundeservice
                    </Link>
                </Text>
            </Flex>
        </Wrapper>
    )
}

TransactionList.propTypes = {
    data: PropTypes.array.isRequired,
    theme: PropTypes.object,
    groupBy: PropTypes.string,
    loading: PropTypes.bool,
    showMore: PropTypes.bool,
    transactionDataFormat: PropTypes.string,
    periodDate: PropTypes.array,
    showDetails: PropTypes.func,
    loadMoreHandler: PropTypes.func,
}

export default TransactionList

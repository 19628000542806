import styled from 'styled-components'

export const SignUpWrapper = styled.div`
    height: auto;
    min-height: 100%;
    box-sizing: border-box;
    display: block;
    background: ${(props) => props.theme.colors.bg};
    @media screen and (max-width: 480px) {
        width: 100%;
    }
`

export const SignUpTop = styled.div`
    height: auto;
    min-height: 35px;
    width: 100%;
    display: flex;
    margin-bottom: 10px;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    box-sizing: border-box;
    position: relative;
`

export const DefaultBtn = styled.div`
    width: 45px;
    height: 45px;
    border-radius: 5px;
    color: ${(props) => props.theme.colors.primaryAction};
    background: ${(props) => props.theme.colors.bg};
    transition: all 0.3s ease;
    cursor: pointer;
    @media screen and (max-width: 1024px) {
        background: ${(props) =>
            props.theme.name === 'default'
                ? props.theme.colors.primaryActionHover
                : props.theme.colors.bgDarker};
    }
    &:hover {
        background: ${(props) =>
            props.theme.name === 'default'
                ? props.theme.colors.primaryActionHover
                : props.theme.colors.bgDarker};
    }
`

export const CloseSignupBtn = styled(DefaultBtn)`
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: -5px;
    right: -5px;
`

export const BackBtn = styled(DefaultBtn)`
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: -5px;
    left: -5px;
`

export const RadioWrapper = styled.div`
    margin: ${({ margin }) => margin || '0 auto'};
    width: 100%;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    align-items: ${({ alignItems }) => alignItems || 'center'};
    flex-wrap: ${({ wrap }) => wrap || 'wrap'};
    div {
        margin: ${({ marginInside }) => marginInside || '20px 0 0'};
        box-sizing: border-box;
        width: ${({ radioWidth }) => radioWidth || '49%'};
        display: flex;
        justify-content: flex-start;
        align-items: center;
        &:first-child {
            margin-top: 0;
            margin-right: 40px;
        }
    }
    input {
        cursor: pointer;
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        position: relative;
        height: 20px;
        width: 20px;
        font-size: 16px;
        border-radius: 100%;
        border: 1px solid ${(props) => props.theme.colors.primaryAction};
        transition: all 0.3s linear;
        outline: none;
        background: ${(props) =>
            props.theme === 'default'
                ? props.theme.colors.bg
                : props.theme.colors.bgDarker};
        margin: 0 14px 0 0;
        flex-shrink: 0;
        &:checked {
            border: 7px solid ${(props) => props.theme.colors.active};
        }
    }
    label {
        cursor: pointer;
        font-size: 16px;
        color: ${(props) => props.theme.colors.basic};
    }
`

export const TextRulesWrapper = styled.div`
    width: 100%;
    position: relative;
    padding: 22px 20px 22px;
    margin: 32px auto 20px;
    background: ${(props) => props.theme.colors.bgDarker};
    border-radius: 5px;
    box-sizing: border-box;
    border: 1px solid
        ${(props) =>
            props.valid
                ? props.theme.colors.active
                : props.theme.colors.bgDarker};
    a {
        line-height: 20px;
        font-size: ${({ linkFontSize }) => linkFontSize || '12px'};
        color: ${(props) => props.theme.colors.link};
    }
`

export const NextButtonDefault = styled.button`
    background: ${(props) =>
        props.disabled
            ? props.theme.colors.secondary
            : props.theme.colors.secondaryAction};
    color: ${(props) =>
        props.disabled
            ? props.theme.colors.primaryAction
            : props.theme.colors.basic};
    opacity: 1;
    bottom: 0;
    width: 100%;
    border-radius: 8px;
    font-size: 16px;
    height: 54px;
    font-weight: ${(props) => props.theme.fontWeight};
    border: none;
    cursor: pointer;
`

export const NextButton = styled(NextButtonDefault)`
    @supports (position: sticky) {
        position: sticky;
    }
    @supports not (position: sticky) {
        position: relative;
    }
    bottom: 0;
`

export const FourthStepBtn = styled.button`
    position: relative;
    height: 54px;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    outline: none;
    font-size: 16px;
    font-weight: ${(props) => props.theme.fontWeight};
    font-family: ${(props) => props.theme.fontFamily};
    background: ${({ mitid }) => (mitid ? '#1a49d5' : '#e5ebf5')};
    cursor: pointer;
    border: ${({ mitid }) =>
        mitid ? '1px solid #1a49d5' : '1px solid #caced6'};
    transition: all 0.3s ease-out 0s;
    border-radius: 8px;
    margin: ${({ margin }) => margin || '25px auto 0'};
    color: ${({ mitid }) => (mitid ? '#fff' : '#333')};
    &:hover {
        color: ${({ mitid }) => (mitid ? '#fff' : '#333')};
        background: ${({ mitid }) => (mitid ? '#1841ba' : '#caced6')};
    }
`

export const ExpanderWrapper = styled.div`
    display: flex;
    justify-content: space-between;
`

export const ExpanderContent = styled.div`
    display: flex;
    align-items: center;
    gap: 12px;
`

export const InfoBox = styled.div`
    background: ${(props) => props.theme.colors.bgBlue};
    border: 1px solid ${(props) => props.theme.colors.borderBlue};
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 16px;
    padding: 0 16px;
`

import React, { Fragment, useEffect, useState } from 'react'
import { Text } from '@it25syv/25syv-ui'
import { FourthStepBtn } from './styled'
import { ReactComponent as MitIdIcon } from './icons/mitID.svg'
import AuthService from '../services/AuthService'

const LOGIN_TYPES = {
    MIT_ID: 'mitid',
    MIT_ID_DEMO: 'mitid_demo' // allows to put any credentials on Mit ID for testing
}

const Step4 = ({ userData = {}, scrollToTop = () => {}, theme, site = '' }) => {
    const [signupError, setSignupError] = useState('')

    useEffect(() => {
        scrollToTop()
    }, [])

    const finishSignUp = (loginType) => {
        return AuthService.startMitId(userData.cpr, loginType, site)
            .then((response) => {
                if (response.statusCode !== 200 || !response.success) {
                    return Promise.reject(response.errorMessage)
                }
                console.log(response)
                window.location.href = response.data
            })
            .catch((error) => {
                setSignupError(
                    typeof error === 'object' ? error.errorMessage : error
                )
            })
    }

    const errorMessage = signupError ? (
        <Text color='#D10000' center>
            {signupError}{' '}
            <i className='fa fa-exclamation-triangle' aria-hidden='true' />
        </Text>
    ) : null

    return (
        <Fragment>
            {errorMessage}
            <Text
                center
                color={theme.colors.basic}
                style={{
                    textAlign: 'center',
                    fontSize: '16px',
                    padding: 0,
                    margin: '32px auto 0'
                }}
            >
                Vælg metode
            </Text>
            <FourthStepBtn
                mitid
                onClick={() => finishSignUp(LOGIN_TYPES.MIT_ID)}
            >
                <MitIdIcon style={{ marginRight: '10px' }} />
                Bekræft med MitID
            </FourthStepBtn>
        </Fragment>
    )
}

export default Step4

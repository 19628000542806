import styled from 'styled-components'
import { BREAKPOINTS } from './themes/breakpoints'

export const CenterBlock = styled.div`
    width: 100%;
    max-width: 1150px;
    margin: 0 auto;
    box-sizing: border-box;
    padding: 0 15px;
    position: relative;

    @media (max-width: ${BREAKPOINTS.sm + 'px'}) {
        padding: 0 15px;
    }
`

export const MediaGrid = styled.div`
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 0.75rem;
    position: relative;

    @media (min-width: ${BREAKPOINTS.sm + 'px'}) {
        grid-template-columns: repeat(3, 1fr);
    }

    @media (min-width: ${BREAKPOINTS.md + 'px'}) {
        ${({ itemsPerRow }) =>
            `grid-template-columns: repeat(${itemsPerRow || 4}, 1fr);`}
    }

    @media (min-width: ${BREAKPOINTS.lg + 'px'}) {
        ${({ itemsPerRow }) =>
            `grid-template-columns: repeat(${itemsPerRow || 4}, 1fr);`}
        gap: ${({ gap }) => gap || '2rem 1rem'};
    }
`

export const HighlightBox = styled.div`
    font-size: 12px;
    height: 20px;
    background: rgba(0, 0, 0, 0.8);
    border-radius: 5px;
    padding: 0 5px;
    color: #fff;
    line-height: 20px;
    display: inline-block;

    @media (max-width: ${BREAKPOINTS.md + 'px'}) {
        height: 17px;
        line-height: 17px;
        font-size: 11px;
    }
`

export const TimerWrapper = styled.div`
    position: absolute;
    width: 183px;
    top: ${(props) => (props.top ? props.top : '50%')};
    left: ${(props) => (props.left ? props.left : '50%')};
    transform: translate(-50%, -50%);
    z-index: 1;

    @media (max-width: ${BREAKPOINTS.lg + 'px'}) {
        width: 119px;
    }
`

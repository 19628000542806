import React, { Fragment, useEffect, useState } from 'react'
import { cutEmptyValues, hasNumber } from '../utils/formatters'
import styled from 'styled-components'
import { Text, ValidatingInput } from '@it25syv/25syv-ui'
import { NextButton, RadioWrapper, TextRulesWrapper } from './styled'
import AuthService from '../services/AuthService'
import Avatar from '../components/Avatar'
import { getLinks } from '../configs/main'

const NewsLetterTitle = styled.div`
    position: absolute;
    top: -26px;
    left: 8px;
    color: ${(props) => props.theme.colors.primary};
    font-size: 14px;
`
const BonusCodeWrapper = styled.div`
    width: 100%;
    color: ${(props) => props.theme.colors.primary};
    padding-left: 10px;
    p {
        display: inline-block;
        text-decoration: underline;
        cursor: pointer;
    }
    span {
        text-decoration: none;
        margin-left: 20px;
        color: ${(props) => props.theme.colors.active};
        font-size: 14px;
    }
`

const Step1 = ({
    setStep = () => {},
    updateUserData = () => {},
    onEmailExists = () => {},
    setStepValidity,
    userData,
    setUserData,
    onFocus,
    isTerminal,
    terminalEmailExist,
    theme,
    avatar,
    showBonusCode,
    project,
    oldAPI = false,
    step1Headline = ''
}) => {
    const LINKS = getLinks(project)
    const [isPasswordHidden, setIsPasswordHidden] = useState(true)
    const [passwordStrength, setPasswordStrength] = useState(0)
    const [passwordHint, setPasswordHint] = useState('Minimum 10 tegn')
    const [isbonusCodeVisible, setIsbonusCodeVisible] = useState(false)
    const [errors, setErrors] = useState({
        email: '',
        password: ''
    })

    const showPassword = () => {
        setIsPasswordHidden(!isPasswordHidden)
    }

    const passwordChangeHandler = (value) => {
        value = cutEmptyValues(value)
        let strength = 0
        const hasNumber = /\d/
        function hasUpperCase(str) {
            return str.toLowerCase() !== str
        }

        if (value.length < 9) {
            setPasswordHint('Minimum 10 tegn')
        } else if (!hasUpperCase(value)) {
            setPasswordHint('Minimum ét STORT bogstav')
        } else if (!hasNumber.test(value)) {
            setPasswordHint('Minimum ét tal')
        } else {
            setPasswordHint('')
        }

        if (hasNumber.test(value)) strength = strength + 1
        if (hasUpperCase(value)) strength = strength + 1
        if (value.length >= 10) strength = strength + 1

        setPasswordStrength(strength)
        setUserData({ ...userData, password: value })
    }
    const onChangeSubscription = (e) => {
        setUserData({ ...userData, newsletterSub: e.target.value })
    }
    const checkValue = (error) => {
        if (error) {
            setErrors({ ...errors, [error.name]: error.value })
        }
    }
    const submitHandler = () => {
        AuthService.checkEmail(userData.email, oldAPI).then((res) => {
            if (res?.data) {
                if (res?.data?.exist === false) {
                    const cUserData = {
                        ...userData,
                        stepsValidated: {
                            ...userData.stepsValidated,
                            step1: true
                        }
                    }
                    updateUserData(cUserData)
                    setStep(2)
                } else if (res?.data?.exist === true) {
                    const cUserData = {
                        ...userData,
                        stepsValidated: {
                            ...userData.stepsValidated,
                            step1: false
                        }
                    }
                    updateUserData(cUserData)
                    onEmailExists()
                    if (isTerminal) {
                        terminalEmailExist(true)
                    }
                }
            }
        })
    }

    const checkEmail = (value) => {
        const email = cutEmptyValues(value)
        setUserData({ ...userData, email: email, userName: email })
    }

    const isDataValid =
        userData.password &&
        userData.newsletterSub &&
        userData.email &&
        !errors.email &&
        !errors.password

    useEffect(() => {
        if (userData?.password) {
            passwordChangeHandler(userData.password)
        }
    }, [])

    useEffect(() => {
        setStepValidity(isDataValid)
    }, [isDataValid])

    return (
        <Fragment>
            {avatar.show && <Avatar path={avatar.path} />}
            <ValidatingInput
                autoFocus={!isTerminal}
                type='text'
                title='Email'
                name='email'
                autoComplete='new-email'
                onCheckValue={checkValue}
                validate='required, email'
                value={userData.email}
                onFocus={(elem, validVal) => {
                    onFocus(elem, checkEmail, validVal)
                }}
                onChangeHandler={(value) => {
                    checkEmail(value)
                }}
                theme={theme.name}
            />
            <ValidatingInput
                onChangeHandler={passwordChangeHandler}
                onCheckValue={checkValue}
                type={isPasswordHidden ? 'password' : 'text'}
                value={userData.password}
                title='Kodeord'
                autoComplete='new-password'
                validate='required, password'
                min={10}
                max={15}
                onFocus={(elem, validVal) => {
                    onFocus(elem, passwordChangeHandler, validVal)
                }}
                name='password'
                bottomLabel={passwordHint}
                altLabel={isPasswordHidden ? 'Vis' : 'Skjul'}
                altLabelClickHandler={showPassword}
                passwordStrength={passwordStrength}
                theme={theme.name}
            />
            <TextRulesWrapper
                valid={
                    userData.newsletterSub === '1' ||
                    userData.newsletterSub === '0'
                }
            >
                <NewsLetterTitle>Nyheder og kampagner</NewsLetterTitle>
                <Text
                    bold
                    color={theme.colors.basic}
                    size='x2'
                    style={{
                        margin: '0 0 17px',
                        fontSize: '18px',
                        fontWeight: theme.fontWeight
                    }}
                >
                    {step1Headline ? step1Headline : 'Få 25 kr. via e-mail'}
                </Text>
                <RadioWrapper
                    value={userData.newsletterSub}
                    wrap='nowrap'
                    marginInside='0'
                >
                    <div>
                        <input
                            type='radio'
                            value='1'
                            name='subYes'
                            autoComplete='new-subYes'
                            id='subYes'
                            checked={userData.newsletterSub === '1'}
                            onChange={onChangeSubscription}
                        />
                        <label htmlFor='subYes'>Ja tak</label>
                    </div>
                    <div>
                        <input
                            type='radio'
                            value='0'
                            name='subNo'
                            autoComplete='new-subNo'
                            id='subNo'
                            checked={userData.newsletterSub === '0'}
                            onChange={onChangeSubscription}
                        />
                        <label htmlFor='subNo'>Nej tak</label>
                    </div>
                </RadioWrapper>
                <Text
                    color={theme.colors.primaryAction}
                    size='x02'
                    lh='20'
                    style={{
                        margin: '20px 0 0 ',
                        fontSize: '12px',
                        lineHeight: '20px'
                    }}
                >
                    Ved tilmelding gives tilladelse til at Bet25 A/S (herunder
                    CasinoGo) må behandle oplysninger om mig og sende mig
                    nyheder og markedsføring af vores onlinecasino, væddemål,
                    events, kampagner og konkurrencer via e-mail og sms. Du kan
                    altid tilbagekalde dit samtykke på{' '}
                    <a
                        target='_blank'
                        href='https://www.bet25.dk'
                        rel='noreferrer'
                    >
                        Bet25.dk
                    </a>{' '}
                    og{' '}
                    <a
                        target='_blank'
                        href='https://www.casinogo.dk'
                        rel='noreferrer'
                    >
                        CasinoGo.dk
                    </a>
                    , samt via vores apps eller via link i nyhedsbreve. Læs mere
                    om regler for beskyttelse af personlige oplysninger her:{' '}
                    <a target='_blank' href={LINKS.dataPolicy} rel='noreferrer'>
                        persondatapolitik
                    </a>
                </Text>
            </TextRulesWrapper>
            {showBonusCode && (
                <BonusCodeWrapper>
                    <p
                        onClick={() => {
                            setIsbonusCodeVisible(!isbonusCodeVisible)
                        }}
                    >
                        Jeg har en bonuskode
                    </p>{' '}
                    <span>(Valgfrit)</span>
                </BonusCodeWrapper>
            )}
            {isbonusCodeVisible && (
                <ValidatingInput
                    title='Bonuskode'
                    type='text'
                    autoComplete='new-bonusCode'
                    value={userData.bonusCode}
                    validate='numeric, min, max, max-length'
                    placeholder='ХХХХ'
                    min={4}
                    max={4}
                    onChangeHandler={(value) => {
                        setUserData({
                            ...userData,
                            bonusCode: hasNumber.test(value)
                                ? value.match(hasNumber).join('').slice(0, 4)
                                : ''
                        })
                    }}
                    onFocus={(elem, validVal) => {
                        onFocus(
                            elem,
                            (value) => {
                                setUserData({
                                    ...userData,
                                    bonusCode: hasNumber.test(value)
                                        ? value
                                              .match(hasNumber)
                                              .join('')
                                              .slice(0, 4)
                                        : ''
                                })
                            },
                            validVal
                        )
                    }}
                    theme={theme.name}
                />
            )}
            <NextButton
                active={!!isDataValid}
                width='100%'
                padding='16px'
                margin='20px auto'
                disabled={!isDataValid}
                yellow
                onClick={submitHandler}
            >
                Næste
            </NextButton>
        </Fragment>
    )
}

export default Step1

import React, { Fragment } from 'react'
import styled, { css } from 'styled-components'
import spacing from '../../utils/spacing'

const CommonInputStyle = css`
    border: none !important;
    background: none;
    padding: 0 15px;
    font-size: 14px;
    color: ${({ color }) => color || '#000'};
    font-weight: normal;
    text-align: ${(props) => (!props.align ? 'left' : props.align)};
    &::placeholder {
        color: ${({ color }) => color || '#d3d3d3'};
        font-weight: 900;
        opacity: 1;
        transition: opacity 0.3s ease-in-out;
        &:focus {
            opacity: 1;
            transition: opacity 0.3s ease-in-out;
        }
    }
    &:focus::placeholder {
        opacity: 0;
        transition: opacity 0.3s ease-in-out;
    }
    &:focus {
        outline: none;
    }
    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus,
    &:-webkit-autofill:active {
        // -webkit-box-shadow: 0 0 0 30px #eceff5 inset !important; //@TODO use it to disable autocomplete input background
        -webkit-text-fill-color: ${({ color }) => color || '#9198aa'};
    }
`

const InputControl = styled.input.attrs({
    autoComplete: 'off',
})`
    ${CommonInputStyle};
`

const TextAreaControl = styled.textarea`
    ${CommonInputStyle};
    resize: none;
`

const InputWrapper = styled.div`
    width: ${({ width }) => (width ? width : 'auto')};
    margin: ${({ margin }) => (!margin ? '10px' : margin)};
    height: ${({ height }) => (!height ? '30px' : height)};
    background: ${({ bg }) => bg || '#fff'};
    color: ${({ color }) => color || '#000'};
    border-radius: ${({ radius }) => (!radius ? '25px' : radius)};
    display: flex;
    flex-flow: column;
    justify-content: center;
    padding: ${({ padding = '10px' }) => padding};
    text-align: ${({ align }) => (!align ? 'left' : align)};
    border: ${(props) =>
        props.validationError && props.redBorder
            ? `1px solid #db1c1b`
            : 'none'};
    ${spacing};
`

const InputSubLabel = styled.small`
    color: ${({ color }) => (color ? color : '#9198aa')};
    font-size: 10px;
    color: ${({ color }) => color || '#000'};
    padding: 0 15px 5px;
    position: absolute;
    text-align: ${({ align }) => (!align ? 'left' : align)};
    bottom: 0;
    display: inline-block;
    width: ${({ padding = '10px', margin = '10px' }) =>
        `calc(100% - (${padding} * 2) - (${margin} * 2))`};
`

const ErrorUnderInput = styled.p`
    color: #db1c1b;
    font-size: 12px;
    margin: ${({ margin }) => (!margin ? '0' : margin)};
    padding: ${({ padding = '0 8px' }) => padding};
    font-family: 'Roboto Condensed', sans-serif;
    font-weight: 300;
`

const InputTopLabel = styled(InputSubLabel)`
    color: #9198aa;
    top: 7px;
    font-weight: 900;
    width: auto;
    height: 10px;
    padding-bottom: 0;
`

const ShowPasswordButton = styled.span`
    color: #9198aa;
    cursor: pointer;
    font-size: 14px;
    font-family: ${({ condensed }) =>
            condensed ? 'Roboto Condensed' : 'inherit'},
        sans-serif;
    font-weight: 300;
    position: absolute;
    right: 20px;
`

class Input extends React.Component {
    constructor() {
        super()

        this.state = {
            subLabel: true,
            passwordShown: false,
        }
    }

    onInputFocused = () => {
        if (this.props.value === '') {
            this.setState({ subLabel: false })
        }

        if (this.props.onInputFocusedHandler) {
            this.props.onInputFocusedHandler()
        }
    }

    onInputBluered = () => {
        if (this.props.value === '') {
            this.setState({ subLabel: true })
        }

        if (this.props.onInputBlueredHandler) {
            this.props.onInputBlueredHandler()
        }
    }

    onInputValueChanged = (event) => {
        event.stopPropagation()
        const value = event.target.value

        this.props.onChange && this.props.onChange(value)
    }

    renderShowPasswordButton = (condensed = false) => (
        <ShowPasswordButton
            condensed={condensed}
            onClick={() =>
                this.setState({ passwordShown: !this.state.passwordShown })
            }
        >
            {this.state.passwordShown ? 'Skjul' : 'Vis'}
        </ShowPasswordButton>
    )

    renderSubLabel = () => {
        return !this.props.validationError ? (
            this.state.subLabel && this.props.sublabel ? (
                <InputSubLabel align={this.props.align}>
                    {this.props.sublabel}
                </InputSubLabel>
            ) : null
        ) : (
            !this.props.showErrorUnderInput && (
                <InputSubLabel
                    color="red"
                    align={this.props.align}
                    inputPadding={this.props.padding}
                >
                    {this.props.validationError}
                </InputSubLabel>
            )
        )
    }

    render() {
        const inputProps = {
            ...this.props,
            innerRef: this.props.inputRef,
            onChange: this.onInputValueChanged,
            onFocus: this.onInputFocused,
            onBlur: this.onInputBluered,
            placeholder: this.props.placeholder,
            align: this.props.align,
            condensed: this.props.condensed,
            type:
                this.props.type === 'password' && this.state.passwordShown
                    ? 'text'
                    : this.props.type,
        }

        const { value, topLabel, validationError } = this.props

        const input =
            this.props.type === 'textarea' ? (
                <TextAreaControl
                    {...inputProps}
                    innerRef={
                        this.props.inputRef ? this.props.inputRef : undefined
                    }
                />
            ) : (
                <InputControl
                    {...inputProps}
                    innerRef={
                        this.props.inputRef ? this.props.inputRef : undefined
                    }
                />
            )

        const InputWrapperComponent =
            this.props.InputWrapperComponent || InputWrapper

        return (
            <Fragment>
                <InputWrapperComponent {...this.props}>
                    {value && topLabel && !validationError && (
                        <InputTopLabel>{topLabel}</InputTopLabel>
                    )}
                    {input}{' '}
                    {this.props.type === 'password' &&
                        this.renderShowPasswordButton(inputProps.condensed)}
                    {this.renderSubLabel()}
                </InputWrapperComponent>
                {this.props.validationError && this.props.showErrorUnderInput && (
                    <ErrorUnderInput
                        align={this.props.align}
                        margin={this.props.margin}
                        padding={this.props.padding}
                    >
                        {this.props.validationError}
                    </ErrorUnderInput>
                )}
            </Fragment>
        )
    }
}

export default Input

export { Input, InputWrapper, InputControl, InputSubLabel }

import React, { Fragment, useContext, useEffect, useState } from 'react'
import styled, { css } from 'styled-components'

import { ReactComponent as LockIcon } from '../images/icons/lock.svg'

import LazyLoad from 'react-lazyload'
import { getImageSrc } from '../services/ImageService'
import { IMG_PATH } from '../configs/main'
import PropTypes from 'prop-types'
import CountdownTimer from './Countdown'
import { convertTimeWithZone } from '../utils/time'
import { TimerWrapper } from '../styled'
import BottomBar from './BottomBar'
import FlagBar from './FlagBar'
import { BREAKPOINTS } from '../themes/breakpoints'
import { AppContext } from '../contexts/AppContex'
import GameLaunchService from '../services/GameLaunchService'
import { OPEN_HOURS_TYPE } from '../configs/categories'
import { isObject } from '../utils/utilsFunctions'

const skeletonLoader = IMG_PATH + 'skeleton-loader.gif'

const GameThumbWrapper = styled.div`
    position: relative;
    transition: all 0.3s ease;
    overflow: hidden;
    border-radius: 8px;
    border: 2px solid
        ${(props) => (props.privateTable ? '#C49B53' : 'transparent')};

    :hover {
        cursor: ${(props) =>
            props.closed || props.locked ? 'default' : 'pointer'};
        transform: scale(1.05);
    }
`

const TimerText = styled.p`
    color: #fff;
    font-size: 12px;
    line-height: 12px;
    text-align: center;
    margin: 0 0 8px;
`

const GameThumb = css`
    height: 100%;
    min-height: 175px;
    width: 100%;
    object-fit: cover;

    @media (max-width: ${BREAKPOINTS.xl + 'px'}) {
        height: 160px;
        min-height: auto;
    }

    @media (max-width: ${BREAKPOINTS.lg + 'px'}) {
        height: 140px;
        min-height: auto;
    }

    @media (max-width: ${BREAKPOINTS.md + 'px'}) {
        height: 130px;
        min-height: auto;
    }

    @media (max-width: ${BREAKPOINTS.sm + 'px'}) {
        height: 120px;
        min-height: auto;
    }

    ${(props) =>
        props.closed &&
        css`
            filter: brightness(50%);
        `};
`

const GameThumbImg = styled.img`
    ${GameThumb}
`
const GameThumbIframe = styled.iframe`
    z-index: -1;
    cursor: pointer;
    ${GameThumb}
`

const IframeLayer = styled.div`
    position: absolute;
    width: 100%;
    height: 100%;
`

const LockedGame = styled.div`
    display: inline-block;
    text-align: center;
    position: absolute;
    width: 50px;
    min-height: 50px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1;
`

const LockGameText = styled.div`
    color: #fff;
    font-size: 14px;
    opacity: 70%;
    margin: 15px 0 0;
`

const GameCard = ({
    game,
    lazyOverflow = false,
    width,
    height,
    gameClickHandler,
}) => {
    let liveCasinoGameData = game.liveCas
    const [imgPath, setImgPath] = useState('')
    const [newGame, setNewGame] = useState({})
    const [liveCasinoGame, setLiveCasinoGame] = useState(liveCasinoGameData)
    const [isActive, setIsActive] = useState(false)
    const [finishedCountdown, setFinishedCountdown] = useState(false)
    const appContextData = useContext(AppContext)

    function isEmptyObj(obj) {
        if (obj !== undefined) {
            for (const prop in obj) {
                if (Object.hasOwn(obj, prop)) {
                    return false
                }
            }
        }

        return true
    }

    const privateTable = () =>
        typeof liveCasinoGame.privateTableConfig === 'object' &&
        liveCasinoGame.privateTableConfig !== null
            ? liveCasinoGame.privateTableConfig.isPrivate
            : false

    const isGameClosed =
        !liveCasinoGame?.open &&
        isObject(liveCasinoGame?.workHours?.value) &&
        liveCasinoGame?.operationHours === OPEN_HOURS_TYPE &&
        !finishedCountdown

    const isGameLocked =
        liveCasinoGame?.seatsTaken !== null &&
        liveCasinoGame?.seatsTaken?.length >= 1 &&
        privateTable()

    const setLiveImg = () => {
        getImageSrc(game, (imgPath) => {
            const findImgPath = liveCasinoGame?.thumb
                ? liveCasinoGame?.thumb + '?t=' + new Date().getTime()
                : imgPath

            return setImgPath(findImgPath)
        })
    }

    const isExternalPath =
        game.subprovider === 'authentic' && game.external_image_path

    const onGameClick = (game) => {
        const userInfo = appContextData.userInfo

        if (userInfo && userInfo.userId) {
            if (userInfo.status === 'open' || userInfo.status === 'temp') {
                GameLaunchService.LaunchGame(
                    game,
                    userInfo,
                    appContextData.skin
                )
            } else {
                appContextData.notification({
                    text: 'User is selfExcluded',
                    type: 'selfExcluded',
                })
            }
        } else {
            appContextData.notification({
                text: 'Du spiller nu for sjov. Opret en konto eller log ind for at spille for rigtige penge',
                type: 'warning',
            })
            return
        }
    }

    useEffect(() => {
        setLiveImg()
        const interval = setInterval(setLiveImg, 1000 * 15)

        setTimeout(() => clearInterval(interval), 1000 * 60 * 15) //stop updating after 15 mins

        return () => clearInterval(interval)
    }, [])

    useEffect(() => {
        setLiveImg()
    }, [game])

    return (
        <LazyLoad
            once
            placeholder={
                <img
                    height={'auto'}
                    width={'100%'}
                    src={skeletonLoader}
                    alt="skeletonImg"
                    style={{ borderRadius: 8, maxHeight: '176px' }}
                />
            }
            overflow={lazyOverflow}
        >
            <GameThumbWrapper
                closed={isGameClosed || isGameLocked}
                onMouseEnter={() => setIsActive(true)}
                onMouseLeave={() => setIsActive(false)}
                privateTable={privateTable()}
                onClick={() => {
                    if (!isGameClosed && !isGameLocked) {
                        if (gameClickHandler !== undefined) {
                            return gameClickHandler(game, !isGameClosed)
                        } else {
                            onGameClick(game)
                        }
                    }
                }}
            >
                {isGameClosed && (
                    <TimerWrapper top={'45%'}>
                        <TimerText>Åbner om</TimerText>
                        <CountdownTimer
                            startTime={convertTimeWithZone(
                                game.liveCas.workHours?.value?.startTime
                            )}
                            completeCallback={(status) => {
                                return setFinishedCountdown(status)
                            }}
                            onMountCallback={(status) => {
                                return setFinishedCountdown(status)
                            }}
                        />
                    </TimerWrapper>
                )}
                <FlagBar providerName={game.subprovider} />
                {isGameLocked && (
                    <LockedGame>
                        <LockIcon />
                        <LockGameText>Optaget</LockGameText>
                    </LockedGame>
                )}
                {isExternalPath ? (
                    <Fragment>
                        <IframeLayer></IframeLayer>
                        <GameThumbIframe
                            border="0"
                            width={width}
                            height={height}
                            src={game.external_image_path}
                            allowFullScreen={false}
                        />
                    </Fragment>
                ) : (
                    <GameThumbImg
                        width={width}
                        height={height}
                        src={imgPath ? imgPath : skeletonLoader}
                        onError={() => setImgPath(skeletonLoader)}
                        loading="lazy"
                        closed={isGameClosed || isGameLocked}
                        alt=""
                    />
                )}
                <BottomBar
                    liveCasinoData={game.liveCas}
                    showPlayersCount={
                        !!Object.keys(game.liveCas).length && !privateTable()
                    }
                    subprovider={game.subprovider}
                    active={isActive}
                    gameType={game.subcat}
                    privateTable={privateTable()}
                />
            </GameThumbWrapper>
        </LazyLoad>
    )
}

GameCard.propTypes = {
    game: PropTypes.object.isRequired,
    lazyOverflow: PropTypes.bool,
    width: PropTypes.string,
    height: PropTypes.string,
    gameClickHandler: PropTypes.func,
}

export default GameCard

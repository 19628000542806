import React from 'react'
import styled from 'styled-components'
import del from '../../images/del.png'
import PropTypes from 'prop-types'

const CardWrapper = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    background-color: #fff;
    border: solid 1px lightgrey;
    border-radius: 30px;
    padding: 15px 15px;
`

const Image = styled.div`
    width: 100px;
    height: 90px;
    background: url(${({ image }) => image || 'none'}) no-repeat center;
    background-size: cover;
    border-radius: 25px;
    border: solid 1px lightgrey;
    text-align: center;
    line-height: 90px;
    font-size: 35px;
`

const CardContent = styled.div`
    flex-direction: column;
    justify-content: space-around;
    flex-basis: 80%;
    padding: 0 20px;
`

const Flag = styled.span`
    padding: 3px 5px;
    background-color: red;
    border-radius: 20px;
    text-align: center;
    color: white;
    font-weight: bold;
    font-size: 10px;
`

const CardTitle = styled.p`
    font-size: 20px;
    color: #404757;
    font-weight: bold;
    margin-top: 5px;
`

const Row = styled.div`
    display: flex;
    flex-direction: row;
`

const Children = styled(Row)`
    font-size: 16px;
`

const BottomLine = styled(Row)`
    padding-top: 20px;
`

const CloseIcon = styled.div`
    width: 20px;
    height: 20px;
    background: url(${del}) no-repeat;
    background-size: cover;
    cursor: pointer;
`

const Card = ({
    image,
    flag = '',
    children = '',
    title = '',
    closable = false,
    BottomLineContent,
    onClose = () => {},
    styled: { CardWrapper = CardWrapper },
}) => {
    return (
        <CardWrapper>
            {<Image image={image}>{!image ? title[0] : ''}</Image>}
            <CardContent>
                {flag && <Flag>{flag}</Flag>}
                <CardTitle>{title}</CardTitle>
                {children && <Children>{children}</Children>}
                {BottomLineContent && (
                    <BottomLine>
                        <BottomLineContent />
                    </BottomLine>
                )}
            </CardContent>
            {closable && <CloseIcon onClick={onClose} />}
        </CardWrapper>
    )
}

Card.propTypes = {
    image: PropTypes.string,
    flag: PropTypes.string,
    title: PropTypes.string,
    children: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.node,
        PropTypes.arrayOf(PropTypes.node),
    ]),
    BottomLineContent: PropTypes.elementType,
    closable: PropTypes.bool,
    onClose: PropTypes.func,
}

export default Card

export { CardWrapper }

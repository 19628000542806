import React, { Fragment, useCallback, useEffect, useState } from 'react'
import { hasNumber, thousandsDots } from '../utils/formatters'
import * as Styled from './styled'
import { Text, ValidatingInput } from '@it25syv/25syv-ui'
import {
    TabsWrapper,
    ButtonSwitcher,
    Tab,
    ActiveTabPad,
    Line,
    TabsLabel
} from '../components/Tabs'
import pick from 'lodash/pick'
import { getLinks } from '../configs/main'
import { ReactComponent as CheckMarkIcon } from './icons/checkmark.circle.svg'
import { ReactComponent as InfoIcon } from './icons/info.circle.fill.svg'

export const DEPOSIT_LIMITS = {
    daily: {
        name: 'daily',
        APIPropertyKey: 'dailyDepositLimit',
        leftIndent: '5px',
        maxValue: 100000,
        format: '######',
        title: 'Daglig',
        subTitle: 'dag'
    },
    weekly: {
        name: 'weekly',
        APIPropertyKey: 'weeklyDepositLimit',
        leftIndent: '33%',
        maxValue: 700000,
        format: '######',
        title: 'Ugentlig',
        subTitle: 'uge'
    },
    monthly: {
        name: 'monthly',
        APIPropertyKey: 'monthlyDepositLimit',
        leftIndent: '66%',
        maxValue: 3000000,
        format: '#######',
        title: 'Månedlig',
        subTitle: 'måned'
    }
}

const maxDepositValues = [
    {
        label: '0 - 2.000',
        value: '0'
    },
    {
        label: '2.000 - 5.000',
        value: '1'
    },
    {
        label: '5.000 - 10.000',
        value: '2'
    },
    {
        label: '10.000+',
        value: '3'
    }
]

const Step3 = ({
    userData,
    setUserData,
    onSave,
    setStep,
    scrollToTop = () => {},
    setStepValidity,
    serverError = '',
    onFocus,
    isTerminal,
    theme,
    project,
    minimumDepositLimit
}) => {
    const LINKS = getLinks(project)
    const [depositRef, setDepositRef] = useState(null)
    const [errors, setErrors] = useState({
        depositLimit: '',
        expectBetting: '',
        rulesAccepted: false
    })
    const [showInfoBox, setShowInfoBox] = useState(false)

    useEffect(() => {
        scrollToTop()
    }, [])

    const depositLimitConfig = DEPOSIT_LIMITS[userData.depositLimitType]

    useEffect(() => {
        if (userData.depositLimit > depositLimitConfig.maxValue) {
            setUserData({
                ...userData,
                depositLimit: depositLimitConfig.maxValue
            })
        }
    }, [userData.depositLimitType])

    const checkValue = (error) => {
        if (error) {
            setErrors({ ...errors, [error.name]: error.value })
        }
    }

    const loseLimit = false

    const isDataValid = () => {
        const values = Object.values(
            pick(userData, ['depositLimit', 'rulesAccepted'])
        )

        return (
            values.every((data) => !!data) &&
            Object.values(errors).every((error) => error == false)
        )
    }

    useEffect(() => {
        setStepValidity(isDataValid)
    }, [isDataValid])

    const onSubmitSuccess = () => setStep(4)

    const submit = () => {
        onSave(onSubmitSuccess)
    }

    const errorMessage = (
        <Text color='#D10000' center>
            {serverError}{' '}
            <i className='fa fa-exclamation-triangle' aria-hidden='true' />
        </Text>
    )

    const renderDepositLimitSwitcher = () => {
        const tabsConfig = Object.keys(DEPOSIT_LIMITS)
        return (
            <>
                {serverError ? errorMessage : null}
                <Text
                    color={theme.colors.basic}
                    style={{ margin: '40px 0 16px', fontSize: '20px' }}
                    weight={theme.fontWeight}
                >
                    Sæt en indbetalingsgrænse
                </Text>
                <TabsLabel
                    padding='0 0 8px'
                    style={{ fontSize: '14px', margin: 0 }}
                >
                    Vælg periode og beløb
                </TabsLabel>
                <TabsWrapper>
                    <ButtonSwitcher>
                        <ActiveTabPad
                            style={{
                                width: 'calc(33% + 1px)',
                                left: depositLimitConfig.leftIndent
                            }}
                        />
                        {tabsConfig.map((key, index) => {
                            const tabConfig = DEPOSIT_LIMITS[key]
                            return (
                                <Fragment key={tabConfig.name}>
                                    <Tab
                                        onClick={() => {
                                            setUserData({
                                                ...userData,
                                                depositLimitType: tabConfig.name
                                            })
                                            setTimeout(
                                                () =>
                                                    depositRef.current.onBlur(),
                                                0
                                            )
                                        }}
                                        active={
                                            userData.depositLimitType ===
                                            tabConfig.name
                                        }
                                        data-deposit-max-amount
                                    >
                                        {tabConfig.title}
                                    </Tab>
                                    {index < tabsConfig.length - 1 && <Line />}
                                </Fragment>
                            )
                        })}
                    </ButtonSwitcher>
                </TabsWrapper>
            </>
        )
    }

    const formattedBottomLabel = useCallback(
        (maxValue) => (
            <Fragment>
                Max. grænse er <b>{thousandsDots(maxValue)}</b> kr.{' '}
                <span style={{ textTransform: 'lowercase' }}>
                    {DEPOSIT_LIMITS[userData.depositLimitType].title}
                </span>
            </Fragment>
        ),
        [userData?.depositLimitType]
    )

    const renderInputExpanderContent = useCallback(() => {
        const { title, subTitle } = DEPOSIT_LIMITS[userData.depositLimitType]
        return (
            <Styled.ExpanderWrapper>
                <Styled.ExpanderContent>
                    <CheckMarkIcon />
                    <Text color={theme.colors.basic} size='sm'>
                        {title} grænse
                    </Text>
                </Styled.ExpanderContent>
                <Text
                    color={theme.colors.active}
                    size='md'
                    weight={theme.fontWeight}
                >
                    {thousandsDots(userData.depositLimit)} kr. pr. {subTitle}
                </Text>
            </Styled.ExpanderWrapper>
        )
    }, [userData?.depositLimitType, userData?.depositLimit])

    return (
        <Fragment>
            {renderDepositLimitSwitcher()}
            <ValidatingInput
                format={
                    Object.keys(userData.depositLimitType).length > 0
                        ? userData.depositLimitType.format
                        : depositLimitConfig.format
                }
                type='number'
                name='depositLimit'
                onCheckValue={checkValue}
                validate='required, numeric, minimum-kr, maximum-kr'
                onChangeHandler={(value) => {
                    setUserData({ ...userData, depositLimit: value })
                }}
                altLabel='kr.'
                allowFirstZero={false}
                maxNumber={depositLimitConfig.maxValue}
                minNumber={minimumDepositLimit}
                value={userData.depositLimit}
                bottomLabel={formattedBottomLabel(depositLimitConfig.maxValue)}
                styles={{ InputWrapper: { marginTop: '14px' } }}
                forwardedRef={(ref) => setDepositRef(ref)}
                autoComplete='new-depositLimit'
                role='presentation'
                onFocus={(elem, validVal) => {
                    setShowInfoBox(true)
                    onFocus(
                        elem,
                        (value) => {
                            setUserData({ ...userData, depositLimit: value })
                        },
                        validVal
                    )
                }}
                // autoFocus={!isTerminal}
                shouldRevalidateOnBlur={(relatedTarget) => {
                    return relatedTarget
                        ? !relatedTarget.hasAttribute('data-deposit-max-amount')
                        : true
                }}
                inputExpanderStates={['success']}
                inputExpanderContent={renderInputExpanderContent}
                validateOnChange
                theme={theme.name}
            />

            {/* if you need add lose limit change loseLimit on isTerminsl */}
            {loseLimit ? (
                <ValidatingInput
                    type='text'
                    title='lose limit'
                    name='loseLimit'
                    onCheckValue={checkValue}
                    validate='required, numeric'
                    autoComplete='new-lose-limit'
                    onFocus={(elem, validVal) => {
                        onFocus(
                            elem,
                            (value) => {
                                setUserData({
                                    ...userData,
                                    loseLimit: hasNumber.test(value)
                                        ? value
                                              .match(hasNumber)
                                              .join('')
                                              .slice(0, 4)
                                        : ''
                                })
                            },
                            validVal
                        )
                    }}
                    onChangeHandler={(value) => {
                        setUserData({
                            ...userData,
                            loseLimit: hasNumber.test(value)
                                ? value.match(hasNumber).join('').slice(0, 4)
                                : ''
                        })
                    }}
                    value={userData.loseLimit}
                    bottomLabel='???'
                    theme={theme.name}
                />
            ) : (
                ''
            )}

            {showInfoBox && (
                <Styled.InfoBox>
                    <InfoIcon />
                    <Text
                        color={theme.colors.basic}
                        lineHeight='21px'
                        style={{ fontSize: '14px' }}
                    >
                        Din grænse kan sænkes med øjebliklig virkning og kan
                        hæves med <b>24 timers forsinkelse</b>.
                    </Text>
                </Styled.InfoBox>
            )}

            <Styled.TextRulesWrapper
                valid={userData.rulesAccepted}
                linkFontSize='16px'
            >
                <Styled.RadioWrapper radioWidth='100%'>
                    <div style={{ marginRight: 0, alignItems: 'flex-start' }}>
                        <input
                            type='radio'
                            name='rules'
                            id='rules'
                            checked={userData.rulesAccepted}
                            onChange={() => {
                                setUserData({
                                    ...userData,
                                    rulesAccepted: !userData.rulesAccepted
                                })
                            }}
                        />
                        <label htmlFor='rules'>
                            <Text
                                size='x15'
                                color={theme.colors.basic}
                                style={{ margin: 0 }}
                                lineHeight={'21px'}
                            >
                                Jeg accepterer{' '}
                                <a
                                    target='_blank'
                                    href={LINKS.termsConds}
                                    rel='noreferrer'
                                >
                                    vilkår & betingelser
                                </a>
                                , samt{' '}
                                <a
                                    target='_blank'
                                    href={LINKS.dataPolicy}
                                    rel='noreferrer'
                                >
                                    regler for beskyttelse af personlige
                                    oplysninger
                                </a>
                                .
                            </Text>
                        </label>
                    </div>
                </Styled.RadioWrapper>
            </Styled.TextRulesWrapper>
            <Styled.NextButton
                active={!!isDataValid()}
                width='100%'
                padding='16px'
                margin='20px 0'
                disabled={!isDataValid()}
                yellow
                onClick={submit}
            >
                Næste
            </Styled.NextButton>
        </Fragment>
    )
}

export default Step3

export const convertTimeWithZone = (time) => {
    const d = new Date()

    let [timeHours, timeMins] = time.split(':')
    d.setUTCHours(timeHours, timeMins)

    return d.toLocaleTimeString('en-US', {
        hour12: false,
        hour: '2-digit',
        minute: '2-digit',
    })
}

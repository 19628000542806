import { detectIpad, detectMobile } from '../utils/deviceDetect'
import {
    apiEndPointGenarator,
    BASE_SITE_NAMES,
    getApiUrl,
    getBetApiUrl,
} from './env'

export const APP_NAME = 'rod25' //@TODO: made as default
export const PROVIDERS = {
    evolution: 'evolution',
    pragmatic: 'pragmatic',
}

const PRAGMATIC_CONNECTIONS = {
    dev: {
        server: 'prelive0-dga.pragmaticplaylive.net',
        casinoId: 'ppcdk00000012144',
        currency: 'DKK',
    },
    prod: {
        server: 'dga.pragmaticplaylive.net',
        casinoId: 'ppcsd00000006285',
        currency: 'DKK',
    },
}

export const PRAGMATIC_CONFIG = apiEndPointGenarator(
    PRAGMATIC_CONNECTIONS.dev,
    PRAGMATIC_CONNECTIONS.prod,
    PRAGMATIC_CONNECTIONS.prod
)

export const CMS_PATH = apiEndPointGenarator(
    `https://cms.${BASE_SITE_NAMES.dev}`,
    `https://cms.${BASE_SITE_NAMES.test}`,
    `https://cms.${BASE_SITE_NAMES.prod}`
)

export const IMG_PATH =
    CMS_PATH + '/upload/files/rod25/images/uploads/roed25/img/live-casino/'

export const getPlatformForApi = () =>
    !detectMobile() || detectIpad() ? 'web' : 'mobile'

export const API_URL = `${getApiUrl()}/v2/`
export const GAME_LAUNCH_API = `${getApiUrl()}/games/`
export const CONTENT_API_BASE_URL = `${getBetApiUrl()}`
export const CDN_IMG_PATH = apiEndPointGenarator(
    `https://cdn.${BASE_SITE_NAMES.dev}/casino/img/thumbs/`,
    `https://cdn.${BASE_SITE_NAMES.prod}/casino/img/thumbs/`,
    `https://cdn.${BASE_SITE_NAMES.prod}/casino/img/thumbs/`
)

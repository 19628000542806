import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import styled, { keyframes, css } from 'styled-components'

const Wrapper = styled.div`
    display: inline-block;
    color: ${(props) => (props.color ? props.color : 'inherit')};
    font-size: 12px;
    @media screen and (max-width: 700px) {
        font-size: 10px;
    }
`

const FadeAnimation = keyframes`
    0% {
      opacity: 1;
    }

    50% {
        opacity: 0;
    }
`

const Divider = styled.span`
    ${(props) =>
        props.animateDivider &&
        css`
            animation: ${FadeAnimation} 2s steps(1, start) infinite;
        `};
`

const DigitalDisplay = ({
    color = 'inherit',
    hours = '',
    minutes = '',
    seconds = '',
    animateDivider = true,
}) => {
    return (
        <Wrapper color={color}>
            {hours && hours}
            {minutes && (
                <Fragment>
                    <Divider animateDivider={animateDivider}>:</Divider>
                    {minutes}
                </Fragment>
            )}
            {seconds && (
                <Fragment>
                    <Divider animateDivider={animateDivider}>:</Divider>
                    {seconds}
                </Fragment>
            )}
        </Wrapper>
    )
}

DigitalDisplay.propTypes = {
    color: PropTypes.string,
    hours: PropTypes.string,
    minutes: PropTypes.string,
    seconds: PropTypes.string,
    animateDivider: PropTypes.bool,
}

export default DigitalDisplay

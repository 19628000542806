export const thousandsDots = (value) => {
    if (value === undefined || value === null) {
        return value
    }
    return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')
}

export const formatDenmarkCurrency = (value = 0, fractionDigits = 2) =>
    new Intl.NumberFormat('da-DK', {
        minimumFractionDigits: fractionDigits,
        maximumFractionDigits: fractionDigits
    }).format(value || 0)

export function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1)
}

export const hasNumber = /\d/gi
export const cutEmptyValues = (value) => value.replace(/\s/g, '')

import React, { useEffect, useState } from 'react'

/*
 * re-render component on safari back button
 */
const WithClearSafariPageCache = (Component) =>
    function ClearCacheComponent(props) {
        const [update, setUpdate] = useState(false)

        const eventHandler = (event) => {
            if (event.persisted) {
                setUpdate(true)
            }
        }

        useEffect(() => {
            setUpdate(false)
            window.addEventListener('pageshow', eventHandler)
            return () => {
                window.removeEventListener('pageshow', eventHandler)
            }
        }, [update])

        return <Component {...props} key={`update-component-key-${update}`} />
    }

export default WithClearSafariPageCache

import React, { useEffect, useRef, useState } from 'react'
import styled, { ThemeProvider } from 'styled-components'
import SmallMultiSwitcher from '../SmallMultiSwitcher'
import { themesMap } from '../../theme'
import ButtonArea from './Components/ButtonArea'
import TransactionList from './Components/TransactionList'
import moment from 'moment'
import PropTypes from 'prop-types'
import TransactionDetails from './Components/TransactionDetails'
import log from 'eslint-plugin-react/lib/util/log'

export const Wrapper = styled.div`
    position: relative;
    width: 100%;
    background: ${(props) => props.theme.colors.bg_200};
    padding: 0 24px 70px;
    flex: 1 1 0;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    box-sizing: border-box;
`

const MultiSwitchWrapper = styled.div`
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    width: 368px;
    top: 16px;
    z-index: 5;
`

const ActionList = [
    {
        name: 'all',
        title: 'Alle',
        groupKey: 2,
    },
    {
        name: 'gameHistory',
        title: 'Spil',
        groupKey: 1,
    },
    {
        name: 'transactionHistory',
        title: 'Ind-/udbetaling',
        groupKey: 0,
    },
]

/**
 * User Transaction History component
 * @param transactions {Array.<Object>}
 * @param currentTheme {string}
 * @param buttonsAreaPosition {string}
 * @param loading {boolean}
 * @param showMore {boolean}
 * @param showDetails {function()} callback
 * @param setTab {function()} callback
 * @param loadMoreHandler {function()} callback
 * @param loadMoreColor {string} bg HEX-color for load more button //need to be changed to theme
 * @param setInitialConfig {function()} callback
 * @param openChooseDate {function()} callback
 * @param resetDateHandler {function()} callback
 * @param currentDate {Object.<dateFrom, dateTo>}
 * @returns {JSX.Element}
 * @constructor
 */
const UserHistory = ({
    transactions,
    currentTheme = 'default',
    loading = true,
    showMore = false,
    showDetails = () => {},
    setTab = () => {},
    loadMoreHandler = () => {},
    loadMoreColor = '',
    setInitialConfig = () => {},
    openChooseDate = () => {},
    resetDateHandler = (defaultDate = { dateFrom: 0, dateTo: 0 }) => {},
    currentDate = {},
    buttonsAreaPosition = '',
}) => {
    const theme = themesMap[currentTheme]
    const transactionDataFormat = 'DDMMYYYY'
    const defaultFromDate = moment()
        .subtract(1, 'year')
        .format(transactionDataFormat)
    const defaultToDate = moment().format(transactionDataFormat)

    const [curTab, setCurTab] = useState(ActionList[0])
    const contentRef = useRef(null)
    const [fromDate, setFromDate] = useState(defaultFromDate)
    const [toDate, setToDate] = useState(defaultToDate)
    const [chosenDate, setChosenDate] = useState(false)

    const changeTab = (tabName) => {
        if (tabName !== curTab.name) {
            const selectedTab = ActionList.filter(
                (tab) => tab.name === tabName
            )[0]
            setTab(selectedTab)
            setCurTab(selectedTab)
        }
    }

    useEffect(() => {
        setInitialConfig({
            dateFrom: fromDate,
            dateTo: toDate,
            rowCount: 20,
        })
        setTab(curTab)
    }, [])

    useEffect(() => {
        if (currentDate.dateTo && currentDate.dateTo) {
            setFromDate(currentDate.dateFrom)
            setToDate(currentDate.dateTo)
            setChosenDate(
                currentDate.dateFrom !== defaultFromDate ||
                    currentDate.dateTo !== defaultToDate
            )
        }
    }, [currentDate])

    return (
        <ThemeProvider theme={theme}>
            <Wrapper background={theme.colors.bg}>
                <MultiSwitchWrapper>
                    <SmallMultiSwitcher
                        onChange={changeTab}
                        value={curTab.name}
                        optionList={ActionList}
                        color={theme.colors.black_font_900}
                        activeColor={theme.colors.black_font_900}
                        activeBgColor={theme.colors.stroke_400}
                        mainColor={theme.colors.stroke_400}
                    />
                </MultiSwitchWrapper>

                <TransactionList
                    ref={contentRef}
                    data={transactions}
                    showDetails={showDetails}
                    theme={theme}
                    loading={loading}
                    loadMoreHandler={loadMoreHandler}
                    showMore={showMore}
                    periodDate={chosenDate ? [fromDate, toDate] : []}
                    transactionDataFormat={transactionDataFormat}
                    loadMoreColor={loadMoreColor}
                />
            </Wrapper>
            <ButtonArea
                showClearButton={chosenDate}
                clearChosenDate={() => {
                    setFromDate(defaultFromDate)
                    setToDate(defaultToDate)
                    resetDateHandler({
                        dateFrom: defaultFromDate,
                        dateTo: defaultToDate,
                    })
                }}
                openChooseDate={() => {
                    openChooseDate()
                }}
                buttonsAreaPosition={buttonsAreaPosition}
            />
        </ThemeProvider>
    )
}

TransactionDetails.propTypes = {
    transactions: PropTypes.array.isRequired,
    currentTheme: PropTypes.string,
    buttonsAreaPosition: PropTypes.string,
    showMore: PropTypes.bool,
    showDetails: PropTypes.func,
    setTab: PropTypes.func,
    loadMoreHandler: PropTypes.func,
    loadMoreColor: PropTypes.string,
    setInitialConfig: PropTypes.func,
    openChooseDate: PropTypes.func,
    resetDateHandler: PropTypes.func,
    currentDate: PropTypes.shape({
        dateTo: PropTypes.number,
        dateFrom: PropTypes.number,
    }),
}

export default UserHistory

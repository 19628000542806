import React, { Fragment, useEffect, useState } from 'react'
import GameCard from './GameCard'
import styled, { css } from 'styled-components'
import { Text } from '@it25syv/25syv-ui'
import { UI_CONFIG } from '../configs/ui'
import { HighlightBox } from '../styled'
import PropTypes from 'prop-types'

const t = window.T

const MAX_NUMBER_OF_GAMES = 200

const GameHolder = styled.div`
    position: relative;
    padding: ${(props) => (props.itemPadding ? props.itemPadding : 'initial')};
    ${(props) =>
        props.thumb &&
        css`
            grid-column: ${(props) => (props.index % 2 ? '3/5' : '1/3')};
            grid-row: 1/3;
        `};

    &:hover {
        cursor: pointer;
    }
`

const ThumbWrapper = styled.div`
    display: inline-block;
    justify-content: center;
    transition: all 0.3s ease;
    width: ${({ width }) => (width ? width : 'auto')};
    position: relative;
    line-height: 0; //removing space between picture and border
`

const GameText = styled(Text)`
    text-align: center;
    position: relative;
    top: 5px;
    color: ${UI_CONFIG.textColor};
    margin: 0;
    line-height: 1.3;
    font-size: 16px;
`

const MinStake = styled(HighlightBox)`
    display: inline-block;
    background: rgba(0, 0, 0, 0.8);
    color: white;
    padding: 0 5px;
    font-size: 0.7rem;
    border-radius: 4px;
    position: absolute;
    bottom: 13px;
    right: 7px;
`

const objExample = {
    availableSeats: 3,
    seat1: false,
    seat2: false,
    seat3: true,
    seat4: true,
    seat5: true,
    seat6: true,
    seat7: false,
    resultSeat2: { time: 'Jan 10, 2017 10:09:17 AM', result: 'BLACKJACK' },
    resultSeat3: { time: 'Jan 10, 2017 10:09:17 AM', result: 'WIN' },
    resultSeat4: { time: 'Jan 10, 2017 10:09:17 AM', result: 'LOSE' },
    resultSeat5: { time: 'Jan 10, 2017 10:09:17 AM', result: 'BUST' },
    resultSeat6: { time: 'Jan 10, 2017 10:09:17 AM', result: 'PUSH' },
    tableId: '303',
    tableType: 'BLACKJACK',
    tableName: 'TableName',
    newTable: false,
    languageSpecificTableInfo: 'en',
    tableImage:
        'https://client.pragmaticplaylive.net/desktop/assets/snaps/sm9wsky7hh3ynx98/poster.jpg',
    tableLimits: { minBet: 5, maxBet: 100, maxPlayers: 99 },
    dealer: { name: 'Aga', id: '' },
    tableOpen: true,
}

const objExampleEvo = {
    id: 3148,
    name: 'Blackjack A',
    game_id: 'evo_bj_a',
    provider_id: '0',
    related_id: 'evo_bj_amobile',
    table_id: 'uwd2bl2khwcikjlz',
    group_id: 'blackjack',
    real_id: '',
    alt: 0,
    wide: true,
    platform: 'web',
    category: 'liveint',
    subcat: 'blackjack',
    feature: '',
    provider: 'evolution',
    subprovider: 'evolution',
    video: false,
    video_url: null,
    images: {
        thumb: null,
        svg: null,
        vertical: null,
        bg: null,
    },
    type: 'Live Casino',
    bonus: 'Nej',
    info: '',
    min_stake: '50',
    payback: '99.29%',
    bonusrunder: 'Nej',
    spinsrunder: 'Nej',
    tag_hot: false,
    tag_new: false,
    rod_hot: false,
    rod_new: false,
    features: [''],
    jackpot: 'Nej',
    jackpot_amount: '',
    jackpot_amount_formatted: null,
    priority: 999,
    rod_hide: false,
    exclusive: false,
    theme: 'bordspil',
    paylines: '',
    clicks: 0,
    release_date: '0000-00-00 00:00:00',
    liveCasinoPageCategories: ['all', 'blackJack'],
    liveCas: {
        name: 'Blackjack A',
        tableId: 'uwd2bl2khwcikjlz',
        gameSubType: null,
        gameType: 'Blackjack',
        language: 'en',
        open: true,
        players: 0,
        operationHours: 'FullTime',
        display: 'on_desktop',
        seats: 7,
        seatsTaken: [],
        workHours: {
            type: 'FullTime',
        },
        thumbs: {
            links: {
                S: '/tablePicture/bj_gen7_med?size=S',
                M: '/tablePicture/bj_gen7_med?size=M',
                L: '/tablePicture/bj_gen7_med?size=L',
                XL: '/tablePicture/bj_gen7_med?size=XL',
            },
            thumbnails: {
                S: 'https://lob.egcvi.com/thumbnail/bj_gen7_med_S.jpg',
                M: 'https://lob.egcvi.com/thumbnail/bj_gen7_med_M.jpg',
                L: 'https://lob.egcvi.com/thumbnail/bj_gen7_med_L.jpg',
                XL: 'https://lob.egcvi.com/thumbnail/bj_gen7_med_XL.jpg',
            },
        },
        betLimits: {
            symbol: 'kr.',
            min: 50,
            max: 25000,
        },
        results: null,
        history: null,
        privateTableConfig: null,
    },
}

const GameList = ({
    games = [],
    limit = MAX_NUMBER_OF_GAMES,
    itemPadding = 'initial',
    lazyOverflow = false,
    gameClickHandler,
}) => {
    const [gamesForRender, setGamesForRender] = useState([])
    const [keyUpdate, setKeyUpdate] = useState(0)

    useEffect(() => {
        setGamesForRender(games)
    }, [games])

    return (
        <Fragment key={keyUpdate}>
            {gamesForRender.length > 0 &&
                gamesForRender.map((game, index) => {
                    return (
                        index < limit && (
                            <GameHolder
                                key={'gamesList-' + index}
                                itemPadding={itemPadding}
                                index={index}
                            >
                                <ThumbWrapper width={'100%'}>
                                    <GameCard
                                        key={game.id}
                                        game={game}
                                        gameClickHandler={gameClickHandler}
                                        lazyOverflow={lazyOverflow}
                                    />
                                </ThumbWrapper>
                                <GameText>{game.name}</GameText>
                            </GameHolder>
                        )
                    )
                })}
        </Fragment>
    )
}

GameList.propTypes = {
    games: PropTypes.arrayOf(PropTypes.object).isRequired,
    limit: PropTypes.number,
    lazyOverflow: PropTypes.bool,
    itemPadding: PropTypes.string,
    gameClickHandler: PropTypes.func,
}

export default GameList

import React, {Fragment} from 'react'

export default () => (
    <Fragment>
        <p className="intercom-align-left">
            <b>
                Ansvarligt Spil Politik Hos Bet25 (Bet25 Sport, Bet25 Casino, Bet25 Heste og CasinoGo)
            </b>
        </p>

        <p className="intercom-align-left">
            <i>Senest opdateret den 31. oktober 2023</i>
        </p>
        <p className="intercom-align-left">
            Hos Bet25 er vi meget bevidste om, at spil om penge indebærer en risiko for spilafhængighed. Derfor har vi
            lavet en politik for ansvarligt spil, som gælder på tværs af vores produkter (Bet25 Sport, Bet25 Casino,
            Bet25 Heste og CasinoGo), og som har til formål at forebygge og bekæmpe ludomani.&nbsp;
        </p>
        <p className="intercom-align-left">
            På denne side kan du læse mere om, hvordan du spiller ansvarligt og få information om, hvordan du kan teste
            dine spilvaner, links til behandlingstilbud og andre ting som er med til at sikre, at spil forbliver
            underholdning og ikke udvikler sig til afhængighed.&nbsp;
        </p>
        <p className="intercom-align-left">
            <b>1.1 Grundlæggende principper for ansvarligt spil</b>
            <br/>
            Når du spiller om penge, opfordrer vi til, at du følger en række grundlæggende principper for ansvarligt
            spil:&nbsp;
        </p>
        <ul>
            <li>Husk, at spil er en hobby og ikke en indtægtskilde.&nbsp;</li>
            <li>
                Forsøm ikke dine sociale omgivelser og tag råd og negative reaktioner på dit spil fra din familie,
                venner og arbejdskollegaer meget alvorligt.&nbsp;
            </li>
            <li>
                Spil aldrig for mere, end du er villig og har råd til at tabe.&nbsp;
            </li>
            <li>
                Spil aldrig under indtagelse af alkohol eller medicin.&nbsp;
            </li>
            <li>
                Optag ikke et lån for at spille, og spil aldrig for penge du skal bruge til faste udgifter.&nbsp;
            </li>
        </ul>
        <p className="intercom-align-left">
            <b>1.2 Aldersgrænse </b>
            <br/>
            Det er ikke lovligt at deltage i spil her på siden, hvis du er under 18 år.&nbsp;
        </p>
        <p className="intercom-align-left">
            <b>1.3 Selvudelukkelse</b>
            <br/>
            Du kan til enhver tid vælge at udelukke dig selv fra at spille et spil på hjemmesiden ved at følge
            nedenstående anvisninger. Din udelukkelse kan være enten permanent eller midlertidig, og i udelukkesperioden
            kan du hverken åbne eller benytte din konto.&nbsp;
        </p>
        <p className="intercom-align-left">
            Ved en permanent udelukkelse lukkes din konto, og din tilmelding ophører. Du kan først tilmelde dig som
            kunde igen, når din konto har været lukket i ét år.&nbsp;
        </p>
        <p className="intercom-align-left">
            Ved en midlertidig udelukkelse kan du vælge at udelukke dig selv fra at spille direkte på siden. Her kan du
            spærre din konto i en af følgende, forudbestemte perioder: 24 timer, 1 uge, 1 måned eller permanent. &nbsp;
        </p>
        <p className="intercom-align-left">
            Du kan udelukke dig selv fra spil direkte her på siden, når du er logget ind. Under dine kontoindstillinger
            i øverste højre hjørne, skal du blot klikke på ”Selvudelukkelse” og vælge i hvilket omfang, du ønkser
            udelukkelse fra spil. Du kan i øvrigt kontakte vores kundesupport for at vælge en anden
            udelukkelsesperiode.&nbsp;
        </p>
        <p className="intercom-align-left">
            <b>1.4 ROFUS &nbsp; </b>
            <br/>
            Ved midlertidig eller permanent udelukkelse får du endvidere mulighed for at blive registreret i
            Spillemyndighedens register, hvilket betyder, at du blokeres og udelukkes fra alle hjemmesider udbudt af
            autoriserede operatører i Danmark (herunder alle Bet25s varemærker) i den relevante udelukkelsesperiode.
            Yderligere oplysninger om det nationale register får du ved at følge dette link::{' '}
            <a
                href="https://www.stopspillet.dk/"
                rel="nofollow noopener noreferrer"
                target="_blank"
            >
                https://www.stopspillet.dk
            </a>{' '}
            &nbsp;
        </p>
        <p className="intercom-align-left">
            <b>
                1.5 StopSpillet: Få råd og vejledning, hvis du har mistanke om spilafhængighed
            </b>
            <br/>
            StopSpillet er Spillemyndighedens hjælpelinje om spilafhængighed. StopSpillet er for alle, der har brug for
            at tale om spilafhængighed. Det kan være personer, som selv har problemer med spil eller pårørende til en
            person, der har problemer med spil. Du kan læse mere her:{' '}
            <a
                href="https://www.stopspillet.dk/"
                rel="nofollow noopener noreferrer"
                target="_blank"
            >
                https://www.stopspillet.dk
            </a>
            .&nbsp;
        </p>
        <p className="intercom-align-left">
            StopSpillet kan kontaktes på telefonnummer <a href="tel:+4570222825">+ 45 70 22 28 25</a>.&nbsp;
        </p>
        <p className="intercom-align-left">Typiske tegn på spillafhængighed:</p>
        <ul>
            <li>Du spiller for mere, end du har planlagt.</li>
            <li>Du har økonomiske problemer som følge af dit pengespil.</li>
            <li>
                Du oplever problemer i forhold til andre mennesker pga. pengespil.
            </li>
            <li>Dit pengespil går ud over din uddannelse eller dit arbejde.</li>
            <li>Dit humør påvirkes af pengespillet.&nbsp;</li>
            <li>
                Du skammer dig over måden, du spiller på, og det der sker, når du spiller.
            </li>
            <li>
                Du tænker meget på pengespil, og på hvordan du skal skaffe penge til at spille for.
            </li>
            <li>
                Du låner penge for at spille eller for at tilbagebetale spillegæld.
            </li>
            <li>Du spiller for at vinde eventuelle tab tilbage.</li>
            <li>
                Du begår kriminalitet for at skaffe penge til spil eller spillegæld.
            </li>
            <li>
                Dine omgivelser har bemærket du spiller meget og kommenteret dit spilforbrug.
            </li>
        </ul>
        <p className="intercom-align-left">
            <b>1.6 Hold øje med dit tidsforbrug </b>
            <br/>
            Via et lille ur øverst på siden vil du altid kunne holde øje med, hvor længe du har spillet i den pågældende
            session, når du er logget ind på vores hjemmesider eller apps.    &nbsp;&nbsp;
        </p>
        <p className="intercom-align-left">
            <b>1.7 Indbetalingsgrænse </b>
            <br/>
            Det er et lovkrav, at du fastsætter en grænse for, hvor meget der kan indbetales på din spillekonto.
            Efterfølgende har du mulighed for at ændre denne grænse for dine indbetalinger i form af en øvre grænse for
            daglige, ugentlige eller månedlige indbetalinger. Dette kan du ændre under dine kontoindstillinger i øverste
            højre hjørne, når du er logget ind.  &nbsp;
        </p>
        <p className="intercom-align-left">
            En anmodning om at forhøje den eksisterende indbetalingsgrænse vil først træde i kraft 24 timer efter
            anmodningen. Omvendt vil en anmodning om at sænke en indbetalingsgrænse træde i kraft med det samme. Den
            daglige, ugentlige eller månedlige periode vil træde i kraft og være gældende fra det minut, du har bestemt
            dig for den nye indbetalingsgrænse.
            &nbsp;&nbsp;
        </p>
        <p className="intercom-align-left">
            <b>1.8 Markedsføring</b>
            <br/>
            Bet25 følger de generelle regler for god og fair markedsføring i henhold til den danske Markedsføringslov.
            Du kan altid frabede dig at blive kontaktet af os i markedsføringsøjemed, selvom du oprindeligt har takket
            ”ja” til at modtage direkte markedsføring.
        </p>
        <p className="intercom-align-left">
            <b>1.9 Behandlingstilbud for ludomani</b>
            <br/>
            Ved mistankte om ludomani kan du henvende dig til ”Center for ludomani” på telefon: {' '} <a
            href="tel:+70111810">+70 11 18 10</a>{' '} eller e-mail på
            <a
                href="mailto:info@ludomani.dk"
                rel="nofollow noopener noreferrer"
                target="_blank"
            >
                {' '}
                info@ludomani.dk
            </a>{' '}
            eller læse mere på{' '}
            <a
                href="http://www.ludomani.dk/"
                rel="nofollow noopener noreferrer"
                target="_blank"
            >
                www.ludomani.dk
            </a>
            , hvor du også kan teste dig selv for ludomani. Desuden henviser vi til følgende behandlingscentre i
            Danmark: &nbsp;
        </p>
        <p className="intercom-align-left">
            · <a href="https://ludomani.dk/center-for-ludomani-i-kobenhavn/" target={'_blank'}>Center for ludomani
            København</a> {' '} - Nørregade 28 D, st., 1165 København K.
        </p>
        <p className="intercom-align-left">
            · <a href="https://ludomani.dk/center-for-ludomani-i-odense/" target={'_blank'}>Center for ludomani
            Odense</a> {' '} - Østergade 42, 5000 Odense C
        </p>
        <p className="intercom-align-left">
            · <a href="https://ludomani.dk/center-for-ludomani-i-aarhus/" target={'_blank'}> Center for ludomani
            Århus</a>{' '} - Gammel Munkegade 6D, 8000 Århus C
        </p>
        <p className="intercom-align-left">
            · <a href="https://www.spillemyndigheden.dk/behandlingssted/center-ludomani-esbjerg" target={'_blank'}>Center
            for Ludomani Esbjerg</a>{' '} - Huset Havnegade 62, 6700 Esbjerg
        </p>
        <p className="intercom-align-left">
            Du kan finde en opdateret liste over samtlige behandlingscentre i Danmark på Spillemyndighedens
            hjemmeside:{' '}
            <a
                href="https://www.spillemyndigheden.dk/behandlingssteder"
                rel="nofollow noopener noreferrer"
                target="_blank"
            >
                https://www.spillemyndigheden.dk/behandlingssteder
            </a>
        </p>
        <p className="intercom-align-left">
            <b>
                1.10 Interne procedurer for forebyggelse og bekæmpelse af ludomani  &nbsp;
            </b>
            <br/>
            Bet25s medarbejdere er trænet i at finde risikotegn på uansvarligt spil blandt vores kunder. Vi foretager
            løbende analyser af alle kunders spil og spilmønstre for at identificere kunder med mulige spilproblemer
            eller begyndende problemer. &nbsp;
        </p>
        <p className="intercom-align-left">
            Vi vil tage kontakt til kunder, som vi mener, udviser tegn på ludomani eller begyndende ludomani. I nogle
            tilfælde vil vi også sænke indbetalingsgrænser eller helt lukke konti.
        </p>
        <p className="intercom-align-left">
            <b>1.11 Blokering af hjemmesider der udbyder spil om penge </b>
            <br/>
            Det er muligt for alle kunder at blokere for hjemmesider, som tilbyder onlinespil for penge ved at
            installere et eller flere af disse programmer fra blandt andet hjemmesider som:
        </p>
        <p className="intercom-align-left">
            · &nbsp; &nbsp; &nbsp; &nbsp;
            <a
                href="https://www.netnanny.com/"
                rel="nofollow noopener noreferrer"
                target="_blank"
            >
                www.netnanny.com
            </a>
            <br/>· &nbsp; &nbsp; &nbsp; &nbsp;
            <a
                href="https://www.gamblock.com/"
                rel="nofollow noopener noreferrer"
                target="_blank"
            >
                www.gamblock.com
            </a>
            <br/>· &nbsp; &nbsp; &nbsp; &nbsp;
            <a
                href="http://www.betfilter.com/"
                rel="nofollow noopener noreferrer"
                target="_blank"
            >
                www.betfilter.com
            </a>
        </p>
        <p className="intercom-align-left">
            <b>1.12 Én spillekonto pr. spiller</b>
            <br/>
            En kontoindehaver vælger et personligt brugernavn, og kontoindehaveren vælger selv password. Det er ikke
            muligt at ændre brugernavn. En indehaver af en midlertidig konto kan logge ind på hjemmesiden med sit
            brugernavn og password. En indehaver af en permanent konto skal logge ind på hjemmesiden ved brug af sit
            personlige MitID.
            &nbsp;
        </p>
        <p className="intercom-align-left">
            Det er kontoindehaverens eget ansvar ikke at dele nogle af disse oplysninger og holde dem hemmelige for alle
            andre end kontoindehaveren selv. Vælg et brugernavn og password, som er svært at gætte sig til, og som
            indeholder både små og store bogstaver samt tal. Gem aldrig dine oplysninger på en måde, så andre kan finde
            dem eller gætte sig til dine login-oplysninger. Hvis kontoindehaveren har grund til at tro, at andre har
            fået kendskab til disse oplysninger, bør kontoindehaveren omgående kontakte Bet25s kundeservice. Hvis en
            kontoindehaver mister, deler eller videregiver sit konto-login, skal Bet25 ikke være ansvarlig for og vil
            ikke blive gjort ansvarlig for krav, der vedrører den pågældende konto.
        </p>
        <p className="intercom-align-left">
            For at sikre dig, at din konto ikke har været brugt af andre, kan du, hver gang du logger ind, kontrollere
            følgende:
        </p>
        <p className="intercom-align-left">
            · &nbsp; At ”Forrige login” under din konto passer med hvornår du sidst var på
        </p>
        <p className="intercom-align-left">
            · &nbsp; At din balance stemmer med din forventning
        </p>
        <p className="intercom-align-left">
            · &nbsp;At dine spil stemmer overens med din forventning
        </p>
        <p className="intercom-align-left">
            Hvis du har mistanke om at andre har benyttet din konto skal du straks henvende dig til kundesupport.
        </p>
        <p className="intercom-align-left">
            <b>1.13 Regler og vilkår.</b>
            <br/>
            Alle vores regler og vilkår stemmer i overens med den danske lovgivning på området og kan læses lige
            her:{' '}
            <br/>
            <p className="intercom-align-left">
                · &nbsp; <b>Bet25 Sport</b>
                <a
                    href="https://intercom.help/25syv/da/articles/3493559-vilkar-og-betingelser"
                    rel="nofollow noopener noreferrer"
                    target="_blank"
                >
                    {' '} - LINK
                </a>
            </p>
            <p className="intercom-align-left">
                · &nbsp; <b>Bet25 Casino</b>
                <a
                    href="https://intercom.help/25syv/da/articles/3493598-vilkar-og-betingelser"
                    rel="nofollow noopener noreferrer"
                    target="_blank"
                >
                    {' '} - LINK
                </a>
            </p>
            <p className="intercom-align-left">
                · &nbsp; <b>Bet25 Heste</b>
                <a
                    href="https://intercom.help/25syv/da/articles/3493016-vilkar-og-betingelser"
                    rel="nofollow noopener noreferrer"
                    target="_blank"
                >
                    {' '} - LINK
                </a>
            </p>
            <p className="intercom-align-left">
                · &nbsp; <b>CasinoGo</b>
                <a
                    href="https://intercom.help/casinogo/da/articles/4660397-vilkar-og-betingelser"
                    rel="nofollow noopener noreferrer"
                    target="_blank"
                >
                    {' '} - LINK
                </a>
            </p>

            <br/>
        </p>
    </Fragment>
)

import React, { useState } from 'react'
import { StyledModalTopbar, Title, Wrapper } from '../LoginDesktop/styled'
import { ConfirmButton, ContentWrapper, Description } from './styled'
import CPRInput from './CPRInput'
import Spinner from '../Spinner'
import PropTypes from 'prop-types'
import { dark, defaultTheme } from '../../utils/loginThemes'
import { getApiUrl } from '../../config/api'

const themesMap = {
    default: defaultTheme,
    dark,
}
const CPRConfirmation = ({
    onConfirm,
    onError,
    idp = '',
    theme = 'default',
    oldAPI = false,
    site = '',
}) => {
    const [cpr, setCpr] = useState('')
    const [isValid, setIsValid] = useState(false)
    const [loading, setLoading] = useState(false)

    const currentTheme = themesMap[theme]

    const idpText = {
        mitid: 'MitID',
        nemid: 'NemID',
    }

    const confirm = () => {
        if (!loading) {
            setLoading(true)
            fetch(
                oldAPI
                    ? '/rest/punter/mitidConfirmLogin'
                    : `${getApiUrl()}/punter/mitidConfirmLogin`,
                {
                    method: 'POST',
                    headers: { 'content-type': 'application/json' },
                    body: JSON.stringify({ cpr, idp, site }),
                    credentials: 'include',
                }
            )
                .then((res) => {
                    console.log(res, 'FIRST RESULT')
                    return res.json()
                })
                .then((result) => {
                    console.log(result, 'Second RESULT')
                    setLoading(false)
                    if (result.success) {
                        onConfirm()
                    } else {
                        onError(result.errorMessage || 'Something went wrong')
                    }
                })
                .catch((e) => {
                    setLoading(false)
                    onError(e)
                    console.log(e, 'ERRROR')
                })
        }
    }
    return (
        <Wrapper backgroundColor={currentTheme.colors.bg}>
            <StyledModalTopbar color={currentTheme.colors.borderColor}>
                <Title color={currentTheme.colors.basic}>
                    Bekræft dit CPR-nummer
                </Title>
            </StyledModalTopbar>
            <ContentWrapper>
                <Description
                    color={currentTheme.colors.basic}
                    condensed={currentTheme.condensed}
                >
                    Da det er første gang at du logger ind med{' '}
                    {idpText[idp] || ''}, har vi behov for at bekræfte dit
                    CPR-nummer.
                </Description>
                <CPRInput
                    onChangeHandler={(value) => setCpr(value)}
                    onCheckValue={(isValid) => setIsValid(isValid)}
                    type="number"
                    title="CPR-nummer"
                    validate="required, numeric, min, max"
                    value={cpr}
                    name="cpr"
                    min="10"
                    max="10"
                    bottomLabel="Dit CPR-nummer sikrer os, at du er over 18 år."
                    format="###### - ####"
                    placeholder="DDMMÅÅ - XXXX"
                    allowEmptyFormatting
                    mask={['D', 'D', 'M', 'M', 'Å', 'Å', 'X', 'X', 'X', 'X']}
                    textColor={currentTheme.colors.primary}
                    inputColor={currentTheme.colors.bgDarker}
                    focusColor={currentTheme.colors.primaryAction}
                    condensed={currentTheme.condensed}
                />
                <ConfirmButton
                    size={'mdB'}
                    active
                    bold
                    onClick={confirm}
                    disabled={!cpr || !isValid}
                    activeColor={currentTheme.colors.activeBtnColor}
                    disableColor={currentTheme.colors.disabledBtnColor}
                    color={currentTheme.colors.basic}
                    condensed={currentTheme.condensed}
                >
                    {loading ? <Spinner size={'md'} /> : 'Bekræft'}
                </ConfirmButton>
            </ContentWrapper>
        </Wrapper>
    )
}

CPRConfirmation.propTypes = {
    onConfirm: PropTypes.func.isRequired,
    onError: PropTypes.func,
    oldAPI: PropTypes.bool,
}

export default CPRConfirmation

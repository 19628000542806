import styled, { css } from 'styled-components'
import { detectMobile } from '../../../utils/deviceDetect'

export const Wrapper = styled.div`
    background: white;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    max-width: 400px;
    width: 80%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    ${detectMobile() &&
        css`
            max-width: 100%;
            width: 100%;
            position: absolute;
            bottom: 0;
            left: 0;
            top: auto;
            transform: none;
            border-bottom-left-radius: 0;
            border-bottom-right-radius: 0;
        `}
`

export const Headline = styled.div`
    text-align: center;
    font-size: 18px;
    font-weight: 700;
    border-bottom: 1px solid #dfe5ed;
    padding: 16px;
`
export const Details = styled.div`
    padding: 0 24px;
`
export const Item = styled.div`
    padding: 20px 24px;
    display: flex;
    justify-content: space-between;
    ${({ last }) => !last && 'border-bottom: 1px solid #dfe5ed'};
    font-size: 14px;
`
export const Name = styled.div`
    font-weight: 700;
`
export const Summary = styled.div``
export const ActionWrapper = styled.div`
    border-top: 1px solid #dfe5ed;
    padding: 24px 16px;
`
export const Button = styled.button`
    padding: 12px;
    background: #f5f8fc;
    border: 1px solid #dfe5ed;
    border-radius: 8px;
    font-weight: 700;
    font-size: 13px;
    width: 100%;
    cursor: pointer;
    color: ${(props) => props.theme.colors.black_font_900};
`
export const ButtonsArea = styled.div`
    width: 100%;
    flex-direction: row;
    bottom: 0;
    align-items: center;
    justify-content: center;
    margin: 0;
    box-sizing: border-box;
    background: ${(props) => props.theme.colors.bg};
    display: flex;
    gap: 16px;
    padding: ${detectMobile() ? '16px ' : '16px 40px'};
    border-top: 1px solid ${(props) => props.theme.colors.stroke_400};
    position: ${({ position }) => position || 'absolute'};
    border-radius: ${({ borderRadius }) => borderRadius || '0'};
    z-index: ${({ zIndex }) =>  zIndex || 2};
`

export const StyledButton = styled.button`
    display: flex;
    position: relative;
    height: 44px;
    width: 100%;
    max-width: ${(props) => (props.maxWidth ? props.maxWidth : '100%')};
    border-radius: 8px;
    outline: none;
    border: none;
    font-size: 13px;
    font-weight: bold;
    font-family: ${(props) => props.theme.fontFamily};
    transition: all 0.3s ease;
    color: ${(props) =>
        props.color ? props.color : props.theme.colors.black_font_900};
    background: ${(props) =>
        props.bgColor ? props.bgColor : props.theme.colors.activeBtnColor};
    border: ${(props) =>
        props.borderColor
            ? '1px solid ' + props.borderColor
            : '1px solid transparent'};
    cursor: pointer;
    justify-content: center;
    align-items: center;
    gap: 8px;
    ${(props) =>
        props.disabled &&
        css`
            opacity: 0.4;
            cursor: not-allowed;
        `};
`

export const IconWrapper = styled.div`
    position: absolute;
    right: 15px;
    top: 15px;
    cursor: pointer;
`

import styled, { css } from 'styled-components'
import { marginProps } from '../utils/spacing.js'
import PropTypes from 'prop-types'

const sizes = {
    xs: 0.67,
    sm: 0.83,
    md: 1,
    lg: 1.125,
    xl: 1.75,
    xxl: 2.25,
}

/**
 *
 * @param size {string}
 * @param scale {int} Use this number to increase default dimensions
 * @return {string}
 */
const getSize = ({ size, scale = 1 }) => {
    let dim = scale * sizes[size]

    return `
        width: ${dim}rem;
        height: ${dim}rem;
    `
}

const Area = styled.span`
    padding: 10px;
    cursor: pointer;
`

const Icon = styled.svg`
    ${marginProps};
    vertical-align: middle;
    ${(props) => props.size && getSize(props)};
    -webkit-mask: url(${(props) => (props.src ? props.src : '')}) center center;
    -webkit-mask-repeat: no-repeat;
    -webkit-mask-size: contain;
    ${(props) =>
        !props.size &&
        css`
            width: ${props.width};
            height: ${props.height};
        `};
    background: ${(props) => (props.color ? props.color : 'black')};
    transform: ${({ rotate }) => (rotate ? `rotate(${rotate}deg)` : ``)};
    cursor: ${({ clickable }) => (clickable ? `pointer` : `inherit`)};
`

Icon.propTypes = {
    src: PropTypes.string.isRequired,
    size: PropTypes.string,
    color: PropTypes.string,
    width: PropTypes.string,
    height: PropTypes.string,
}

export default Icon

export { Area }

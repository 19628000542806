import styled, { css } from 'styled-components'
import { marginProps } from '../utils/spacing.js'
import PropTypes from 'prop-types'

const Img = styled.img.attrs((props) => ({
    src: props.src,
    alt: props.alt ? props.alt : props.src,
}))`
    ${marginProps};
    width: ${(props) => (props.width ? props.width : 'auto')};
    height: ${(props) => (props.height ? props.height : 'auto')};
`

Img.propTypes = {
    src: PropTypes.string.isRequired,
    width: PropTypes.string,
    height: PropTypes.string,
}

Img.defaultProps = {}

export default Img

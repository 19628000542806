import styled from 'styled-components'

export const Wrapper = styled.div`
    width: 100%;
`

export const DescriptionWrapper = styled.div`
    border-radius: 10px;
    border: 1px solid #377fe919;
    background: #e4effd;
    padding: 16px;
    margin: 0;

    display: flex;
    justify-content: start;
    align-items: center;
`
export const DescriptionText = styled.p`
    font-size: 16px;
    color: #333333;
    margin: 0 0 0 17px;
    padding: 0;
`

export const BonusWrapper = styled.div`
    border-radius: 10px;
    border: 1px solid #edf1f8;
    background: #ffffff;
    padding: 16px;

    .tooltip {
        width: 250px;
        opacity: 1 !important;
        font-size: 14px;
        color: #505050;
        background: #ffffff 0% 0% no-repeat padding-box;
        box-shadow: 0px 3px 6px #00000029;
        padding: 16px;
        box-sizing: content-box;
        border-radius: 8px;

        p {
            color: #505050;
            font-size: 14px;
        }
    }
`

export const QuestionMarkIcon = styled.span`
    display: block;
    width: 18px;
    height: 18px;
    background: #edf1f8;
    border-radius: 50%;
    color: #000;
    font-size: 12px;
    line-height: 18px;
    text-align: center;
    font-weight: bold;
    cursor: pointer;
`

export const StatusBarWrapper = styled.div`
    width: 100%;
`

export const StatusBarLine = styled.div`
    width: 100%;
    height: 10px;
    background: #edf1f8;
    border-radius: 8px;
`

export const StatusBarSlider = styled.div`
    width: ${(props) => (props.status ? props.status + '%' : '0')};
    height: 100%;
    overflow: hidden;
    background: #65c466;
    border-radius: 8px;
`

export const StatusBarValueWrapper = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin: 5px 0 0;
`

export const ButtonWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    gap: 8px;
    margin: 15px 0 0;
`
export const StatusBarValue = styled.div`
    color: #333333;
    font-size: 12px;
`

export const BonusButton = styled.button`
    width: 100%;
    display: block;
    height: 46px;
    border-radius: 8px;
    outline: none;
    border: none;
    font-size: 16px;
    transition: all 0.3s ease;
    cursor: pointer;
    text-align: center;
`

export const ButtonGoTo = styled(BonusButton)`
    background: #edf1f8;
    color: #333333;

    &:hover {
        background: #e4e9f2;
    }
`

export const ButtonDelete = styled(BonusButton)`
    width: 54px;
    vertical-align: middle;
    background: none;
    margin: 0 0 0 8px;

    &:hover {
        background: #fbe4e4;
    }
`

import React from 'react'

const ArrowLogo = (props) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={props.width || '10'}
      height={props.height || '10'}
      viewBox='0 0 10 10'
    >
      <defs>
        <clipPath id='clip-path'>
          <rect
            id='Rectangle_3547'
            data-name='Rectangle 3547'
            width='5.044'
            height='8'
            fill='#a9c7e6'
          />
        </clipPath>
      </defs>
      <g id='Group_3566' data-name='Group 3566' transform='translate(2.198 1)'>
        <rect
          id='Rectangle_3548'
          data-name='Rectangle 3548'
          width='10'
          height='10'
          transform='translate(-2.198 -1)'
          fill='none'
        />
        <g
          id='Group_3553'
          data-name='Group 3553'
          transform='translate(0.302 0)'
        >
          <path
            id='Path_9028'
            data-name='Path 9028'
            d='M4.739,3.261,1.783.306A1.045,1.045,0,0,0,.306,1.783L2.522,4,.306,6.215A1.045,1.045,0,0,0,1.783,7.694L4.739,4.738a1.046,1.046,0,0,0,0-1.477'
            transform='translate(0 0)'
            fill={props.color || '#fff'}
          />
        </g>
      </g>
    </svg>
  )
}

export default ArrowLogo

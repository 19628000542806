import React, { Fragment, useEffect, useState } from 'react'
import {
    FakePlaceholder,
    ForgetPassword,
    InputField,
    InputsWrapper,
    InputWrapper,
    LoginButton,
    LoginButtonWrapper,
    LoginLabel,
} from '../Login/styled'
import Spinner from '../Spinner'
import PropTypes from 'prop-types'

let validators = {}

const UsernameLogin = ({
    currentTheme,
    loginHandler = () => {},
    forgotPassHandler = () => {},
    forgotPassword = () => {},
    showResetPassword = false,
}) => {
    const [userName, setUsername] = useState('')
    const [password, setPassword] = useState('')
    const [loading, setLoading] = useState(false)

    const setValidationHandler = (fieldName, validator) => {
        validators[fieldName] = validator
    }
    const isFormValid = () => userName && password
    const keyPressHandler = (e) => {
        const key = e.charCode || e.keyCode || 0

        if (key === 13) {
            e.preventDefault()
            loginAction()
        }
    }

    const loginAction = () => {
        const isValidUsername = validators.userName()
        const isValidPassword = validators.password()

        if (isValidUsername && isValidPassword) {
            loginHandler(
                userName,
                password,
                (state) => {
                    setLoading(state)
                },
                () => {
                    //success action
                },
                (e) => {
                    //error action
                    //for backward compatibility, should be removed in the next versions
                    if (e && e.data === true) {
                        return e.errorMessage
                    } else {
                        console.error('Error during login: ' + e)
                    }
                }
            )
        } else {
            loginHandler('', '')
        }
    }

    const forgotPassAction = () => {
        forgotPassHandler()
    }

    const renderInputs = () => {
        return (
            <InputsWrapper onKeyDown={keyPressHandler}>
                <InputWrapper>
                    <FakePlaceholder
                        condensed={currentTheme.condensed}
                        color={currentTheme.colors.basic}
                    >
                        Brugernavn eller email
                    </FakePlaceholder>
                    <InputField
                        redBorder
                        value={userName}
                        onChange={(value) => setUsername(value)}
                        validate={{ required: true }}
                        getValidator={(validationHandler) =>
                            setValidationHandler('userName', validationHandler)
                        }
                        showErrorUnderInput
                        bgColor={currentTheme.colors.bgDarker}
                        color={currentTheme.colors.primary}
                        condensed={currentTheme.condensed}
                    />
                </InputWrapper>
                <InputWrapper>
                    <FakePlaceholder
                        condensed={currentTheme.condensed}
                        color={currentTheme.colors.basic}
                    >
                        Kodeord
                    </FakePlaceholder>
                    {/*todo legacy part, should be removed when every product reach version number 7.13.11 or higher */}
                    {!showResetPassword && (
                        <ForgetPassword
                            onClick={forgotPassword}
                            color={currentTheme.colors.link}
                            condensed={currentTheme.condensed}
                        >
                            Glemt dit kodeord?
                        </ForgetPassword>
                    )}
                    <InputField
                        redBorder
                        type="password"
                        value={password}
                        onChange={(value) => setPassword(value)}
                        validate={{ required: true }}
                        getValidator={(validationHandler) =>
                            setValidationHandler('password', validationHandler)
                        }
                        showErrorUnderInput
                        bgColor={currentTheme.colors.bgDarker}
                        color={currentTheme.colors.primary}
                        condensed={currentTheme.condensed}
                    />
                </InputWrapper>
            </InputsWrapper>
        )
    }

    return (
        <Fragment>
            {renderInputs()}
            <LoginButtonWrapper>
                <LoginButton
                    onClick={loginAction}
                    disabled={!isFormValid()}
                    activeColor={currentTheme.colors.activeLoginColor}
                    disableColor={currentTheme.colors.disabledLoginColor}
                    color={currentTheme.colors.basic}
                >
                    {loading ? (
                        <Spinner color={'#9198AA'} size={'md'} />
                    ) : (
                        <LoginLabel
                            condensed={currentTheme.condensed}
                            normal={!currentTheme.bold}
                        >
                            Log ind
                        </LoginLabel>
                    )}
                </LoginButton>
                {showResetPassword ? (
                    <LoginButton
                        onClick={forgotPassAction}
                        activeColor={currentTheme.colors.bg}
                        disableColor={currentTheme.colors.disabledLoginColor}
                        color={currentTheme.colors.basic}
                        borderColor={currentTheme.colors.borderColor}
                    >
                        <LoginLabel
                            condensed={currentTheme.condensed}
                            normal={!currentTheme.bold}
                        >
                            Hjælp til at logge ind?
                        </LoginLabel>
                    </LoginButton>
                ) : null}
            </LoginButtonWrapper>
        </Fragment>
    )
}

UsernameLogin.propTypes = {
    currentTheme: PropTypes.object,
    forgotPassword: PropTypes.func,
    loginHandler: PropTypes.func,
}

export default UsernameLogin

import React from 'react'
import styled from 'styled-components'
import ButtonList from './ButtonList'
import PropTypes from 'prop-types'

const ButtonListHolder = styled.div`
    display: flex;
    overflow-x: scroll;
    -ms-overflow-style: none;
    scrollbar-width: none;
    padding: 0 0 0 10px;

    &::-webkit-scrollbar {
        display: none;
    }
`

const ButtonsSwitcher = ({ tabs, activeTab, onChange, menuColors }) => {
    return (
        <ButtonListHolder>
            <ButtonList
                buttonSet={tabs}
                value={activeTab}
                onChange={onChange}
                btnActiveColor={menuColors.btnActiveColor}
                btnActiveTextColor={menuColors.btnActiveTextColor}
            />
        </ButtonListHolder>
    )
}

ButtonsSwitcher.propTypes = {
    value: PropTypes.string,
    onChange: PropTypes.func,
    tabs: PropTypes.arrayOf(
        PropTypes.shape({
            name: PropTypes.string,
            label: PropTypes.string,
            title: PropTypes.string,
        })
    ).isRequired,
    menuColors: PropTypes.objectOf(PropTypes.string),
}

export default ButtonsSwitcher

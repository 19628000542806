const SERVERS = {
    'bet25.dk': 'prod',
    'bet25test.dk': 'test',
    'bet25dev.dk': 'dev',
    'bet25dev2.dk': 'dev2',
    'bet25dev3.dk': 'dev3',
    localhost: 'dev',
}

const API_PATHS = {
    prod: 'https://api.bet25.dk',
    test: 'https://api.bet25test.dk',
    dev: 'https://api.bet25dev.dk',
    dev2: 'https://api.bet25dev2.dk',
    dev3: 'https://api.bet25dev3.dk',
}

export const getApiUrl = () => {
    const host = window.location.hostname
    const env = SERVERS[host]
    return API_PATHS[env]
}

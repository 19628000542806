import React from 'react'
import styled, { keyframes } from 'styled-components'
import { UI_CONFIG } from '../configs/ui'

const bounceDelay = keyframes`
  0%, 80%, 100% {
    -webkit-transform: scale(0);
    transform: scale(0);
  } 40% {
    -webkit-transform: scale(1.0);
    transform: scale(1.0);
  }
`

const Bounce = styled.div`
    display: inline-block;
    width: 12px;
    height: 12px;
    margin: 0 3px;
    background: ${(props) =>
        props.background ? props.background : UI_CONFIG.loaderColor};

    border-radius: 100%;
    -webkit-animation: ${bounceDelay} 1.4s infinite ease-in-out both;
    animation: ${bounceDelay} 1.4s infinite ease-in-out both;
`

const Wrapper = styled.div`
    display: inline-block;
    text-align: center;
    & > :first-child {
        -webkit-animation-delay: -0.32s;
        animation-delay: -0.32s;
    }
    & > :nth-child(2) {
        -webkit-animation-delay: -0.16s;
        animation-delay: -0.16s;
    }
`

const Loader = ({ dotsNumber = 3, color = UI_CONFIG.loaderColor }) => {
    return (
        <Wrapper>
            <Bounce background={color} />
            <Bounce background={color} />
            <Bounce background={color} />
        </Wrapper>
    )
}

export default Loader

import React, { useState, useEffect } from 'react'

/**
 *
 * @param Component
 * @return {function(*): *}
 */
const WithClock = (Component) =>
    function ClockComponent(props) {
        const [date, setDate] = useState(new Date())

        useEffect(() => {
            let timerID = setInterval(() => tick(), 1000)
            return () => {
                clearInterval(timerID)
            }
        }, [])

        const tick = () => {
            setDate(new Date())
        }

        return <Component {...props} date={date} />
    }

export default WithClock

import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import { UI_CONFIG } from '../configs/ui'

const MENU_COLORS = UI_CONFIG.menuColors

const StyledButton = styled.button`
    height: 37px;
    font-size: 16px;
    border: 1px solid transparent;
    border-radius: 5px;
    text-transform: none;
    cursor: pointer;
    padding: 0 20px;
    font-weight: bold;
    margin: 0 11px 0 0;
    background: ${(props) => (props.active ? props.btnActiveColor : 'none')};
    color: ${(props) =>
        props.active ? props.btnActiveTextColor : MENU_COLORS.btnColor};

    :hover {
        ${(props) =>
            !props.active &&
            css`
                color: ${MENU_COLORS.btnColor};
                background: ${MENU_COLORS.btnHoverColor};
            `};
    }
`

const ButtonList = ({
    value = '',
    onChange = (f) => f,
    buttonSet = [],
    btnActiveColor = MENU_COLORS.btnActiveColor,
    btnActiveTextColor = MENU_COLORS.btnActiveTextColor,
}) => {
    return (
        <Fragment>
            {buttonSet.map((button, key) => {
                return (
                    <StyledButton
                        key={key}
                        onClick={() => onChange(button)}
                        active={value === button.url}
                        btnActiveColor={btnActiveColor}
                        btnActiveTextColor={btnActiveTextColor}
                    >
                        {button.title ? button.title : button.label}
                    </StyledButton>
                )
            })}
        </Fragment>
    )
}

ButtonList.propTypes = {
    value: PropTypes.string,
    onChange: PropTypes.func,
    buttonSet: PropTypes.arrayOf(
        PropTypes.shape({
            name: PropTypes.string,
            label: PropTypes.string,
            title: PropTypes.string,
        })
    ).isRequired,
    btnActiveColor: PropTypes.string,
    btnActiveTextColor: PropTypes.string,
}

export default ButtonList

const getSiteName = window.location.hostname.replace(
    /.+\/\/|www.|test.|dev.|\..+/g,
    ''
)

export const BASE_SITE_NAMES = {
    dev: 'bet25dev.dk',
    test: 'bet25test.dk',
    prod: 'bet25.dk',
}

export const getEvoSkin = (siteName = 'rod25') => {
    const getSkin = (skin) => {
        return {
            skin: skin,
            brand: skin,
        }
    }

    const skin = {
        rod25: getSkin(1),
        casinogo: getSkin(2),
        bet25: getSkin(3),
        derby25: getSkin(4),
    }

    return skin[siteName]
}

export function getApiUrl() {
    return apiEndPointGenarator(
        `https://api.${BASE_SITE_NAMES.dev}/casino`,
        `https://api.${BASE_SITE_NAMES.test}/casino`,
        `https://api.${BASE_SITE_NAMES.prod}/casino`
    )
}

export function getBetApiUrl() {
    return apiEndPointGenarator(
        `https://api.${BASE_SITE_NAMES.dev}/util/`,
        `https://api.${BASE_SITE_NAMES.test}/util/`,
        `https://api.${BASE_SITE_NAMES.prod}/util/`
    )
}

export function ENV() {
    return apiEndPointGenarator('dev', 'test', 'prod')
}

export function apiEndPointGenarator(dev, test, prod) {
    if (window.location.hostname.indexOf('dev') > -1) {
        return dev
    } else {
        switch (window.location.hostname) {
            case 'localhost':
            case BASE_SITE_NAMES.dev:
                return dev
            case BASE_SITE_NAMES.test:
                return test
            default:
                return prod
        }
    }
}

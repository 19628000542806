import React from 'react'
import PropTypes from 'prop-types'
import DigitalDisplay from './DigitalDisplay'

/**
 *
 * @param number {int}
 * @return {string}
 */
const pad = (number) =>
    number < 10 ? '0' + number : number ? number.toString() : ''

const DigitalTimer = ({
    passedTime,
    color = 'inherit',
    displayFormat = 'HH:mm:ss',
}) => {
    const millisecondsInDay = 60 * 60 * 1000 * 24,
        millisecondsInHour = 60 * 60 * 1000

    let timeDifference = passedTime,
        hours = Math.floor(
            (timeDifference % millisecondsInDay) / millisecondsInHour
        ),
        minutes = Math.floor(
            ((timeDifference % millisecondsInDay) % millisecondsInHour) /
                (60 * 1000)
        ),
        seconds = Math.floor(
            (((timeDifference % millisecondsInDay) % millisecondsInHour) %
                (60 * 1000)) /
                1000
        )

    return (
        <div>
            <DigitalDisplay
                hours={displayFormat.includes('HH') ? pad(hours) : ''}
                minutes={displayFormat.includes('mm') ? pad(minutes) : ''}
                seconds={displayFormat.includes('ss') ? pad(seconds) : ''}
                color={color}
            />
        </div>
    )
}

DigitalTimer.propTypes = {
    passedTime: PropTypes.number.isRequired,
    color: PropTypes.string,
    displayFormat: PropTypes.string,
}

export default DigitalTimer

import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { CenterBlock, MediaGrid } from '../styled'
import ProvidersThumbs from './ProvidersThumbs/ProvidersThumbs'
import GameList from './GameList'
import { BREAKPOINTS } from '../themes/breakpoints'
import { PROVIDERS_THUMBS } from '../configs/categories'
import { Text } from '@it25syv/25syv-ui'
import PropTypes from 'prop-types'

const ContentWrapper = styled.div`
    width: 100%;
    height: auto;
    min-height: auto;
    background: #f8f8fa;
    overflow-y: unset;
`

const ProvidersThumbBlock = styled(CenterBlock)`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
`

const StyledThumbBlock = styled(CenterBlock)`
    margin-top: 50px;
    margin-bottom: 50px;
    padding: 0 25px;
    min-height: 300px;

    @media (max-width: ${BREAKPOINTS.lg + 'px'}) {
        font-size: 12px;
        line-height: 12px;
    }
`

const MediaGridCustom = styled(MediaGrid)`
    @media (max-width: ${BREAKPOINTS.sm + 'px'}) {
        grid-template-columns: repeat(2, 1fr);
    }

    @media (min-width: ${BREAKPOINTS.md + 1 + 'px'}) {
        grid-template-columns: repeat(4, 1fr);
    }
`

const Content = ({
    activeCategory,
    liveCasinoGames,
    showThumbsByProviders,
    showGamesByProviders,
    thumbClickHandler,
    gameClickHandler,
    gamesCount = (f) => f,
}) => {
    const [displayedGames, setDisplayedGames] = useState([])
    const [providersCount, setCategoryCount] = useState(0)
    const [providersThumbs, setProvidersThumbs] = useState([])

    const findTableForProviderThumb = (gameId) => {
        const matchGame = liveCasinoGames.find((game) => game.id === gameId)
        return matchGame ? matchGame.liveCas : {}
    }

    const matchProvidersThumbToTable = () => {
        return PROVIDERS_THUMBS.map((thumb) => {
            return {
                ...thumb,
                liveCasino: thumb.gameId
                    ? findTableForProviderThumb(thumb.gameId)
                    : {},
            }
        })
    }

    const showByProvider = (provider = '') => {
        return showGamesByProviders.length === 0
            ? true
            : showGamesByProviders.indexOf(provider) >= 0
    }

    const filterByOpened = (game) => game.liveCas

    const filterGamesByCategory = (games, categoryName) => {
        return games.filter(
            (game) =>
                game.liveCasinoPageCategories.includes(categoryName) &&
                showByProvider(game.subprovider) &&
                filterByOpened(game)
        )
    }

    const sortingGames = (games) => {
        return games
            .sort(
                (a, b) =>
                    Number(b.tag_hot) - Number(a.tag_hot) ||
                    parseFloat(b.clicks || 0) - parseFloat(a.clicks || 0) ||
                    a.name.localeCompare(b.name)
            )
            .sort((a, b) =>
                b.tag_hot && a.tag_hot
                    ? parseInt(a.priority) - parseInt(b.priority)
                    : 0
            )
    }

    useEffect(() => {
        let filteredGames = filterGamesByCategory(
            liveCasinoGames,
            activeCategory.name
        )
        
        setCategoryCount(filteredGames.length)
        gamesCount(filteredGames.length)
        setDisplayedGames(sortingGames(filteredGames))
    }, [activeCategory, liveCasinoGames])

    useEffect(() => {
        setProvidersThumbs(matchProvidersThumbToTable())
    }, [])

    return (
        <ContentWrapper>
            <ProvidersThumbBlock>
                {providersThumbs && (
                    <ProvidersThumbs
                        activeCategoryName={activeCategory.name}
                        thumbs={providersThumbs}
                        showProviders={showThumbsByProviders}
                        thumbClickHandler={thumbClickHandler}
                        liveCasinoGames={liveCasinoGames}
                    />
                )}
            </ProvidersThumbBlock>
            <StyledThumbBlock>
                {displayedGames.length ? (
                    <MediaGridCustom>
                        <GameList
                            games={displayedGames}
                            gameClickHandler={gameClickHandler}
                        />
                    </MediaGridCustom>
                ) : (
                    <Text align={'center'} pt={3}>
                        Ingen match fundet.
                    </Text>
                )}
            </StyledThumbBlock>
        </ContentWrapper>
    )
}

Content.propTypes = {
    activeCategory: PropTypes.object.isRequired,
    liveCasinoGames: PropTypes.arrayOf(PropTypes.object).isRequired,
    showThumbsByProviders: PropTypes.arrayOf(PropTypes.string).isRequired,
    showGamesByProviders: PropTypes.arrayOf(PropTypes.string).isRequired,
    thumbClickHandler: PropTypes.func,
    gameClickHandler: PropTypes.func,
    gamesCount: PropTypes.func,
}

export default Content

import React, { useEffect, useState } from 'react'
import Input from '../Input'

export const emailRegEx = /^[a-zA-Z0-9æøåöÆØÅÖ.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
export const numericRegEx = /^\d+$/
export const alphabeticRegEx = /^[a-zA-Z æøåöÆØÅÖ]+$/
export const noSpaceRegEx = /^((?=.*\\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[@#$%]).)$/
export const userNameRegEx = /^[a-zA-Z0-9æøåöÆØÅÖ]{6,}$/
export const passwordRegEx = /^(?=.*[A-Za-z])(?=(.*[\d]))(?=.*[A-Z])[A-Za-z0-9]{6,}$/

const validation = {
    required: 'Skal udfyldes',
    numeric: 'Feltet bør kun indeholde cifre',
    alphabetic: 'Feltet må kun indeholde bogstaver',
    email: 'Ugyldig email',
    min: 'Mindste værdi ',
    max: 'Maksimal værdi ',
    minLength: 'Længde må ikke være mindre end ',
    maxLength: 'Længde må ikke overstige ',
    symbol: 'tegn',
    symbols: 'tegn',
    notValid: 'Ugyldig værdi',
    sameAs: 'Feltet skal bekræftes',
    minNumber: 'Mindste værdi %d',
    maxNumber: 'Maksimal værdi %d',
}

const ValidInput = ({
    showErrorOnChange,
    onChange,
    value,
    validate,
    validationHandler,
    getValidator,
    placeholder,
    showErrorUnderInput,
    ...props
}) => {
    const [validationError, setValidationError] = useState(null)
    const [brokenRules, setBrokenRules] = useState([])

    const onInputChange = (value) => {
        const valid = onValidate(value)

        if (!valid && !showErrorOnChange) {
            // if showing error on change is not enabled
            // the validation error will be reset
            setValidationError(null)
        }

        if (onChange) {
            onChange(value, valid)
        }
    }

    const isValidInput = () => {
        onValidate(value)
    }

    const onValidate = (value) => {
        const inputValidateRules = validate

        let isValid = true

        if (inputValidateRules) {
            if (
                inputValidateRules.required &&
                // deny empty string or just string with empty whitespaces
                (value === '' || /^ +$/g.test(value))
            ) {
                setValidationError(
                    inputValidateRules.required.message
                        ? inputValidateRules.required.message
                        : validation.required
                )
                setBrokenRules([...brokenRules, 'required'])

                isValid = false
            }

            let emptyInput = typeof value === 'undefined' || value === ''
            if (!emptyInput) {
                if (inputValidateRules.numeric && !numericRegEx.test(value)) {
                    setValidationError(
                        inputValidateRules.numeric.message
                            ? inputValidateRules.numeric.message
                            : validation.numeric
                    )
                    setBrokenRules([...brokenRules, 'numeric'])

                    isValid = false
                }

                if (
                    inputValidateRules.alphabetic &&
                    !alphabeticRegEx.test(value)
                ) {
                    setValidationError(
                        inputValidateRules.alphabetic.message
                            ? inputValidateRules.alphabetic.message
                            : validation.alphabetic
                    )
                    setBrokenRules([...brokenRules, 'alphabetic'])

                    isValid = false
                }

                if (inputValidateRules.email && !emailRegEx.test(value)) {
                    setValidationError(
                        inputValidateRules.email.message
                            ? inputValidateRules.email.message
                            : validation.email
                    )
                    setBrokenRules([...brokenRules, 'email'])

                    isValid = false
                }

                if (
                    inputValidateRules.withoutSpaces &&
                    !noSpaceRegEx.test(value)
                ) {
                    setValidationError(
                        inputValidateRules.withoutSpaces.message
                            ? inputValidateRules.withoutSpaces.message
                            : validation.notValid
                    )
                    setBrokenRules([...brokenRules, 'withoutSpaces'])

                    isValid = false
                }

                if (
                    inputValidateRules.sameAs &&
                    value !== inputValidateRules.sameAs.confirmationValue
                ) {
                    setValidationError(
                        inputValidateRules.sameAs.message
                            ? inputValidateRules.sameAs.message
                            : validation.sameAs
                    )
                    setBrokenRules([...brokenRules, 'sameAs'])

                    isValid = false
                }

                if (inputValidateRules.min && value < inputValidateRules.min) {
                    setValidationError(validation.notValid)
                    setBrokenRules([...brokenRules, 'min'])

                    isValid = false
                }

                if (inputValidateRules.max && value > inputValidateRules.max) {
                    setValidationError(validation.notValid)
                    setBrokenRules([...brokenRules, 'max'])

                    isValid = false
                }

                if (
                    inputValidateRules.minLength &&
                    value.length < inputValidateRules.minLength
                ) {
                    setValidationError(
                        inputValidateRules.minLengthMessage ||
                            validation.notValid
                    )
                    setBrokenRules([...brokenRules, 'minLength'])

                    isValid = false
                }

                if (
                    inputValidateRules.maxLength &&
                    value.length > inputValidateRules.maxLength
                ) {
                    setValidationError(validation.notValid)
                    setBrokenRules([...brokenRules, 'maxLength'])

                    isValid = false
                }

                if (inputValidateRules.userName && !userNameRegEx.test(value)) {
                    setValidationError(
                        inputValidateRules.userName.message
                            ? inputValidateRules.userName.message
                            : validation.notValid
                    )
                    setBrokenRules([...brokenRules, 'userName'])

                    isValid = false
                }

                if (inputValidateRules.password && !passwordRegEx.test(value)) {
                    setValidationError(
                        inputValidateRules.password.message
                            ? inputValidateRules.password.message
                            : validation.notValid
                    )
                    setBrokenRules([...brokenRules, 'password'])

                    isValid = false
                }

                if (
                    inputValidateRules.regExp &&
                    !inputValidateRules.regExp.test(value)
                ) {
                    setValidationError(validation.notValid)
                    setBrokenRules([...brokenRules, 'regExp'])

                    isValid = false
                }
            }
        }

        if (isValid) {
            setValidationError(null)
        }

        if (validationHandler) {
            validationHandler(isValid)
        }

        return isValid
    }

    useEffect(() => {
        if (getValidator) {
            getValidator(() => onValidate(value))
        }
    }, [value])

    return (
        <Input
            {...props}
            value={value}
            onChange={onInputChange}
            placeholder={placeholder}
            onInputBlueredHandler={isValidInput}
            validationError={
                !props.onDemand
                    ? validationError
                    : props.showError
                    ? validationError
                    : null
            }
            disabled={props.disabled}
            type={props.type}
            sublabel={props.sublabel}
            align={props.align}
            showErrorUnderInput={showErrorUnderInput}
        />
    )
}

export default ValidInput

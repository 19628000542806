export const UI_CONFIG = {
    menuColors: {
        btnColor: '#333333',
        btnActiveColor: '#FDE9E9',
        btnActiveTextColor: '#DA1D1B',
        btnHoverColor: '#ebebeb',
    },
    textColor: '#646464',
    loaderColor: '#646464',
}

import { CDN_IMG_PATH, CMS_PATH } from '../configs/main'

const imageSizes = {
    xs: '_xs',
    sm: '_sm',
    md: '_md',
    lg: '_lg',
    xl: '_xl',
    xxl: '_xxl',
}

const imagePath = CDN_IMG_PATH

/*
 * Select image size by replacing actual image path
 * */
const replaceImagePath = (imagePath, imageSize = 'xs') => {
    const splitPath = imagePath.split('/')
    const image = splitPath[splitPath.length - 1]
    const imageName = image.split('.')[0]

    splitPath[splitPath.length - 1] = image.replace(
        imageName,
        imageName + imageSizes[imageSize]
    )

    return splitPath.join('/')
}

const checkImage = (url, cb) => {
    new Promise((resolve, reject) => {
        const img = new Image()
        img.src = url
        img.onload = () => resolve(url)
        img.onerror = () => {
            reject()
        }
    })
        .then((validUrl) => {
            cb(validUrl)
        })
        .catch(() => {
            cb(false)
        })
}

export const imageSvg = (game, cb) => {
    if (game?.images?.svg?.length && game.alt && parseFloat(game.alt)) {
        const svgImg = `${CMS_PATH}${game.images.svg}`
        checkImage(svgImg, (validUrl) => cb(validUrl))
    } else if (game?.svgimage?.length && game.alt && parseFloat(game.alt)) {
        const svgImg = `${CMS_PATH}${game.svgimage}`
        checkImage(svgImg, (validUrl) => cb(validUrl))
    } else {
        cb(false)
    }
}

export const defaultImage = (game, size, cb) => {
    if (game?.images?.thumb?.length) {
        const imgSrc = `${CMS_PATH}${replaceImagePath(game.images.thumb, size)}`
        checkImage(imgSrc, (validUrl) => cb(validUrl))
    } else if (game?.image?.length) {
        const imgSrc = `${CMS_PATH}${replaceImagePath(game.image, size)}`
        checkImage(imgSrc, (validUrl) => cb(validUrl))
    } else {
        cb(false)
    }
}

export const reserveImage = (gameId) => {
    return gameId ? imagePath + gameId + '-tmb.jpg' : ''
}

export const getImageSrc = (game, cb) => {
    imageSvg(game, (imageUrl) => {
        if (imageUrl) {
            cb(imageUrl)
        } else {
            defaultImage(game, 'md', (imageUrl) => {
                cb(imageUrl || reserveImage(game.game_id))
            })
        }
    })
}

import React, { useEffect, useState } from 'react'
import { BackBtn, CloseSignupBtn, SignUpTop, SignUpWrapper } from './styled'
import { Text } from '@it25syv/25syv-ui'
import Stepper from '../components/Stepper'
import Step1 from './Step1'
import Step2 from './Step2'
import Step3, { DEPOSIT_LIMITS } from './Step3'
import Step4 from './Step4'
import { ReactComponent as CloseIcon } from './icons/close.svg'
import { ReactComponent as BackBtnIcon } from './icons/back.svg'
import AuthService from '../services/AuthService'
import { parseError, parseUserDataStep3 } from '../utils/parsers'
import { ThemeProvider } from 'styled-components'
import { dark, defaultTheme } from './themes'
import { siteByProjectName } from '../configs/main'

const themesMap = {
    default: defaultTheme,
    dark
}

const signupSteps = ['Konto', 'Profil', 'Sikkerhed', 'Bekræft']

const scrollToRef = (ref) => {
    ref.current?.scrollTo(0, 0)
    ref.current?.scrollIntoView({ behavior: 'smooth', block: 'start' })
    ref.current?.scrollIntoView(true) // for old ios version
}

const Signup = ({
    project,
    onEmailExists,
    onCPRExists,
    onClose,
    showError,
    scrollableWrapperRef,
    defaultStep = 1,
    theme = 'default',
    onFocus = () => {},
    isTerminal = false,
    showBonusCode = true,
    avatar = {
        show: false,
        path: ''
    },
    title = '',
    addressSpinnerPopup = () => {},
    terminalEmailExist = () => {},
    minimumDepositLimit = 50,
    step1Headline
}) => {
    const [currentStep, setCurrentStep] = useState(defaultStep)
    const [stepValidity, setStepValidity] = useState(false)
    const [serverError, setServerError] = useState('')
    const chosenTheme = themesMap[theme]
    const projectName = project || 'bet25'

    useEffect(() => {
        scrollToTop()
        AuthService.isTerminal = isTerminal
    }, [])

    const scrollToTop = () => {
        scrollableWrapperRef && scrollToRef(scrollableWrapperRef)
    }

    const [userData, setUserData] = useState({
        userName: '',
        email: '',
        password: '',
        newsletterSub: '',
        bonusCode: '',
        fullName: '',
        address: '',
        city: '',
        zip: '',
        phoneNumber: '',
        cpr: '',
        dailyDepositLimit: null,
        weeklyDepositLimit: null,
        monthlyDepositLimit: null,
        expectBetting: '',
        pinCode: '',
        loseLimit: 10000,
        project: projectName,
        // Not BE parameters
        pureAddress: '',
        depositLimit: '',
        depositLimitType: DEPOSIT_LIMITS.daily.name,
        rulesAccepted: false,
        stepsValidated: {
            step1: false,
            step2: false,
            step3: false,
            step4: false
        }
    })

    const selectStep = (step) => {
        let validatedStep

        currentStep > step
            ? (validatedStep = step)
            : (validatedStep = currentStep)

        if (
            userData.stepsValidated['step' + validatedStep] === true &&
            (step < currentStep || (stepValidity && step - currentStep === 1))
        ) {
            setCurrentStep(step)
        }
    }

    const backBtnHandler = () => {
        if (currentStep > 1) {
            setCurrentStep(currentStep - 1)
        }
    }

    const updateUserData = (payload) => {
        setUserData({ ...userData, ...payload })
    }

    const submitHandlerStep3 = (onSuccess) => {
        const params = parseUserDataStep3(userData)

        updateUserData(params)

        AuthService.storePunter(params, !siteByProjectName[projectName])
            .then(onSuccess)
            .catch((error) => {
                const msg = parseError(error)
                console.log(error)
                setServerError(msg)
                scrollToTop()
                showError(msg)
            })
    }

    const getTemplateByStep = (step = 1) => {
        if (step === 1) {
            return (
                <Step1
                    onEmailExists={onEmailExists}
                    terminalEmailExist={terminalEmailExist}
                    setStep={setCurrentStep}
                    updateUserData={updateUserData}
                    setStepValidity={setStepValidity}
                    setUserData={setUserData}
                    userData={userData}
                    onFocus={onFocus}
                    isTerminal={isTerminal}
                    theme={chosenTheme}
                    avatar={avatar}
                    showBonusCode={showBonusCode}
                    project={projectName}
                    oldAPI={!siteByProjectName[projectName]}
                    step1Headline={step1Headline}
                />
            )
        } else if (step === 2) {
            return (
                <Step2
                    setStep={setCurrentStep}
                    onCPRExists={onCPRExists}
                    signupData={userData}
                    updateUserData={updateUserData}
                    setStepValidity={setStepValidity}
                    setUserData={setUserData}
                    userData={userData}
                    showError={showError}
                    scrollToTop={scrollToTop}
                    onFocus={onFocus}
                    isTerminal={isTerminal}
                    addressSpinnerPopup={addressSpinnerPopup}
                    oldAPI={!siteByProjectName[projectName]}
                    theme={chosenTheme}
                />
            )
        } else if (step === 3) {
            return (
                <Step3
                    setStep={setCurrentStep}
                    setStepValidity={setStepValidity}
                    setUserData={setUserData}
                    userData={userData}
                    scrollToTop={scrollToTop}
                    serverError={serverError}
                    onSave={submitHandlerStep3}
                    onFocus={onFocus}
                    isTerminal={isTerminal}
                    theme={chosenTheme}
                    project={projectName}
                    minimumDepositLimit={minimumDepositLimit}
                />
            )
        } else if (step === 4) {
            return (
                <Step4
                    setStep={setCurrentStep}
                    setStepValidity={setStepValidity}
                    userData={userData}
                    scrollToTop={scrollToTop}
                    theme={chosenTheme}
                    site={siteByProjectName[projectName]}
                />
            )
        }
    }

    return (
        <ThemeProvider theme={chosenTheme}>
            <SignUpWrapper>
                <SignUpTop>
                    {currentStep > 1 ? (
                        <BackBtn onClick={backBtnHandler}>
                            <BackBtnIcon />
                        </BackBtn>
                    ) : null}
                    <Text
                        center
                        size='x2'
                        color={chosenTheme.colors.basic}
                        style={{
                            margin: 0,
                            textAlign: 'center',
                            width: '100%',
                            fontWeight: chosenTheme.fontWeight,
                            fontSize: '18px'
                        }}
                    >
                        {title || 'Opret en 25-konto'}
                    </Text>

                    <CloseSignupBtn onClick={onClose}>
                        <CloseIcon alt='Close button' />
                    </CloseSignupBtn>
                </SignUpTop>
                <Stepper
                    currentStep={currentStep}
                    steps={signupSteps}
                    progressColor={chosenTheme.colors.secondaryAction}
                    selectStep={selectStep}
                    userData={userData}
                    submitHandlerStep3={submitHandlerStep3}
                    theme={chosenTheme}
                />
                {getTemplateByStep(currentStep, setCurrentStep)}
            </SignUpWrapper>
        </ThemeProvider>
    )
}

export default Signup

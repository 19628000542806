import React from 'react'
import {
    BlackjackBetBehind,
    BlackjackCircle,
    BlackjackTakenSeat,
    BottomDropDown,
} from './styled'

const Blackjack = ({ liveCasinoData, active, privateTable }) => {
    const seats = liveCasinoData.seats === null ? 0 : liveCasinoData.seats
    const seatsTaken =
        liveCasinoData.seatsTaken === null ? [] : liveCasinoData.seatsTaken
    const arraySeats = Array.from(Array(seats).keys())

    const isSeatTaken = (number) => seatsTaken.includes(number)

    const isAllowedData = () =>
        typeof seats === 'number' && seats > 0 && Array.isArray(seatsTaken)

    return (
        isAllowedData() && (
            <BottomDropDown active={active} privateTable={privateTable}>
                {seatsTaken ? (
                    seatsTaken.length === seats ? (
                        <BlackjackBetBehind>Bet behind</BlackjackBetBehind>
                    ) : (
                        arraySeats.map((number, index) => {
                            return (
                                <BlackjackCircle
                                    key={index}
                                    color={isSeatTaken(number)}
                                >
                                    <BlackjackTakenSeat
                                        taken={isSeatTaken(number)}
                                    />
                                </BlackjackCircle>
                            )
                        })
                    )
                ) : null}
            </BottomDropDown>
        )
    )
}

export default Blackjack

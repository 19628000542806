import React from 'react'
import Countdown, { zeroPad } from 'react-countdown'
import styled from 'styled-components'
import { BREAKPOINTS } from '../themes/breakpoints'
import PropTypes from 'prop-types'

const TimerBoxWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    flex-direction: row;
`

const TimerBox = styled.div`
    width: 57px;
    height: 57px;
    line-height: 57px;
    border-radius: 5px;
    background: rgba(0, 0, 0, 0.8);
    color: #fff;
    font-size: 32px;
    text-align: center;
    vertical-align: middle;

    @media (max-width: ${BREAKPOINTS.lg + 'px'}) {
        width: 37px;
        height: 37px;
        line-height: 37px;
        font-size: 20px;
    }
`

const renderer = ({ hours, minutes, seconds, completed }) => {
    if (completed) {
        return null
    } else {
        return (
            <TimerBoxWrapper>
                <TimerBox>{zeroPad(hours)}</TimerBox>
                <TimerBox>{zeroPad(minutes)}</TimerBox>
                <TimerBox>{zeroPad(seconds)}</TimerBox>
            </TimerBoxWrapper>
        )
    }
}

const CountdownTimer = ({
    startTime,
    completeCallback = () => {},
    onMountCallback = () => {},
}) => {
    const currentDate = React.useRef(Date.now()) //prevent unpredictable update
    /**
     * Convert time with format HH:MM to ms
     * @param {string} t - time
     */
    const convertToMS = (t) => {
        return (
            Number(t.split(':')[0]) * 60 * 60 * 1000 +
            Number(t.split(':')[1]) * 60 * 1000
        )
    }

    const subtractTime = (startTime) => {
        const d = new Date()
        const curTime = `${d.getHours()}:${d.getMinutes()}`

        return convertToMS(startTime) - convertToMS(curTime)
    }

    return (
        <Countdown
            date={currentDate.current + subtractTime(startTime)}
            precision={4}
            renderer={renderer}
            onComplete={(timerStatus) => {
                completeCallback(timerStatus.completed)
            }}
            onMount={(status) => {
                return Object.keys(status)
                    ? onMountCallback(status.completed)
                    : false
            }}
        />
    )
}

CountdownTimer.propTypes = {
    startTime: PropTypes.string,
    completeCallback: PropTypes.func,
    onMountCallback: PropTypes.func,
}

export default CountdownTimer

import React from 'react'
import PropTypes from 'prop-types'
import { ThemeProvider } from 'styled-components'
import {
    ActionWrapper,
    Button,
    Details,
    Headline,
    Item,
    Name,
    Summary,
    Wrapper,
    IconWrapper,
} from './styled'
import {
    numberWithCommasAndDotsIntl,
    dateFormatter,
} from '../../../utils/formatters'
import IconClose from '../../../images/icons/close-thin.svg'
import Icon from '../../Icon'
import { themesMap } from '../../../theme'

const TransactionDetails = ({ data = {}, onClose = () => {} }) => {
    const theme = themesMap['default']

    const {
        amount,
        transId,
        refId,
        text: type,
        transactionDate,
        product,
    } = data
    return (
        <ThemeProvider theme={theme}>
            <Wrapper
                onClick={(e) => {
                    e.preventDefault()
                    e.stopPropagation()
                }}
            >
                <Headline>{type}</Headline>
                <IconWrapper onClick={onClose}>
                    <Icon
                        height={'24px'}
                        width={'24px'}
                        src={IconClose}
                        color={theme.colors.semi_grey_500}
                    />
                </IconWrapper>
                <Details>
                    <Item>
                        <Name>Detaljer</Name>
                        <Summary>{product}</Summary>
                    </Item>
                    <Item>
                        <Name>Transaktions ID</Name>
                        <Summary>{transId}</Summary>
                    </Item>
                    <Item>
                        <Name>Reference ID</Name>
                        <Summary>{refId}</Summary>
                    </Item>
                    <Item>
                        <Name>Dato</Name>
                        <Summary>
                            {dateFormatter(
                                new Date(transactionDate).toLocaleString(
                                    'da-DK',
                                    {
                                        dateStyle: 'short',
                                        timeStyle: 'short',
                                    }
                                )
                            )}
                        </Summary>
                    </Item>
                    <Item last>
                        <Name>Beløb</Name>
                        <Summary>
                            {numberWithCommasAndDotsIntl(amount)} kr.
                        </Summary>
                    </Item>
                </Details>
                <ActionWrapper>
                    <Button onClick={onClose}>OK</Button>
                </ActionWrapper>
            </Wrapper>
        </ThemeProvider>
    )
}

TransactionDetails.propTypes = {
    data: PropTypes.shape({}),
    onClose: PropTypes.func,
}

export default TransactionDetails

import { css } from 'styled-components'

/** Prop passing Shorthands for Styled-components
 * The approach was inspired by Bootstrap 4 spacing https://getbootstrap.com/docs/4.4/utilities/spacing/
 **/

/**
 *
 * @param number {int}
 */
const getSpacingByNumber = (number) => {
    const spacingRange = [0, 1, 2, 3, 4, 5]
    if (!spacingRange.includes(number)) {
        throw new Error(
            `Spacing must be one of numbers ${spacingRange}, instead ${number} type of ${typeof number} was supplied`
        )
    }
    switch (number) {
        case 0:
            return 0
        case 1:
            return 0.25
        case 2:
            return 0.5
        case 3:
            return 1
        case 4:
            return 1.5
        case 5:
            return 3
        default:
            return 0
    }
}

export const borderProps = ({ bt, br, bb, bl, color }) => {
    return css`
        ${bb && `border-bottom: ${bb || '1px'} solid ${color.border}`};
        ${bt && `border-top: ${bt || '1px'} solid ${color.border}`};
        ${bl && `border-left: ${bl || '1px'} solid ${color.border}`};
        ${br && `border-right: ${br || '1px'} solid ${color.border}`};
    `
}

export const marginProps = (props) => css`
    ${props.mb && `margin-bottom: ${getSpacingByNumber(props.mb) + 'rem'}`};
    ${props.mt && `margin-top: ${getSpacingByNumber(props.mt) + 'rem'}`};
    ${props.ml && `margin-left: ${getSpacingByNumber(props.ml) + 'rem'}`};
    ${props.mr && `margin-right: ${getSpacingByNumber(props.mr) + 'rem'}`};
    ${props.m && `margin: ${getSpacingByNumber(props.m) + 'rem'}`};
    ${props.mx &&
        `margin-right: ${getSpacingByNumber(props.mx) + 'rem'};
         margin-left: ${getSpacingByNumber(props.mx) + 'rem'};`};
    ${props.my &&
        `margin-top: ${getSpacingByNumber(props.my) + 'rem'};
         margin-bottom: ${getSpacingByNumber(props.my) + 'rem'};`};
`

export const paddingProps = (props) => css`
    ${props.pb && `padding-bottom: ${getSpacingByNumber(props.pb) + 'rem'}`};
    ${props.pt && `padding-top: ${getSpacingByNumber(props.pt) + 'rem'}`};
    ${props.pl && `padding-left: ${getSpacingByNumber(props.pl) + 'rem'}`};
    ${props.pr && `padding-right: ${getSpacingByNumber(props.pr) + 'rem'}`};
    ${props.px &&
        `padding-right: ${getSpacingByNumber(props.px) + 'rem'};
         padding-left: ${getSpacingByNumber(props.px) + 'rem'};`};
    ${props.py &&
        `padding-top: ${getSpacingByNumber(props.py) + 'rem'};
         padding-bottom: ${getSpacingByNumber(props.py) + 'rem'};`};
    ${props.p && `padding: ${getSpacingByNumber(props.p) + 'rem'}`};
`

/**
 *
 * @param borderRadius
 * @param round
 * @param bdrs          Emmit style prop
 * @return {[]|null|*}
 */
export const borderRadiusProps = ({ borderRadius, round, bdrs }) => {
    const value = borderRadius || round || bdrs || 0

    return css`
        border-radius: ${typeof value === 'number'
            ? getSpacingByNumber(value) + 'rem'
            : 'initial'};
    `
}

export default (props) => `
    ${borderProps(props)};
    ${marginProps(props)};
    ${paddingProps(props)};
    ${borderRadiusProps(props)};
`

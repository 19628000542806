import React from 'react'
import styled from 'styled-components'
import { ReactComponent as AvatarIcon } from '../images/icons/avatar.svg'
import { HighlightBox } from '../styled'
import { BREAKPOINTS } from '../themes/breakpoints'
import Roulette from './GameTableTypes/Roulette'
import Blackjack from './GameTableTypes/Blackjack'
import Baccarat from './GameTableTypes/Baccarat'
import { detectMobile } from '../utils/deviceDetect'

const BottomBox = styled.div`
    width: 100%;
    position: absolute;
    bottom: 0;
`
const BottomBoxData = styled.div`
    width: 100%;
    display: flex;
    justify-content: ${(props) =>
        props.showPlayersCount ? 'space-between' : 'flex-end'};
    padding: 0 5px 5px;
    box-sizing: border-box;
`

const UsersCount = styled(HighlightBox)`
    svg {
        display: inline-block;
        margin: 0 5px 0 0;
        vertical-align: middle;

        @media (max-width: ${BREAKPOINTS.md + 'px'}) {
            vertical-align: top;
            margin-top: 3px;
        }
    }
`

const BetRange = styled(HighlightBox)`
    @media (max-width: ${BREAKPOINTS.lg + 'px'}) {
        display: none;
    }
`

const BottomBar = ({
    liveCasinoData,
    showPlayersCount,
    subprovider = '',
    active = false,
    gameType = '',
    privateTable = false,
}) => {
    const convertCurrency = (currency) => currency.toLocaleString('da-DK')
    const seats =
        typeof liveCasinoData.seats === 'number' ? liveCasinoData.seats : 0
    const seatsTaken =
        liveCasinoData.seatsTaken && Array.isArray(liveCasinoData.seatsTaken)
            ? liveCasinoData.seatsTaken
            : []

    const players = seats
        ? seatsTaken.length + '/' + seats
        : liveCasinoData.players

    const showDropDownByType = () => {
        if (
            (!privateTable && subprovider === 'evolution') ||
            subprovider === 'pragmatic'
        ) {
            return liveCasinoData.open
        }

        return false
    }

    const betLimits = () => {
        if (
            privateTable &&
            liveCasinoData.privateTableConfig?.minBetsCount &&
            liveCasinoData.betLimits?.symbol
        ) {
            return (
                liveCasinoData.privateTableConfig.minBetsCount +
                ' ' +
                liveCasinoData.betLimits.symbol
            )
        } else if (
            liveCasinoData.betLimits?.min &&
            liveCasinoData.betLimits?.max
        ) {
            return `${convertCurrency(
                liveCasinoData.betLimits.min
            )} - ${convertCurrency(liveCasinoData.betLimits.max)} ${
                liveCasinoData.betLimits.symbol
            }`
        } else {
            return false
        }
    }

    const showGameTableByType = (type) => {
        switch (type) {
            case 'roulette':
                return (
                    <Roulette
                        liveCasinoData={liveCasinoData}
                        active={detectMobile() ? true : active}
                        privateTable={privateTable}
                    />
                )
            case 'blackjack':
                return (
                    <Blackjack
                        liveCasinoData={liveCasinoData}
                        active={detectMobile() ? true : active}
                        privateTable={privateTable}
                    />
                )
            case 'baccarat':
                return (
                    <Baccarat
                        history={liveCasinoData.history}
                        active={detectMobile() ? true : active}
                    />
                )
        }
    }

    return (
        <BottomBox>
            <BottomBoxData showPlayersCount={showPlayersCount}>
                {showPlayersCount && (
                    <UsersCount>
                        <AvatarIcon />
                        {players}
                    </UsersCount>
                )}
                {betLimits() && <BetRange>{betLimits()}</BetRange>}
            </BottomBoxData>

            {showDropDownByType() ? showGameTableByType(gameType) : null}
        </BottomBox>
    )
}

export default BottomBar

import React from 'react'
import {
    BonusWrapper,
    ButtonDelete,
    ButtonGoTo,
    ButtonWrapper,
    DescriptionText,
    DescriptionWrapper,
    QuestionMarkIcon,
    StatusBarLine,
    StatusBarSlider,
    StatusBarValue,
    StatusBarValueWrapper,
    StatusBarWrapper,
    Wrapper,
} from './styled'
import infoCircle from '../../images/icons/info-circle.svg'
import rodLogo from '../../images/producs-logos/casino.svg'
import trashIcon from '../../images/icons/trash.svg'

import PropTypes from 'prop-types'
import Icon from '../Icon'
import Text from '../Text'
import Flex from '../Flex'
import ReactTooltip from 'react-tooltip'
import { numberWithCommasAndDotsIntl } from '../../utils/formatters'

const ActiveBonuses = ({
    onRemove = () => {},
    activeBonus = 0,
    rollover = 0,
    initialRollover = 0,
    gameId = '',
    redirectUrl,
    bonusButtonText = '',
}) => {
    const turnover = Math.round(initialRollover / activeBonus)
    const statusBarPercent = 100 - (rollover / initialRollover) * 100

    const onRemoveHandler = () => {
        onRemove()
    }

    const getRedirectUrl = () => {
        if (window.location.hostname === 'localhost') {
            window.location = window.location.origin
        }

        window.location.pathname = '/casino'
    }

    return (
        <Wrapper>
            <DescriptionWrapper>
                <img height={'20px'} width={'20px'} src={infoCircle} />
                <DescriptionText>
                    Du har én eller flere aktive bonusser som skal omsættes før
                    du kan udbetale.
                </DescriptionText>
            </DescriptionWrapper>
            <Text
                bold
                size={'sm'}
                color={'#333333'}
                style={{ margin: '21px 0 6px' }}
            >
                Aktive bonusser
            </Text>
            <BonusWrapper>
                <Flex justify={'space-between'}>
                    <Icon
                        src={rodLogo}
                        width={'100px'}
                        height={'25px'}
                        color={'#DB1C1B'}
                    />
                    <QuestionMarkIcon data-tip data-for="happyFace">
                        ?
                    </QuestionMarkIcon>
                    <ReactTooltip
                        id="happyFace"
                        place="left"
                        type="light"
                        effect="solid"
                        borderColor="#70707033"
                        className="tooltip"
                    >
                        <Text bold style={{ marginTop: 0 }}>
                            Hvad er en indbetalingsbonus?
                        </Text>
                        <Text>
                            En indbetalingsbonus består af en forøgelse af
                            spillerens indbetaling.
                        </Text>
                        <Text>
                            Det betyder, at spilleren får f.eks. 100 kr. extra i
                            bonus, hvis denne indsætter 100 kr. på sin
                            spilkonto.
                        </Text>
                        <Text>
                            For at veksle bonuspengene til rigtige penge er der
                            et omsætningskrav. Det indebærer, at spilleren skal
                            omsætte sin bonus et bestemt antal gange, typisk 10
                            gange, inden den bliver konverteret til rigtige
                            penge.
                        </Text>
                    </ReactTooltip>
                </Flex>

                <Text
                    size={'md'}
                    color={'#333333'}
                    style={{ margin: '16px 0 0' }}
                >
                    Indbetalingsbonus på {activeBonus} kr.
                </Text>
                <Text
                    size={'sm'}
                    color={'#9198AA'}
                    style={{ margin: '4px 0 16px' }}
                >
                    {turnover}x omsætningskrav
                </Text>
                <StatusBarWrapper>
                    <StatusBarLine>
                        <StatusBarSlider status={statusBarPercent} />
                    </StatusBarLine>
                    <StatusBarValueWrapper>
                        <StatusBarValue>
                            <strong>
                                {numberWithCommasAndDotsIntl(
                                    Math.abs(rollover - initialRollover)
                                )}
                            </strong>{' '}
                            / {numberWithCommasAndDotsIntl(initialRollover)} kr.
                        </StatusBarValue>
                        <StatusBarValue>
                            -{numberWithCommasAndDotsIntl(rollover)} kr.
                        </StatusBarValue>
                    </StatusBarValueWrapper>
                    <ButtonWrapper>
                        <ButtonGoTo
                            onClick={() => {
                                typeof redirectUrl === 'function'
                                    ? redirectUrl()
                                    : getRedirectUrl()
                            }}
                        >
                            {bonusButtonText
                                ? bonusButtonText
                                : 'Gå til alle spil'}
                        </ButtonGoTo>
                        <ButtonDelete onClick={() => onRemoveHandler()}>
                            <Icon
                                src={trashIcon}
                                width={'19px'}
                                height={'21px'}
                                color={'#D42C2C'}
                            />
                        </ButtonDelete>
                    </ButtonWrapper>
                </StatusBarWrapper>
            </BonusWrapper>
        </Wrapper>
    )
}

ActiveBonuses.propTypes = {
    onRemove: PropTypes.func.isRequired,
    activeBonus: PropTypes.number.isRequired,
    initialRollover: PropTypes.number.isRequired,
    gameId: PropTypes.string,
    redirectUrl: PropTypes.func,
    bonusButtonText: PropTypes.string,
}

export default ActiveBonuses

import omit from 'lodash/omit'
import { capitalizeFirstLetter } from './formatters'
import { DEPOSIT_LIMITS } from '../SignUp/Step3'

export const parseFirstName = (fullName) => {
    fullName = fullName.trim()
    const lastIndex = fullName.lastIndexOf(' ')
    return fullName.substring(0, lastIndex)
}

export const parseLastName = (fullName) => {
    const split = fullName.trim().split(' ')
    return split[split.length - 1]
}

export const getBirthday = (cpr) => {
    const day = cpr.substr(0, 2)
    const month = cpr.substr(2, 2)
    const year = cpr.substr(4, 2)
    return `${day}-${month}-19${year}`
}
export const getGender = (cpr) => {
    const lastLetter = cpr.slice(-1)
    if (lastLetter % 2 === 0) {
        return 'F'
    } else {
        return 'M'
    }
}

export const parseUserDataStep3 = (userData) => {
    const depositLimitConfig = DEPOSIT_LIMITS[userData.depositLimitType]

    return omit(
        {
            ...userData,
            // assign nulls in order to reset previously selected values
            [DEPOSIT_LIMITS.daily.APIPropertyKey]: null,
            [DEPOSIT_LIMITS.weekly.APIPropertyKey]: null,
            [DEPOSIT_LIMITS.monthly.APIPropertyKey]: null,
            // reassign specific selected deposit limit
            [depositLimitConfig.APIPropertyKey]: userData.depositLimit,
            stepsValidated: {
                ...userData.stepsValidated,
                step3: true
            }
        },
        ['rulesAccepted']
    )
}

const serverErrorMapping = {
    LASTNAMEISREQ: 'Det fulde navn er forkert'
}

export const parseError = (error) => {
    return typeof error === 'object'
        ? error.errorMessage
        : capitalizeFirstLetter(serverErrorMapping[error]) || error
}

export const defaultTheme = {
    name: 'default',
    condensed: true,
    bold: true,
    colors: {
        active: '#0bc628',
        bg: '#ffffff',
        basic: '#333',
        secondary: '#333333',
        link: '#575c69',
        borderColor: '#e9eaee',
        activeBtnColor: '#fbd600',
        disabledBtnColor: '#fff8cb',
        activeLoginColor: '#fbd600',
        disabledLoginColor: '#fff8cb',
        divider: '#e9eaee',
        bgDarker: '#eceff6',
        primary: '#575c69',
        primaryAction: '#9198aa',
    },
}

export const dark = {
    name: 'dark',
    condensed: false,
    bold: false,
    colors: {
        active: '#97D800',
        bg: '#121212',
        basic: '#fff',
        secondary: '#BEBEBE',
        link: '#F77705',
        borderColor: '#262626',
        activeBtnColor: '#F77705',
        disabledBtnColor: '#522200',
        activeLoginColor: '#97D800',
        disabledLoginColor: '#2C4000',
        divider: '#818181',
        bgDarker: '#262626',
        primary: '#BEBEBE',
        primaryAction: '#818181',
    },
}

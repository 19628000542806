import { useState } from 'react'

const argsSeparator = ':'

const check = (value, rules = []) =>
    !rules.some((rule) => {
        const validatorArgs = rule.split(argsSeparator)
        const ruleName = validatorArgs.shift()
        const validator = validators[ruleName]

        if (!validator) {
            throw Error(
                `Wrong rule: ${rule}. Allowed rules: ${Object.keys(validators)}`
            )
        }

        return !validator(value, ...validatorArgs)
    })

export default (defaultValue, rules = []) => {
    const [value, setValue] = useState(defaultValue)

    const setCheckedValue = (value) => {
        value =
            typeof value === 'object'
                ? value.target.value.toString()
                : value.toString()

        if (check(value, rules)) {
            setValue(value)
        }
    }

    return [value, setCheckedValue]
}

const validators = {
    numeric: (value) => value.match(/^\d*$/),
    maxNumber: (value, maximumNumber) => {
        if (isNaN(parseInt(value, 10)) || isNaN(parseInt(maximumNumber, 10))) {
            return true
        }
        return parseInt(value, 10) <= parseInt(maximumNumber, 10)
    },
    amount: (value, maxAmount) => {
        return (
            validators.numeric(value) && validators.maxNumber(value, maxAmount)
        )
    },
}

import { ENV } from '../configs/env'
import {
    API_URL,
    APP_NAME,
    getPlatformForApi,
    PRAGMATIC_CONFIG,
    PROVIDERS,
} from '../configs/main'
import GameDTO from './GameDTO'
import { get } from './FetchAdapter'
import { OPEN_HOURS_TYPE } from '../configs/categories'
import dgaAPI from './dgaAPI'
import LiveCasinoDTO from './LiveCasinoDTO'

const gameMapper = (game) => {
    if (game.name) return GameDTO.unserialize(game)
}

const getGamesByKey = (allGames) => {
    const games = allGames.map((el) => gameMapper(el))
    return games
}

const casinoMapper = (games) => {
    let mappedGames = games

    mappedGames.forEach((game) => {
        if (game.rod_hide === false) {
            game['liveCasinoPageCategories'] = []

            if (game.category === 'liveint' || game.category === 'livedk')
                game['liveCasinoPageCategories'].push('all')
            if (
                (game.category === 'liveint' || game.category === 'livedk') &&
                game.subcat === 'roulette'
            )
                game['liveCasinoPageCategories'].push('roulette')
            if (game.category === 'liveint' && game.subcat === 'blackjack')
                game['liveCasinoPageCategories'].push('blackJack')
            if (game.category === 'liveint' && game.subcat === 'baccarat')
                game['liveCasinoPageCategories'].push('baccarat')
            if (game.category === 'liveint' && game.subcat === 'poker')
                game['liveCasinoPageCategories'].push('poker')
        }
    })

    return mappedGames
        .filter((g) => g.rod_hide === false)
        .sort((a, b) => b.priority - a.priority || a.name.localeCompare(b.name))
}

export const getLiveTablesEvoAdapter = () => {
    return get(API_URL + 'get-live-tables').then((res) => {
        if (res && res.data) {
            return res.data
        }
    })
}
export const getLiveCasinoGamesAdapter = () => {
    return get(API_URL + 'categories/liveint/games', {
        platform: getPlatformForApi(),
        srv: ENV(),
        app: APP_NAME,
        'page[size]': 500,
    }).then((res) => {
        let mappedGames = casinoMapper(getGamesByKey(res.data))
        return mappedGames
    })
}

const setConnectionToPragmatic = (games) => {
    dgaAPI.connect(PRAGMATIC_CONFIG.server)
    dgaAPI.onConnect = function () {
        dgaAPI.available(PRAGMATIC_CONFIG.casinoId)

        games.map((game) => {
            if (game.provider === PROVIDERS.pragmatic && game.provider_id) {
                dgaAPI.subscribe(
                    PRAGMATIC_CONFIG.casinoId,
                    game.provider_id,
                    PRAGMATIC_CONFIG.currency
                )
            }
        })
    }
}

const disconnectFromPragmatic = () => {
    dgaAPI.disconnect()
}

const getPragmaticGames = (mappedGames, data) => {
    const withPragmaticLC = mappedGames.map((el) => {
        if (data.tableId === el.provider_id && data.tableOpen) {
            return {
                ...el,
                liveCas: LiveCasinoDTO.unseralize('pragmatic', data, el.subcat),
            }
        } else {
            return el
        }
    })

    return withPragmaticLC
}

const prepareListOfPragmaticGames = (mappedGames, pragmaticGames) => {
    let newArr = []
    mappedGames.forEach((game) => {
        if (
            game.provider === 'pragmatic' &&
            pragmaticGames.indexOf(game.provider_id)
        ) {
            newArr.push({
                ...game,
                liveCas: {},
            })
        } else {
            newArr.push({ ...game, liveCas: game.liveCas ? game.liveCas : {} })
        }
    })
    return newArr
}

export const getAllGames = (mappedGames, liveTableGames) => {
    let newArr = []

    mappedGames.forEach((game) => {
        if (game.provider === 'evolution') {
            Object.entries(liveTableGames).forEach(
                ([tableID, liveTableGame]) => {
                    if (
                        liveTableGame.open ||
                        (!liveTableGame.open &&
                            liveTableGame.operationHours === OPEN_HOURS_TYPE)
                    ) {
                        if (tableID === game.table_id) {
                            const newEl = {
                                ...game,
                                liveCas: LiveCasinoDTO.unseralize(
                                    'evolution',
                                    liveTableGame,
                                    game.subcat
                                ),
                            }
                            newArr.push(newEl)
                        }
                    }
                }
            )
        } else {
            newArr.push({ ...game, liveCas: game.liveCas ? game.liveCas : {} })
        }
    })
    return {
        evolutionGamesState: mappedGames,
        liveCasinoGames: newArr,
    }
}

export default {
    setConnectionToPragmatic: (games) => setConnectionToPragmatic(games),
    disconnectFromPragmatic: () => disconnectFromPragmatic(),
    getPragmaticGames: (mappedGames, pragmaticGame) =>
        getPragmaticGames(mappedGames, pragmaticGame),
    prepareListOfPragmaticGames: (games, callback) =>
        prepareListOfPragmaticGames(games, callback),
}

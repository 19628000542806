import { PROVIDERS } from '../configs/main'

const tableType = {
    blackjack: 'BLACKJACK',
    roulette: 'ROULETTE',
    baccarat: 'BACCARAT',
}

const imgSizeEvo = 'M'

class LiveCasinoDTO {
    thumb = ''
    open = false

    tableId = ''

    constructor(provider, game = {}, type = '') {
        this.thumb = this.setLiveImg(provider, game)

        this.workHours = {}

        this.gameSubType = ''
        this.privateTable = null //can be obj
        this.betLimits = { min: '', max: '' }

        if (PROVIDERS.pragmatic === provider) {
            this.open = game.tableOpen
            this.tableId = game.tableId || ''
            this.tableName = game.tableName || ''
            this.privateTable = null
            this.betLimits = {
                min: game.tableLimits?.minBet || 0,
                max: game.tableLimits?.maxBet || 0,
                symbol: 'kr.',
            }
            this.workHours = {
                type: 'Bounded',
                value: {
                    startTime: '14:00',
                    endTime: '21:00',
                },
            }
        } else if (PROVIDERS.evolution === provider) {
            this.open = game.open
            this.tableId = game.tableId || ''
            this.tableName = game.name || ''
            this.privateTable = game.privateTable || null
            this.betLimits = game.betLimits || {}
            this.operationHours = game.operationHours || ''
            this.players = game.players || 0

            this.workHours =
                game.workHours && Object.keys(game.workHours).length
                    ? game.workHours
                    : {}
        }

        if (type === 'blackjack') {
            this.unseralizeBlackjack(provider, game)
        } else if (type === 'baccarat') {
            this.unseralizeBaccarat(provider, game)
        } else if (type === 'roulette') {
            this.unseralizeRoulette(provider, game)
        }
    }

    static unseralize(provider, game, type) {
        return {
            ...new LiveCasinoDTO(provider, game, type),
        }
    }

    unseralizeBaccarat(provider, game) {
        this.history = []
        this.players = 0

        if (provider === 'pragmatic') {
            const types = {
                BANKER_WIN: 'B',
                PLAYER_WIN: 'P',
                TIE: 'T',
            }
            const gameResult = Array.isArray(game.gameResult)
                ? game.gameResult
                : []

            this.history = gameResult.map((res) => types[res.winner])
            this.players = game.totalSeatedPlayers || 0
        } else if (provider === 'evolution') {
            this.history = game.history || []
            this.players = game.players || 0
        }
    }

    unseralizeBlackjack(provider, game) {
        this.seats = null
        this.seatsTaken = []

        if (provider === 'pragmatic') {
            if (game.tableType === tableType.blackjack) {
                const arrSeatsTaken = [
                    game.seat1,
                    game.seat2,
                    game.seat3,
                    game.seat4,
                    game.seat5,
                    game.seat6,
                    game.seat7,
                ]
                const seatsIndices = arrSeatsTaken.reduce(
                    (acc, taken, index) => {
                        if (taken) {
                            acc.push(index)
                        }
                        return acc
                    },
                    []
                )

                this.seatsTaken = seatsIndices || []
                this.seats = arrSeatsTaken.length || null
            } else {
                this.players = game.totalSeatedPlayers
            }
        } else if (provider === 'evolution') {
            this.seats = game.seats || null
            this.seatsTaken = game.seatsTaken || []
        }
    }

    unseralizeRoulette(provider, game) {
        this.results = []
        this.players = 0

        if (provider === 'pragmatic') {
            const gameResult = Array.isArray(game.last20Results)
                ? game.last20Results
                : []

            this.results = gameResult.map((res) => res.result)
            this.players = game.totalSeatedPlayers || 0
            this.gameSubType = ''
        } else if (provider === 'evolution') {
            this.results = game.results || []
            this.players = game.players || 0
            this.gameSubType = game.gameSubType || ''
        }
    }

    setLiveImg(provider, game) {
        let imgPath = ''
        if (provider === PROVIDERS.evolution) {
            imgPath = (((game || {}).thumbs || {}).thumbnails || {})[imgSizeEvo]
                ? game?.thumbs?.thumbnails[imgSizeEvo]
                : ''
        } else if (provider === PROVIDERS.pragmatic) {
            imgPath = game?.tableImage || ''
        }
        return imgPath ? imgPath + '?t=' + new Date().getTime() : ''
    }
}

export default LiveCasinoDTO

import React, { Fragment, useEffect, useState } from 'react'
import styled from 'styled-components'
import { Text, Flex, Icon } from '@it25syv/25syv-ui'

import { ReactComponent as ArrowIcon } from '../SignUp/icons/back.svg'
import { DefaultBtn } from '../SignUp/styled'

const UserAvatar = styled.img`
    margin-top: 5px;
    vertical-align: middle;
    display: inline-block;
    height: 100px;
    width: 100px;
    border-radius: 50%;
    cursor: pointer;
    box-shadow: 0 3px 10px rgba(0, 0, 0, 0.7);
`

const NavButton = styled(DefaultBtn)`
    margin: 0 20px;

    @media screen and (max-width: 480px) {
        background: ${(props) => props.theme.colors.bgDarker};
    }
    &:hover {
        background: ${(props) => props.theme.colors.bgDarker};
    }
`

const avatarsCount = 147

const Avatar = ({ path }) => {
    const [curImage, setCurImage] = useState(1)

    const navigationClickHandler = (direction = 'next') => {
        let arr = Array.from({ length: avatarsCount }, (_, i) => i + 1) //fill in array [1, ..., n]
        let avatarIndex = parseInt(arr.indexOf(curImage), 10)

        let arrLength = arr.length
        let distance

        if (direction === 'prev') {
            distance = -1
        } else if ('next') {
            distance = 1
        }

        avatarIndex = (avatarIndex + distance + arrLength) % arrLength

        setCurImage(arr[avatarIndex])
        localStorage.setItem('avatar', arr[avatarIndex])
    }

    useEffect(() => {
        let avatar = localStorage.getItem('avatar')
        if (avatar) {
            setCurImage(avatar)
        }
    }, [])

    return (
        <div style={{ margin: '35px 0 0' }}>
            <Flex style={{ position: 'relative' }}>
                <NavButton onClick={() => navigationClickHandler('prev')}>
                    <ArrowIcon />
                </NavButton>
                <UserAvatar
                    src={path + curImage + '.png'}
                    onClick={() => navigationClickHandler('next')}
                />
                <NavButton onClick={() => navigationClickHandler('next')}>
                    <ArrowIcon style={{ transform: 'rotate(180deg)' }} />
                </NavButton>
            </Flex>
        </div>
    )
}

export default Avatar

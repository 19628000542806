import React, { Fragment, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { CenterBlock } from '../styled'
import { IMG_PATH } from '../configs/main'
import { BREAKPOINTS } from '../themes/breakpoints'

const CATEGORY_IMG_PATH = IMG_PATH + 'categories/'

const BannerWrapper = styled.div`
    position: relative;
    width: 100%;
    min-height: 250px;
    height: 35vw;
    overflow: hidden;
    max-height: 460px;

    background: ${(props) =>
        props.bgImage
            ? `url(${props.bgImage}) top/cover no-repeat`
            : `transparent linear-gradient(104deg, ${props.bgGradient.leftColor} 0%, ${props.bgGradient.rightColor} 100%) 0% 0% no-repeat padding-box`};

    @media (max-width: ${BREAKPOINTS.sm + 'px'}) {
        min-height: 150px;
    }
`

const BannerDesc = styled.div`
    margin: 50px 0 0 120px;
    float: left;
    transition: all 0.3s ease 0s;

    @media (max-width: ${BREAKPOINTS.md + 'px'}) {
        margin: 10px 0 0 30px;
    }

    @media (max-width: ${BREAKPOINTS.sm + 'px'}) {
        margin: 20px 0 0 30px;
    }

    svg {
        height: 130px;

        @media (max-width: ${BREAKPOINTS.sm + 'px'}) {
            height: 50px;
        }
    }
`

const BannerTitle = styled.h2`
    color: #fff;
    font-size: 66px;
    margin: 15px 0;

    @media (max-width: ${BREAKPOINTS.lg + 'px'}) {
        font-size: 48px;
        margin: 5px 0;
    }

    @media (max-width: ${BREAKPOINTS.md + 'px'}) {
        font-size: 36px;
        margin: 5px 0;
    }

    @media (max-width: ${BREAKPOINTS.sm + 'px'}) {
        font-size: 22px;
        margin: 10px 0;
    }
`

const BannerCount = styled.p`
    color: rgba(255, 255, 255, 0.5);
    font-size: 42px;
    margin: 0;
    transition: all 0.3s ease 0s;

    @media (max-width: ${BREAKPOINTS.lg + 'px'}) {
        font-size: 26px;
    }

    @media (max-width: ${BREAKPOINTS.md + 'px'}) {
        font-size: 22px;
    }

    @media (max-width: ${BREAKPOINTS.sm + 'px'}) {
        font-size: 14px;
    }
`

const BannerImg = styled.img`
    position: absolute;
    bottom: 0;
    right: 0;
    height: 95%;
    transition: all 0.3s ease 0s;

    @media (max-width: ${BREAKPOINTS.sm + 'px'}) {
        height: 90%;
    }
`

const Banner = ({ activeCategory, gamesCount = 0 }) => {
    const [category, setCategory] = useState({})

    useEffect(() => {
        setCategory(activeCategory)
    }, [activeCategory])
    return (
        <Fragment>
            {Object.keys(activeCategory).length && (
                <BannerWrapper
                    bgImage={
                        activeCategory.bgImage
                            ? IMG_PATH + activeCategory.bgImage
                            : ''
                    }
                    bgGradient={
                        Object.keys(activeCategory.bgGradient).length
                            ? activeCategory.bgGradient
                            : ''
                    }
                >
                    {activeCategory.name !== 'all' && (
                        <CenterBlock style={{ height: '100%' }}>
                            <BannerDesc>
                                {activeCategory.icon}
                                <BannerTitle>
                                    {activeCategory.label}
                                </BannerTitle>
                                <BannerCount>
                                    {gamesCount ? gamesCount : 'XX'} borde
                                </BannerCount>
                            </BannerDesc>
                            <BannerImg
                                src={CATEGORY_IMG_PATH + activeCategory.person}
                                alt={''}
                            />
                        </CenterBlock>
                    )}
                </BannerWrapper>
            )}
        </Fragment>
    )
}

Banner.propTypes = {
    activeCategory: PropTypes.object.isRequired,
    gamesCount: PropTypes.number,
}

export default Banner

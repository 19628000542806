import React from 'react'
import styled from 'styled-components'
import { Text } from '@it25syv/25syv-ui'

const LabelWrapper = styled.div`
  background-color: ${({ background }) => background || 'white'};
  width: ${({ width }) => width || 'auto'};
  height: 20px;
  line-height: 16px;
  padding: 0 5px;
  text-align: center;
  border-radius: 5px;
`
const StyledText = styled(Text)`
  font-style: italic;
  line-height: unset;
  font-family: 'Roboto', sans-serif;
  font-weight: 900;
`

const Label = ({ background, width, height, text, textColor }) => {
  return (
    <LabelWrapper background={background} width={width} height={height}>
      <StyledText
        size={'xs'}
        as={'span'}
        color={textColor || '#000'}
        align={'center'}
      >
        {text}
      </StyledText>
    </LabelWrapper>
  )
}

export default Label

import React, { useState, Fragment, useEffect } from 'react'
import propTypes from 'prop-types'
import styled, { css } from 'styled-components'
import LabeledInput from '../Input/LabeledInput'
import Spinner from '../Spinner'
import Icon, { Area } from '../Icon'
import Text from '../Text'
import arrow from '../../images/icons/chevronDown.svg'
import AnsvarligtSpil from '../../pages/AnsvarligtSpil'
import useFilteredState from '../../hooks/useFilteredState'
import { thousandsDots } from '../../utils/formatters'

const MAX_AMOUNT = 50000

const animation = css`
    transition: transform 400ms ease;
    ${({ toLeft }) => (toLeft ? `translateX(-100%)` : ``)}
    ${({ toRight }) => (toRight ? `translateX(100%)` : ``)}
`

const Wrapper = styled.div`
    margin: 0;
    display: flex;
    flex-direction: column;
    align-content: center;
    & > label {
        text-align: center;
    }
    ${animation};
`

const FormRow = styled.div`
    padding: 10px 0;
    display: flex;
    flex-direction: column;
    text-align: center;
`

const Divider = styled.div`
    width: 100%;
    height: 1px;
    border-bottom: 1px solid #efefef;
`

const Link = styled.span`
    text-align: center;
    cursor: pointer;
    font-weight: bold;
    font-family: Roboto Condensed;
    text-decoration: underline;
`

const DefaultLabel = styled(Text)`
    margin: 0;
    padding-bottom: 5px;
    text-align: center;
`

const FastDepositLimitForm = ({
    Input = LabeledInput,
    Label = DefaultLabel,
    MaxAmountButton,
    ConfirmationButton,
    texts,
    inputCSS,
    inputLabelCSS,
    showDivider = true,
    onConfirm,
    pending = false,
}) => {
    const [displayedText, setDisplayedText] = useState(texts.description)
    const showDescriptionText = () => setDisplayedText(texts.description)
    const showTermsCondsText = () => setDisplayedText(<AnsvarligtSpil />)

    const [termsCondsMode, setTermsCondsMode] = useState(false)
    const enableTermsCondsMode = () => {
        showTermsCondsText()
        setTermsCondsMode(true)
    }
    const disableTermsCondsMode = () => {
        showDescriptionText()
        setTermsCondsMode(false)
    }

    const [limit, setLimitDraft, limitDraftValid] = useFilteredState('', [
        `amount:${MAX_AMOUNT}`,
    ])
    const setLimit = (value) => setLimitDraft(value.replace(/\./g, ''))
    const [limitValid, setLimitValid] = useState(false)
    const setMaximumLimit = () => setLimitDraft(MAX_AMOUNT)

    useEffect(() => {
        setLimitValid(limit > 0 && limit <= MAX_AMOUNT)
    }, [limit])

    const confirm = () => onConfirm(limit)

    const renderIcon = () => (
        <Area onClick={disableTermsCondsMode}>
            <Icon src={arrow} size="sm" rotate={90} clickable />
        </Area>
    )

    return (
        <Wrapper>
            <Text size="xl" align="center" bold>
                {termsCondsMode ? (
                    <Fragment>
                        {renderIcon()}
                        {texts.termsCondsTitle}
                    </Fragment>
                ) : (
                    texts.title
                )}
            </Text>

            {showDivider && <Divider />}

            {termsCondsMode ? displayedText : <Text>{displayedText}</Text>}

            {termsCondsMode ? null : (
                <Fragment>
                    <FormRow>
                        <Label size="xs">{texts.inputLabel}</Label>
                        <Input
                            bdrs={1}
                            p={1}
                            h={40}
                            labelText={' kr.'}
                            inputCSS={inputCSS}
                            labelCSS={inputLabelCSS}
                            value={thousandsDots(limit)}
                            onChange={setLimit}
                            type={'tel'}
                        />
                    </FormRow>
                    <FormRow>
                        <Label size="xs" condensed className="maxamount-label">
                            {texts.maxAmountLabel}
                        </Label>
                        <MaxAmountButton onClick={setMaximumLimit}>
                            {texts.maxAmountBtn}
                        </MaxAmountButton>
                        <Label size="xs" className="confirm-label">
                            {texts.confirmBtnLabel}
                        </Label>
                    </FormRow>
                    <FormRow>
                        <ConfirmationButton
                            onClick={confirm}
                            disabled={!limitValid}
                        >
                            {pending ? <Spinner size="sm" /> : texts.confirmBtn}
                        </ConfirmationButton>
                    </FormRow>

                    <Text
                        color="#333333"
                        align="center"
                        size="md"
                        className="terms-and-conds"
                    >
                        {texts.readMore}
                        <Link onClick={enableTermsCondsMode} bold>
                            {texts.termsCondsLink}
                        </Link>
                    </Text>
                </Fragment>
            )}
        </Wrapper>
    )
}

FastDepositLimitForm.propTypes = {
    Input: propTypes.elementType,
    Label: propTypes.elementType,
    Button: propTypes.elementType,
    inputCSS: propTypes.array,
    inputLabelCSS: propTypes.array,
    texts: propTypes.object,
    onConfirm: propTypes.func,
    pending: propTypes.bool,
    termsAndCondsCSS: propTypes.array,
    showDivider: propTypes.bool,
}

FastDepositLimitForm.defaults = {
    texts: {
        description:
            'Du bedes indtaste en daglig indbetalinsgrænse for dit spil. Dette er et krav fra spillemyndigheden, og det er ikke muligt at spille hos os, uden du har sat en indbetalinsgrænse. Bemærk, at din indbetalingsgraænse er gældende på både Rød25, Derby25 og Bet25.',
        title: 'Title',
        termsCondsTitle: 'Terms&Conditions',
        readMore: 'Læs mere om ',
        termsCondsLink: 'terms&conditions',
        maxAmountLabel: 'Max amount label',
        confirmBtnLabel: 'Ready?',
        confirmBtn: 'Confirm',
        maxAmountBtn: 'Set maximum',
    },
    onConfirm: () => {},
    pending: false,
}

export default FastDepositLimitForm

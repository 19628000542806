import { detectIpad, detectMobile } from '../utils/deviceDetect'
import { IMG_PATH } from './main'

import { ReactComponent as EvolutionIcon } from '../images/icons/evolution-icon.svg'
import { ReactComponent as AuthenticIcon } from '../images/icons/authentic-icon.svg'
import { ReactComponent as BaccaratIcon } from '../images/icons/baccarat.svg'
import { ReactComponent as BlackjackIcon } from '../images/icons/blackjack.svg'
import { ReactComponent as PokerIcon } from '../images/icons/poker.svg'
import { ReactComponent as RouletteIcon } from '../images/icons/roulette.svg'
import React from 'react'
import { ENV } from './env'

export const DANSK_ROULETTE_ID = detectMobile() && !detectIpad() ? 3265 : 2101
export const DOUBLE_BALL_ROULETTE = 'double_ball_roulette'

export const OPEN_HOURS_TYPE = 'Bounded'

const providersThumbsImgs = {
    dkRouletteEvo: 'dansk-roulette.jpeg',
    dkRouletteAG: 'arhus-roulette.jpeg',
    evoLobby: 'evolution-lobby.jpeg',
    AGLobby: 'authentic-lobby.jpeg',
}

export const PROVIDERS_LOGOS = {
    evolution: <EvolutionIcon />,
    authentic: <AuthenticIcon />,
}

export const PROVIDERS_THUMBS = [
    {
        gameId: ENV() === 'dev' ? 3191 : DANSK_ROULETTE_ID,
        title: {
            topTitle: 'Dansk',
            middleTitle: 'Roulette',
            bottomTitle: '',
            titlePosition: 'right',
        },
        bg: IMG_PATH + providersThumbsImgs.dkRouletteEvo,
        showFlag: true,
        providerLogo: PROVIDERS_LOGOS.evolution,
        providerName: 'evolution',
        providerKey: 'evolution',
        players: true,
        betLimits: true,
        showTimer: true,
    },
    {
        gameId: 3188, //for testing
        title: {
            topTitle: 'Dansk',
            middleTitle: 'Roulette',
            bottomTitle: '',
            titlePosition: 'right',
        },
        bg: IMG_PATH + providersThumbsImgs.dkRouletteAG,
        showFlag: true,
        providerLogo: PROVIDERS_LOGOS.authentic,
        providerName: 'authentic',
        providerKey: 'authentic',
        players: true,
        betLimits: true,
        showTimer: true,
    },
    {
        gameId: '', //for test
        title: {
            topTitle: 'Lobby',
            middleTitle: 'Live Casino',
            bottomTitle: 'åbne borde',
            titlePosition: 'left',
        },
        bg: IMG_PATH + providersThumbsImgs.evoLobby,
        showFlag: false,
        providerLogo: PROVIDERS_LOGOS.evolution,
        providerName: 'evolution',
        providerKey: 'evolution',
        players: false,
        betLimits: false,
        showTimer: false,
    },
    {
        gameId: '',
        title: {
            topTitle: 'Lobby',
            middleTitle: 'Authentic',
            bottomTitle: 'åbne borde',
            titlePosition: 'left',
        },
        bg: IMG_PATH + providersThumbsImgs.AGLobby,
        showFlag: false,
        providerLogo: PROVIDERS_LOGOS.evolution,
        providerName: 'authentic',
        providerKey: 'authentic',
        players: false,
        betLimits: false,
        showTimer: false,
    },
]

export const LIVE_CASINO_CATEGORIES = [
    {
        name: 'all',
        label: 'Alle',
        url: 'alle',
        icon: null,
        bgImage: 'banner-bg.jpeg',
        bgGradient: {},
        person: '',
    },
    {
        name: 'roulette',
        label: 'Roulette',
        url: 'roulette',
        icon: <RouletteIcon />,
        bgImage: '',
        bgGradient: { leftColor: '#206F47', rightColor: '#103824' },
        person: 'roulette.png',
    },
    {
        name: 'blackJack',
        label: 'Blackjack',
        url: 'blackJack',
        icon: <BlackjackIcon />,
        bgImage: '',
        bgGradient: { leftColor: '#6F2048', rightColor: '#381024' },
        person: 'blackjack.png',
    },
    {
        name: 'baccarat',
        label: 'Baccarat',
        url: 'baccarat',
        icon: <BaccaratIcon />,
        bgImage: '',
        bgGradient: { leftColor: '#6F4720', rightColor: '#382410' },
        person: 'baccarat.png',
    },
    {
        name: 'poker',
        label: 'Poker',
        url: 'poker',
        icon: <PokerIcon />,
        bgImage: '',
        bgGradient: { leftColor: '#20486F', rightColor: '#102438' },
        person: 'poker.png',
    },
]

import React from 'react'
import Icon from '../../Icon'
import Text from '../../Text'
import calendarIcon from '../../../images/icons/calendar.svg'
import { withTheme } from 'styled-components'
import { ButtonsArea, StyledButton } from './styled'
import { detectMobile } from '../../../utils/deviceDetect'

const ButtonArea = ({
    theme,
    openChooseDate = () => {},
    showClearButton = false,
    clearChosenDate = () => {},
    chosenDate = '',
    buttonsAreaPosition = null,
}) => {
    return (
        <ButtonsArea
            position={
                buttonsAreaPosition !== null ? buttonsAreaPosition : 'absolute'
            }
            zIndex={13}
        >
            {showClearButton && (
                <StyledButton
                    borderColor={theme.colors.stroke_400}
                    bgColor={theme.colors.bg_200}
                    onClick={clearChosenDate}
                >
                    <Text bold color={theme.colors.red} align={'center'}>
                        Nulstil
                    </Text>
                </StyledButton>
            )}
            <StyledButton maxWidth={'320px'} onClick={openChooseDate}>
                <Icon
                    height={'16px'}
                    width={'16px'}
                    src={calendarIcon}
                    color={'black'}
                />
                <Text bold color={theme.colors.black_font_900} align={'center'}>
                    {chosenDate.length && showClearButton > 0
                        ? chosenDate
                        : 'Vælg periode'}
                </Text>
                {/*{btnLoading[LOGIN_TYPES.MIT_ID] && (*/}
                {/*    <Spinner color={'#fff'} size={'md'} />*/}
                {/*)}*/}
                {/*test commit*/}
            </StyledButton>
        </ButtonsArea>
    )
}

ButtonsArea.propTypes = {}

export default withTheme(ButtonArea)

export const ForgotPasswordText = {
    password: {
        title: 'Hjælp til at logge ind?',
        dontPanic: 'Ingen panik!',
        dontPanicDescription: 'Følg trinene nedenfor for at gendanne adgangen til din konto.',
        step1Title: 'Log ind med MitID.',
        step1Description: 'Brug dit MitID for sikkert at verificere din identitet.',
        step2Title: 'Vælg et nyt kodeord.',
        step2Description: 'Efter verifikation bliver du guidet til at vælge et nyt kodeord.',
    },
    pin: {
        title: 'Hjælp til at logge ind?',
        dontPanic: 'Ingen panik!',
        dontPanicDescription: 'Følg trinene nedenfor for at gendanne adgangen til din konto.',
        step1Title: 'Log ind med MitID.',
        step1Description: 'Brug dit MitID for sikkert at verificere din identitet.',
        step2Title: 'Vælg en ny PIN kode.',
        step2Description: 'Efter verifikation bliver du guidet til at vælge en ny PIN kode.',
    },
}

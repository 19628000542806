import styled from 'styled-components'
import { detectMobile } from '../../utils/deviceDetect'

export const Wrapper = styled.div`
    height: ${({ height }) => `${height}` || 'auto'};
    width: 100%;
    display: flex;
    flex-direction: column;
    position: relative;
    padding: ${({ padding }) => `${padding}` || ' 0 24px 70px'};
    overflow-y: ${({ overflowY }) => `${overflowY}` || 'auto'};
    justify-content: ${({ justify }) => `${justify}` || 'flex-start'};
    box-sizing: border-box;
`

export const ChooseDatePageWrapper = styled.div`
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: ${detectMobile() ? 'flex-end' : 'center'};
    box-sizing: border-box;
    background: rgba(0, 0, 0, 0.5);
    position: fixed;
    bottom: 0;
    left: 0;
    z-index: 13;
`
export const ChooseDateWrapper = styled.div`
    height: auto;
    width: ${detectMobile() ? '100%' : 'auto'} ;
    border-radius: ${detectMobile() ? '8px 8px 0 0' : ' 8px'};
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    box-sizing: border-box;
    background-color: ${(props) => `${props.bgColor}` || props.theme.colors.bg};
    position: relative;
    z-index: 14;
`

export const HR = styled.div`
    display: block;
    width: 100%;
    box-sizing: border-box;
    border-top: ${({ height, type, color }) =>
        `${height} ${type} ${color}` || '1px solid #DFE5ED'};
`
export const DateRangeWrapper = styled.div`
    height: ${detectMobile() ? 'calc(100% - 130px)' : 'calc(100% - 134px)'} ;
    width: 100%;
    padding: 0 16px;
    display: flex;
    flex-direction: row;
    align-items: ${detectMobile() ? 'center' :'center'};
    justify-content: space-between;
    box-sizing: border-box;
    margin: ${({ margin }) => `${margin}` || '0'};
`

export const WrapperCloseIcon = styled.div`
    display: block;
    box-sizing: border-box;
    position: absolute;
    right: 16px;
    top: 16px;
    z-index: 6;
`
export const DateRangeItem = styled.div`
    display: block;
    width: ${detectMobile ? 'calc(50% - 8px)' : '100%'};
    box-sizing: border-box;
`
export const DateRangeInput = styled.div`
    height: auto;
    width: 100%;
    margin: 5px 0 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 16px;
    border-radius: 8px;
    background-color: ${({ bgColor }) => `${bgColor}` || '#fff'};
    border: 1px solid ${({ borderColor }) => `${borderColor}` || '#DFE5ED'};
    box-sizing: border-box;
    cursor: pointer;
    position: relative;
    z-index: 12;
`

export const DatePickerPage = styled.div`
    height: auto;
    width: 100%;
    display: block;
    box-sizing: border-box;
    padding: 0;
    overflow: hidden;
    border-radius: 8px;
`

export const DatePickerWrapper = styled.div`
    overflow: hidden;
    margin: 0;
    padding: 0;
    border-radius: ${detectMobile() ? '8px 8px 0 0' : '8px'}; ;
    background-color: ${(props) => `${props.bgColor}` || 'none'};
    li.Cal__Day__root {
        height: 100%;
    }

    .Cal__Day__month {
        top: 11px;
    }

    .Cal__Today__root .Cal__Today__chevron {
        position: relative;
        top: 28%;
    }
`

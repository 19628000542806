import styled, { css, keyframes } from 'styled-components'
import ValidInput from '../ValidInput'

export const slideToTop = keyframes`
    0% { transform: translateY(100%); }
    100% { transform: translateY(0%); }
`
export const slideToLeft = keyframes`
    0% { transform: translateX(100%); }
    100% { transform: translateX(0%); }
`

export const animationWindow = css`
    animation-timing-function: cubic-bezier(0.1, -0.6, 0.2, 0);
    animation-fill-mode: forwards;
    animation-direction: ${({ close }) => (close ? 'reverse' : 'normal')};
`

export const Wrapper = styled.div`
    z-index: ${({ zIndex }) => zIndex || '10'};
    margin: 0;
    height: 100%;
    width: 100%;
    box-sizing: border-box;
    background: ${({ bg }) => bg || 'white'};
    display: flex;
    flex-direction: column;
    animation: ${slideToTop} 300ms;
    ${animationWindow};

    @media screen and (min-width: 992px) {
        animation: ${slideToLeft} 300ms;
        ${animationWindow};
        position: absolute;
        right: 0;
        width: 400px;
    }
    @media screen and (min-width: 376px) {
        background: ${({ bg }) => bg || 'white'};
    }
    @media screen and (min-width: 320px) and (max-width: 900px) and (orientation: landscape) {
        overflow: auto;
        background: ${({ bg }) => bg || 'white'};
    }
`
export const ModalTopBar = styled.div`
    margin: 0;
    background: none;
    padding: 0;
    display: flex;
    justify-content: center;
    border-bottom: none;
    align-items: center;
    position: relative;
`

export const StyledModalTopbar = styled(ModalTopBar)`
    padding: 25px;
    border-bottom: 2px solid ${({ color }) => color || '#e9eaee'};
`
export const MobileModalTopBar = styled(ModalTopBar)`
    position: fixed;
    width: calc(100vw - 30px);
    top: 15px;
    left: 15px;
    z-index: 2;
`

export const FlexContainer = styled.div`
    display: flex;
    flex-flow: row;
    align-items: center;
    flex: 1 1 auto;
    cursor: auto;
    width: auto;
    height: auto;
    flex-direction: row;
    justify-content: flex-end;
`
export const CloseModalButton = styled.div`
    width: 45px;
    height: 45px;

    display: flex;
    align-content: center;
    justify-content: space-around;
    align-items: center;
    border-radius: 5px;
    cursor: pointer;

    &:hover {
        background-color: ${({ bgHover }) => bgHover || '#eceff6'};
        box-shadow: 4px 4px 8px 0 rgba(34, 60, 80, 0.2);
    }
`
export const OpretBtn = styled.button`
    line-height: 1;
    border: 1px solid transparent;
    border-radius: 5px;
    transition: all 0.3s ease-out;
    text-transform: none;
    cursor: pointer;
    background: ${({ activeColor }) => activeColor || '#fbd600'};
    color: ${({ color }) => color || '#333333'};
    font-weight: bold;
    display: inline-block;
    font-size: 14px;
    margin: 0 0 0 auto;
    font-family: ${({ condensed }) =>
            condensed ? 'Roboto Condensed' : 'inherit'},
        sans-serif;
    padding: 15px 31px 14px;
    box-shadow: 4px 4px 8px 0 rgba(34, 60, 80, 0.2);
`
export const MiddleWrapper = styled.div`
    padding: 45px 25px 115px;
    flex: 1;
    color: ${({ color }) => color || '#333'};
    overflow-y: scroll;
    //hide scrollbar
    scrollbar-width: none;
    -ms-overflow-style: none;
    &::-webkit-scrollbar {
        width: 0;
        height: 0;
    }

    @media screen and (min-width: 320px) and (max-width: 900px) and (orientation: landscape) {
        margin-bottom: 70px;
    }
`

export const ForgotPassWrapper = styled(MiddleWrapper)`
    > h3 {
        font-size: 24px;
        padding: 0 0 8px;
        margin: 0;
    }
`

export const FakePlaceholder = styled.span`
    position: absolute;
    font-size: 14px;
    color: ${({ color }) => color || '#575c69'};
    top: -27px;
    left: 8px;
    font-family: ${({ condensed }) =>
            condensed ? 'Roboto Condensed' : 'inherit'},
        sans-serif;
`
export const InputsWrapper = styled.div`
    position: relative;
`
export const InputWrapper = styled.div`
    position: relative;
    margin: 0 auto;
    &:first-child {
        margin-bottom: 50px;
    }
`
export const InputField = styled(ValidInput)`
    padding: 17px 45px 17px 16px;
    border-radius: 5px;
    width: 100%;
    box-sizing: border-box;
    background: ${({ bgColor }) => bgColor || '#eceff6'};
    position: relative;
    color: ${({ color }) => color || '#575c69'};
    opacity: 1;
    font-size: 18px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    -webkit-text-security: none;
    font-family: ${({ condensed }) =>
            condensed ? 'Roboto Condensed' : 'inherit'},
        sans-serif;
    display: flex;
    height: 57px;
    margin: 0 auto;
    outline: none;

    input {
        top: 0;
        width: 100%;
        height: 100%;
        position: absolute;
        left: 0;
        font-family: ${({ condensed }) =>
                condensed ? 'Roboto Condensed' : 'inherit'},
            sans-serif;
        &:-webkit-autofill,
        &:-webkit-autofill:hover,
        &:-webkit-autofill:focus,
        &:-webkit-autofill:active {
            -webkit-box-shadow: 0 0 0 60px #eceff6 inset !important;
            background-color: #eceff6 !important;
            background-clip: content-box !important;
        }
    }
`
export const ForgetPassword = styled.p`
    margin: 0;
    position: absolute;
    top: -27px;
    right: 8px;
    font-size: 14px;
    font-family: ${({ condensed }) =>
            condensed ? 'Roboto Condensed' : 'inherit'},
        sans-serif;
    color: ${({ color }) => color || '#575c69'};
    text-decoration: underline;
    cursor: pointer;
`
export const LoginButton = styled.button`
    position: relative;
    height: 54px;
    width: 100%;
    border-radius: 5px;
    outline: none;
    border: none;
    font-size: 16px;
    font-weight: bold;
    font-family: 'Roboto Condensed', sans-serif;
    transition: all 0.3s ease;
    background: ${({ activeColor }) => activeColor || '#fbd600'};
    border: ${({ borderColor }) =>
        borderColor ? '1px solid ' + borderColor : '1px solid transparent'};
    cursor: pointer;

    ${({ hide }) =>
        hide &&
        css`
            padding: 0;
            flex: 0;
            border: none;
            margin: 0 !important;
        `} &:disabled {
        background: ${({ disableColor }) => disableColor || '#fff8cb'};
    }

    &:not(:disabled) {
        p {
            color: ${({ color }) => color || '#333333'};
        }
    }
`
export const LoginButtonWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    position: relative;
    margin-top: 15px;
    flex-direction: column;
    gap: 10px;
`

export const LoginLabel = styled.p`
    animation: ${(props) =>
        props.loading ? props.loading + ' 1.2s infinite 0s ease-in-out' : ''};
    animation-direction: alternate;
    text-shadow: 0 0 1px white;
    margin: 0;
    font-family: ${({ condensed }) =>
            condensed ? 'Roboto Condensed' : 'inherit'},
        sans-serif;
    font-weight: ${({ normal }) => (normal ? 'normal' : 'bold')};
`

export const TextWrapper = styled.div`
    font-size: 12px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    width: 100%;

    p {
        margin: 0;
    }
`

export const ButtonsArea = styled(LoginButtonWrapper)`
    width: 100%;
    position: absolute;
    flex-direction: column;
    bottom: 0;
    align-items: center;
    justify-content: center;
    margin: 0;
    background: ${({ bg }) => bg || '#fff'};
`

export const AcceptButton = styled(LoginButton)`
    display: flex;
    align-items: center;
    justify-content: center;

    p + svg {
        position: absolute;
        right: 20px;
    }
`

export const ButtonWrapper = styled.div`
    padding: 15px 0 25px;
    width: calc(100% - 50px);
`

export const MitIdButton = styled(LoginButton)`
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: 100%;
    border: 1px solid #0000001a;
    background-color: #2163de;
    gap: 10px;

    p + svg {
        position: absolute;
        right: 20px;
    }

    ${({ hide }) =>
        hide &&
        css`
            padding: 0;
            flex: 0;
            border: none;
        `}
    }
`

export const Divider = styled.div`
    height: 1px;
    background-color: ${({ color }) => color || '#e9eaee'};
    flex: 1;
`

import React from 'react'
import {
    BottomDropDown,
    DoubleRouletteCircle,
    DoubleRouletteMerged,
    RouletteCircle,
} from './styled'
import { DOUBLE_BALL_ROULETTE } from '../../configs/categories'

const rouletteColors = {
    green: [0],
    red: [1, 3, 5, 7, 9, 12, 14, 16, 18, 19, 21, 23, 25, 27, 30, 32, 34, 36],
    black: [2, 4, 6, 8, 10, 11, 13, 15, 17, 20, 22, 24, 26, 28, 29, 31, 33, 35],
}

const Roulette = ({ liveCasinoData, active, privateTable }) => {
    const betResults = liveCasinoData.results !== null && liveCasinoData.results
    const gameSubType = liveCasinoData.gameSubType

    const resolveRouletteColor = (number) => {
        return Object.keys(rouletteColors).find((el) =>
            rouletteColors[el].includes(number)
        )
    }

    const splitDoubleBall = (number = '', position = 0) => {
        const slitedArray = number.split('|')
        return slitedArray[position]
    }

    const resolveDoubleRouletteColor = (number = '', position) => {
        return isDouble() && isEqualNumbers(number)
            ? null
            : resolveRouletteColor(+splitDoubleBall(number, position))
    }

    const isDouble = () => DOUBLE_BALL_ROULETTE === gameSubType

    const isEqualNumbers = (number) =>
        splitDoubleBall(number) === splitDoubleBall(number, 1)

    const isAllowedData = () =>
        betResults.length &&
        (typeof betResults[0] === 'number' || typeof betResults[0] === 'string')

    return isAllowedData() ? (
        <BottomDropDown
            active={active}
            extended={DOUBLE_BALL_ROULETTE === gameSubType}
            gameSubType={gameSubType}
            privateTable={privateTable}
        >
            {betResults.map((number, index) => {
                return isDouble() ? (
                    <DoubleRouletteCircle key={index}>
                        <DoubleRouletteMerged equal={isEqualNumbers(number)}>
                            <RouletteCircle
                                color={resolveDoubleRouletteColor(number, 0)}
                                border={index < 1}
                                double={isDouble()}
                            >
                                {splitDoubleBall(number)}
                            </RouletteCircle>
                            <RouletteCircle
                                color={resolveDoubleRouletteColor(number, 1)}
                                border={index < 1}
                                key={index}
                                double={isDouble()}
                            >
                                {splitDoubleBall(number, 1)}
                            </RouletteCircle>
                        </DoubleRouletteMerged>
                    </DoubleRouletteCircle>
                ) : (
                    <RouletteCircle
                        color={resolveRouletteColor(+number)}
                        border={index < 1}
                        key={index}
                    >
                        {number}
                    </RouletteCircle>
                )
            })}
        </BottomDropDown>
    ) : null
}

export default Roulette

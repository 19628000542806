import React from 'react'
import styled, { withTheme } from 'styled-components'
import { ButtonsArea, StyledButton } from './styled'

const CancelButton = styled(StyledButton)`
    max-width: calc(50% - 8px);
    background: ${(props) => props.theme.colors.bg_200};
`

const AcceptButton = styled(StyledButton)`
    max-width: calc(50% - 8px);

    &:disabled {
        opacity: 0.5;
    }
`

const AcceptAndCancelButtons = ({
    theme,
    cancelAction = () => {},
    acceptAction = () => {},
    disableAcceptButton = false,
    cancelLabel = 'Annuller',
    acceptLabel = 'Vis',
    position = 'absolute',
    borderRadius,
}) => {
    return (
        <ButtonsArea position={position} borderRadius={borderRadius}>
            <CancelButton
                borderColor={theme.colors.stroke_400}
                onClick={cancelAction}
            >
                {cancelLabel}
            </CancelButton>
            <AcceptButton
                borderColor={theme.colors.activeBtnColor}
                onClick={acceptAction}
                disabled={disableAcceptButton}
            >
                {acceptLabel}
            </AcceptButton>
        </ButtonsArea>
    )
}

AcceptAndCancelButtons.propTypes = {}

export default withTheme(AcceptAndCancelButtons)

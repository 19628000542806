import styled, { css, keyframes } from 'styled-components'
import { CloseModalButton, LoginButton, ModalTopBar } from '../Login/styled'

export const slideToLeft = keyframes`
    0% { transform: translateX(100%); }
    100% { transform: translateX(0%); }
`
export const Wrapper = styled.div`
    width: 100%;
    height: 100%;
    position: absolute;
    right: 0;
    background-color: ${({ backgroundColor }) =>
        backgroundColor ? backgroundColor : 'white'};
    animation: ${slideToLeft} 300ms;
    animation-timing-function: cubic-bezier(0.1, -0.6, 0.2, 0);
    animation-fill-mode: forwards;
    animation-direction: ${({ close }) => (close ? 'reverse' : 'normal')};
    @media screen and (min-width: 992px) {
        width: 400px;
    }
`
export const StyledModalTopbar = styled(ModalTopBar)`
    padding: 25px;
    border-bottom: 2px solid ${({ color }) => color || '#e9eaee'};
    margin-bottom: 25px;
`
export const CloseButton = styled(CloseModalButton)`
    position: absolute;
    right: 15px;
    cursor: pointer;
    display: flex;
    align-content: center;
    background-color: transparent;
    justify-content: space-around;
    align-items: center;
    transition: all 0.3s ease-in-out;
    box-shadow: none;
    &:hover {
        background-color: #eceff6;
    }
`
export const Title = styled.span`
    color: ${({ color }) => color || '#333333'};
    font-size: 18px;
    font-weight: 900;
    font-family: ${({ condensed }) =>
            condensed ? 'Roboto Condensed' : 'inherit'},
        sans-serif;
    line-height: normal;
`
export const MethodWrapper = styled.div`
    padding: 0 15px;
`
export const StyledLoginButton = styled(LoginButton)`
    width: 100%;
    transition: all 0.3s ease-out;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
    margin-top: ${({ mitId }) => (mitId ? '25px' : '15px')};

    background: ${({ mitId }) => (mitId ? '#1a49d5' : '#e5ebf5')};
    border: ${({ mitId }) =>
        mitId ? '1px solid #1a49d5' : '1px solid #caced6'};
    ${({ hide }) =>
        hide &&
        css`
            opacity: 0;
            height: 0;
            border: 0;
        `} p + svg {
        position: absolute;
        right: 0;
    }
    &:hover {
        background: ${({ mitId }) => (mitId ? '#1841ba' : '#caced6')};
    }

    &:not(:disabled) {
        p {
            color: ${({ mitId }) => (mitId ? '#fff' : '#333')};
        }
    }
`

export const ButtonContent = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.3s ease-in-out;

    svg {
        width: 17%;
        margin-right: 10px;
    }
`

export const LoginWrapper = styled.div`
    margin-top: 50px;
`

import React from 'react'
import Icon from './Icon'
import styled, { keyframes } from 'styled-components'
import spinner from '../images/icons/spinner.png'
import spacing from '../utils/spacing'
import sizing from '../utils/sizing'

const rotate360 = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
`

const Spinner = styled(Icon)`
    display: inline-block;
    position: relative;
    ${spacing};
    ${sizing};
    border-radius: 50%;
    animation: ${rotate360} 1.2s linear infinite;
`

export default ({ size, color, m }) => (
    <Spinner m={m} src={spinner} size={size} scaleOfSize={2} color={color} />
)

import React from 'react'
import styled from 'styled-components'
import { Text } from '@it25syv/25syv-ui'

const Wrapper = styled.div`
    width: 100%;
    box-sizing: border-box;
`
const StepsWrapper = styled.div`
    display: flex;
    justify-content: space-around;
    align-items: center;
`
const ProgressLine = styled.div`
    width: 100%;
    display: block;
    box-sizing: border-box;
    border-radius: 7px;
    background: ${(props) => props.theme.colors.bgDarker};
    padding: 3px 4px;
    div {
        height: 6px;
        border-radius: 3px;
        width: ${({ progress = 5 }) => progress}%;
        background: ${({ progressColor = '#FFD401' }) => progressColor};
        transition: all 0.3s ease;
    }
`

const Stepper = ({
    currentStep = 1,
    steps = [],
    progressColor = '#FFD401',
    selectStep,
    submitHandlerStep3,
    theme
}) => {
    const getProgressLength = (currentStep, length) =>
        (currentStep / length) * 100

    const toStep = (step) => {
        if (step === 4) {
            submitHandlerStep3(() => selectStep(step))
        } else {
            selectStep(step)
        }
    }

    return (
        <Wrapper>
            <StepsWrapper>
                {steps.map((step, index) => (
                    <Text
                        padding='7px 0 14px'
                        center
                        key={index}
                        color={
                            currentStep === index + 1
                                ? theme.colors.basic
                                : theme.colors.primaryAction
                        }
                        decoration={
                            currentStep > index + 1 ? 'line-through' : 'none'
                        }
                        style={{
                            cursor: 'pointer',
                            margin: 0,
                            fontWeight: theme.fontWeight,
                            fontSize: '14px'
                        }}
                        onClick={() => toStep(index + 1)}
                    >
                        {step}
                    </Text>
                ))}
            </StepsWrapper>
            <ProgressLine
                progress={getProgressLength(currentStep, steps.length)}
                progressColor={progressColor}
            >
                <div />
            </ProgressLine>
        </Wrapper>
    )
}

export default Stepper

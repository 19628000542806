import React, { useEffect, useState } from 'react'
import ReactDOM from 'react-dom'
import PropTypes from 'prop-types'
import {
  DefaultMenuItems,
  DragLabel,
  DragLabels,
  MenuItem,
  MenuItemIcon,
  MenuItems,
  MenuItemWrapper,
  MenuWrapper,
  TextWrapper,
  Title,
  TitleWrapper
} from './styled'
import { Text } from '@it25syv/25syv-ui'
import { ReactComponent as NytLogo } from '../images/25-nyt.svg'
import { ReactComponent as MessageLogo } from '../images/message.svg'
import Draggable from 'react-draggable'
import ArrowLogo from '../SvgComponents/ArrowLogo'
import Label from '../ui/Label'

const height = window.screen.height
const offsetTop = 40
const dragLabelDeg = 15
const moveGap = 70
const topBound = 20

const Menu = ({
  configs,
  background,
  secondaryColor,
  isOpen,
  onClose,
  initialMenuPosition = height * 0.3,
  zIndexWrapper,
  MenuItemsColor
}) => {
  const initialHeight = window.innerHeight - initialMenuPosition
  const [fullTop, setFullTop] = useState(false)
  const [controlledPosition, setControlledPosition] = useState({
    x: 0,
    y: height
  })
  const [transition, setTransition] = useState(true)
  const [labelRotateDeg, setLabelRotateDeg] = useState(0)
  const [lockScroll, setLockScroll] = useState(false)
  const [halfHeight, setHalfHeight] = useState(initialHeight)

  useEffect(() => {
    setHalfHeight(window.innerHeight - initialMenuPosition)
  }, [window.innerHeight])

  useEffect(() => {
    setHalfHeight(window.innerHeight - initialMenuPosition)
  }, [initialMenuPosition])

  const menuComponent = document.getElementById('bottom-menu-dropdown')
  const menuRef = ReactDOM.findDOMNode(menuComponent)
  const $body = document.querySelector('body')

  const { campaignAction, intercomAction, menuItems } = configs

  useEffect(() => {
    if (isOpen) {
      $body.style.overflow = 'hidden'
    } else {
      $body.style.removeProperty('overflow')
      handleClose()
    }
    setControlledPosition({ x: 0, y: isOpen ? halfHeight : height })
  }, [isOpen])

  useEffect(() => {
    if (menuRef) {
      menuRef.addEventListener('scroll', handleScroll)

      return () => {
        menuRef.removeEventListener('scroll', handleScroll)
      }
    }
  }, [menuRef])

  const handleScroll = (e) => {
    const scrollTop = e.target.scrollTop
    if (scrollTop === 0) {
      setLockScroll(true)
    }
  }

  const handleClose = () => {
    setFullTop(false)
    setLabelRotateDeg(0)
    onClose()
  }

  const handleFullSize = () => {
    setFullTop(true)
    setControlledPosition({ x: 0, y: offsetTop })
    setLabelRotateDeg(dragLabelDeg)
    if (menuRef) {
      setLockScroll(menuRef.clientHeight >= menuRef.scrollHeight)
    }
  }

  const handleStart = () => {
    setTransition(false)
  }

  const handleDrag = (e, ui) => {
    setControlledPosition({ x: ui.x, y: ui.y })
  }

  const handleStop = (e, ui) => {
    setLockScroll(false)

    setTransition(true)

    const offsetY = halfHeight - ui.y
    if (
      (Math.abs(offsetY) < 20 || (ui.y - offsetTop < 20 && fullTop)) &&
      e.target.closest('.click-handler')
    ) {
      e.target.closest('.click-handler').click()
    } else if (offsetY > moveGap) {
      handleFullSize()
    } else if (offsetY < -moveGap) {
      handleClose()
    } else {
      if (fullTop) {
        handleClose()
      } else {
        setControlledPosition({ x: 0, y: halfHeight })
        setLabelRotateDeg(0)
        setFullTop(false)
      }
    }
  }

  return (
    <Draggable
      handle='.handle'
      position={controlledPosition}
      bounds={{ top: topBound, left: 0, right: 0 }}
      onStart={handleStart}
      onDrag={handleDrag}
      onStop={handleStop}
    >
      <MenuWrapper
        background={background}
        transition={transition}
        zIndexWrapper={zIndexWrapper}
      >
        <TitleWrapper className='handle'>
          <DragLabels>
            <DragLabel color={secondaryColor} deg={labelRotateDeg} />
            <DragLabel color={secondaryColor} deg={labelRotateDeg} />
          </DragLabels>
          <Title color='white' align='center'>
            Menu
          </Title>
        </TitleWrapper>
        <MenuItems
          id={'bottom-menu-dropdown'}
          fullTop={fullTop}
          isOpen={isOpen}
          className={!fullTop || lockScroll ? 'handle' : ''}
        >
          <DefaultMenuItems>
            <MenuItemWrapper MenuItemsColor={MenuItemsColor}>
              <MenuItem
                className={'click-handler'}
                onClick={() => {
                  campaignAction()
                  handleClose()
                }}
              >
                <NytLogo />
                <Text align={'center'}>25 nyt</Text>
              </MenuItem>
            </MenuItemWrapper>
            <MenuItemWrapper MenuItemsColor={MenuItemsColor}>
              <MenuItem
                className={'click-handler'}
                onClick={() => {
                  intercomAction()
                  handleClose()
                }}
              >
                <MessageLogo />
                <Text align={'center'}>Kundeservice</Text>
              </MenuItem>
            </MenuItemWrapper>
          </DefaultMenuItems>
          {menuItems.map((items, index) => (
            <MenuItemWrapper
              key={`dropdown-menu-${index}`}
              MenuItemsColor={MenuItemsColor}
            >
              {items.map((item, index) => (
                <MenuItem
                  key={`menu-item-${index}`}
                  className={'click-handler'}
                  onClick={() => {
                    item.action()
                    handleClose()
                  }}
                >
                  <MenuItemIcon
                    src={item.logo}
                    color={'white'}
                    width={'20px'}
                    height={'20px'}
                  />
                  <TextWrapper>
                    <Text>{item.title}</Text>
                    {item.label && <Label {...item.label} />}
                  </TextWrapper>
                  <ArrowLogo
                    width={12}
                    height={12}
                    color={secondaryColor || 'white'}
                  />
                </MenuItem>
              ))}
            </MenuItemWrapper>
          ))}
        </MenuItems>
      </MenuWrapper>
    </Draggable>
  )
}

Menu.propTypes = {
  configs: PropTypes.shape({
    campaignAction: PropTypes.func.isRequired,
    intercomAction: PropTypes.func.isRequired,
    zIndexWrapper: PropTypes.number,
    MenuItemsColor: PropTypes.string,
    menuItems: PropTypes.arrayOf(
      PropTypes.arrayOf(
        PropTypes.shape({
          logo: PropTypes.string.isRequired,
          title: PropTypes.string.isRequired,
          action: PropTypes.func.isRequired
        })
      )
    ).isRequired
  }).isRequired,
  background: PropTypes.string.isRequired,
  isOpen: PropTypes.bool.isRequired
}

export default Menu

import React from 'react'
import { CloseButton, Title } from '../../LoginDesktop/styled'
import Icon from '../../Icon'
import cross from '../../../images/icons/cross.svg'
import { StyledModalTopbar } from '../styled'
import PropTypes from 'prop-types'

const TopBarLogin = ({
    title,
    currentTheme = {},
    showCloseBtn = true,
    closeAction = () => {},
}) => {
    return (
        <StyledModalTopbar color={currentTheme.colors.borderColor}>
            <Title
                color={currentTheme.colors.basic}
                condensed={currentTheme.condensed}
            >
                {title}
            </Title>
            {showCloseBtn && (
                <CloseButton onClick={closeAction}>
                    <Icon size={'sm'} src={cross} color={'#9198AA'} />
                </CloseButton>
            )}
        </StyledModalTopbar>
    )
}

TopBarLogin.propTypes = {
    title: PropTypes.string.isRequired,
    currentTheme: PropTypes.string,
    showCloseBtn: PropTypes.bool,
    closeAction: PropTypes.func,
}

export default TopBarLogin

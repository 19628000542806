import React from 'react'
import styled from 'styled-components'
import { InputControl } from './index'
import spacing from '../../utils/spacing'
import sizing from '../../utils/sizing'

const Wrapper = styled.section`
    ${spacing};
    ${sizing};
    border: 1px solid #000;
    display: flex;
    justify-content: flex-end;
    flex-flow: row;
    ${({ inputCSS }) => inputCSS}
    & > span {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding-right: 10px;
        ${({ labelCSS }) => labelCSS}
    }
`

const InputExtended = styled(InputControl)`
    width: 100%;
    border: 0;
    -webkit-appearance: none;
    -moz-appearance: textfield;
    margin: 0;
    & > input {
        text-align: center;
    }
    &::placeholder {
        text-align: center;
        overflow: visible;
    }
    &:placeholder-shown + span {
        width: 0;
        visibility: hidden;
    }
    &:not(:placeholder-shown) + span {
        width: auto;
        visibility: visible;
    }
    &:focus + span {
        width: auto;
        visibility: visible;
    }
    && + span::after {
        content: '${({ labelText }) => labelText}';
    }
`

export default ({
    bdrs,
    p,
    h,
    labelText,
    inputCSS = '',
    labelCSS = '',
    onFocus,
    onBlur,
    value,
    onChange,
    type = 'text',
}) => {
    const onChangeHandler = (event) => {
        event.stopPropagation()
        const value = event.target.value
        onChange && onChange(value)
    }

    return (
        <Wrapper
            bdrs={bdrs}
            p={p}
            h={h}
            labelCSS={labelCSS}
            inputCSS={inputCSS}
        >
            <InputExtended
                align="center"
                labelText={labelText}
                onFocus={onFocus}
                onBlur={onBlur}
                value={value}
                type={type}
                onChange={onChangeHandler}
            />
            <span />
        </Wrapper>
    )
}

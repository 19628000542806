import { makeRequest } from './REST'
import {
    getBirthday,
    getGender,
    parseFirstName,
    parseLastName
} from '../utils/parsers'

export const getAPIEnv = () => {
    return window.location.hostname === 'localhost'
        ? 'bet25dev.dk'
        : window.location.hostname
}

const OLD_AUTH_REST = '/rest/punter/'

const API_PATH = `https://api.${getAPIEnv()}`

const API_PUNTER_PATH = `${API_PATH}/punter/`

const PROD_LOCATIONS = ['terminal.retail.25syv.dk', 'derby.retail.25syv.dk']

let AUTH_REST_DOMAIN
if (PROD_LOCATIONS.includes(window.location.hostname)) {
    AUTH_REST_DOMAIN = 'https://retail.25syv.dk'
} else {
    AUTH_REST_DOMAIN = API_PUNTER_PATH
}

async function sha512(message) {
    // encode as UTF-8
    const msgBuffer = new TextEncoder().encode(message)

    // hash the message
    const hashBuffer = await crypto.subtle.digest('SHA-512', msgBuffer)

    // convert ArrayBuffer to Array
    const hashArray = Array.from(new Uint8Array(hashBuffer))

    // convert bytes to hex string

    return hashArray.map((b) => b.toString(16).padStart(2, '0')).join('')
}

class AuthService {
    request(requestType, url, data) {
        return makeRequest(requestType, url, data)
            .then((response) => {
                return response.json()
            })
            .then((data) => {
                return data
            })
    }

    post(method, data, oldAPI = false) {
        let restPath
        if (this.isTerminal) {
            restPath = AUTH_REST_DOMAIN + API_PUNTER_PATH
        } else if (oldAPI) {
            restPath = OLD_AUTH_REST
        } else {
            restPath = API_PUNTER_PATH
        }
        return this.request('post', restPath + method, data)
    }

    storePunter(userData, oldAPI = false) {
        const defaultValues = {
            currency: 'DKK',
            country: 'DK',
            code_note: `Signupbonus ${userData.project}`
        }

        userData = {
            userName: userData.userName,
            password: userData.password,
            firstName: parseFirstName(userData.fullName),
            lastName: parseLastName(userData.fullName),
            email: userData.email,
            currency: defaultValues.currency,
            birthDate: getBirthday(userData.cpr),
            gender: getGender(userData.cpr),
            address: userData.pureAddress,
            city: userData.city,
            zip: userData.zip,
            country: defaultValues.country,
            phoneNr: userData.phoneNumber,
            mobileNr: userData.phoneNumber,
            bonusCode: userData.bonusCode,
            bonusCodeNote: defaultValues.code_note,
            mailList: userData.newsletterSub,
            securityAnswer: userData.pinCode,
            expectBetting: userData.expectBetting,
            dailyDepositLimit: userData.dailyDepositLimit,
            weeklyDepositLimit: userData.weeklyDepositLimit,
            monthlyDepositLimit: userData.monthlyDepositLimit
        }

        return this.post('storePunter', userData, oldAPI).then((response) => {
            if (response.statusCode !== 200 || !response.success) {
                return Promise.reject(response.errorMessage)
            }
            return userData
        })
    }

    startMitId(cpr, idp, site = '') {
        let restPath
        let channel
        if (this.isTerminal) {
            restPath = AUTH_REST_DOMAIN + '/mitId/start'
            channel = { channel: 9 }
        } else if (!site) {
            restPath = `/rest/mitId/start`
        } else {
            restPath = `${API_PUNTER_PATH}mitId/start`
        }
        return this.request('post', restPath, {
            cpr,
            idp,
            channel: this.isTerminal ? 9 : '',
            site
        }).then((data) => {
            return data
        })
    }

    checkEmail(email, oldAPI = false) {
        const salt = '1238ASvNkjsfaw23v.da!)&efu'
        return sha512(email + salt).then((hash) => {
            return this.post('checkEmail', { email, hash }, oldAPI)
        })
    }

    checkUserName(username, cpr, oldAPI = false) {
        const salt = '1238ASvNkjsfaw23v.da!)&efu'
        return sha512(username + salt).then((hash) => {
            return this.post('checkUsername', { username, cpr, hash }, oldAPI)
        })
    }

    checkRegisterInfo(username, cpr, oldAPI = false) {
        const salt = '1238ASvNkjsfaw23v.da!)&efu'
        return sha512(username + salt + cpr).then((hash) => {
            return makeRequest(
                'post',
                `${oldAPI ? OLD_AUTH_REST : AUTH_REST_DOMAIN}checkRegisterInfo`,
                {
                    username,
                    cpr,
                    hash
                }
            )
                .then((response) => {
                    return response.json()
                })
                .then((data) => {
                    return data
                })
        })
    }

    /* checkUserName(username, cpr) {
        const salt = '1238ASvNkjsfaw23v.da!)&efu'
        return sha512(username + salt).then((hash) => {
            return this.post('checkSignupInput', { username, cpr, hash })
        })
    } */
}

export default new AuthService()

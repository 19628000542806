export const LINKS = {
    default: {
        dataPolicy:
            'https://intercom.help/25syv/da/articles/3870767-persondatapolitik-for-bet25-a-s',
        termsConds:
            'https://intercom.help/25syv/da/articles/3493559-generelle-vilkar-betingelser'
    },

    casinogo: {
        dataPolicy:
            'https://intercom.help/casinogo/da/articles/3870784-persondatapolitik-for-25syv-a-s',
        termsConds:
            'https://intercom.help/casinogo/da/articles/4660397-vilkar-og-betingelser'
    }
}

export const siteByProjectName = {
    rod25: 'casino',
    bet25: 'sport',
    derby25: 'heste',
    casinogo: ''
}

export const getLinks = (project = 'default') =>
    LINKS[project] === undefined ? LINKS.default : LINKS[project]

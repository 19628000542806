import React, { Fragment, useEffect, useState } from 'react'
import ProvidersThumb from './ProvidersThumb'
import PropTypes from 'prop-types'

const ProvidersThumbs = ({
    activeCategoryName,
    thumbs,
    showProviders,
    thumbClickHandler,
    liveCasinoGames,
}) => {
    const [thumbsToDisplay, setThumbsToDisplay] = useState(thumbs)

    const isToShow = () => {
        return (
            ['all'].indexOf(activeCategoryName) >= 0 && liveCasinoGames.length
        )
    }

    const providerGamesCount = (provider = '') => {
        return liveCasinoGames && liveCasinoGames.length
            ? liveCasinoGames.filter((game) => game.subprovider === provider)
                  .length
            : 0
    }

    const showByProvider = (provider = '') => {
        return showProviders.length === 0
            ? true
            : showProviders.indexOf(provider) >= 0
    }

    useEffect(() => {
        setThumbsToDisplay(
            thumbs.filter(
                (item) =>
                    showByProvider(item.providerKey) &&
                    providerGamesCount(item.providerKey)
            )
        )
    }, [thumbs])

    return (
        <Fragment>
            {isToShow()
                ? thumbsToDisplay.map((thumb, key) => {
                      if (
                          (Object.keys(thumb.liveCasino).length &&
                              thumb.gameId) ||
                          (!Object.keys(thumb.liveCasino).length &&
                              !thumb.gameId)
                      ) {
                          return (
                              <ProvidersThumb
                                  key={key}
                                  thumb={thumb}
                                  thumbClickHandler={thumbClickHandler}
                                  liveCasinoGames={liveCasinoGames}
                              />
                          )
                      }
                  })
                : null}
        </Fragment>
    )
}

ProvidersThumbs.propTypes = {
    activeCategoryName: PropTypes.string,
    thumb: PropTypes.object,
    thumbClickHandler: PropTypes.func,
}

export default ProvidersThumbs
